import React, { memo } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { Row } from 'react-table';

import { ZoneTypeHelper } from '../../../models';
import { PreviewPredicates } from '../../../models';
import { Zone } from '../../../services/zone';
import { END_OF_DAY_TIME, START_OF_DAY_TIME } from '../../../utils/constants';
import CustomIcon from '../../../utils/CustomIcon';
import { TranslatedText, ReactTable } from '../../../wmv-components';

import { previewTableContainerStyles, previewTableTitleBoxStyles, previewTableTitleTextStyles } from './PlanningModePreviewTable.styles';

interface PlanningModePreviewTableProps {
  zones: Zone[];
  collapsed: boolean;
  onCollapseToggle: (collapsed: boolean) => void;
  previewPredicates: PreviewPredicates;
}

export const PlanningModePreviewTable: React.FC<PlanningModePreviewTableProps> = ({ zones, collapsed, onCollapseToggle }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  // TODO: Fix w/1519 i18n all Headers & cell contents (Create Ticket for it)
  const columns = [
    {
      id: 'name',
      Header: formatMessage({ id: 'planningPreview.table.name' }),
      accessor: 'name',
      Cell: ({ row }: { row: Row<Zone> }) => (
        <Typography variant="bodySmall" px={1}>
          {row.original.name}
        </Typography>
      ),
    },
    {
      id: 'ruleType',
      Header: formatMessage({ id: 'planningPreview.table.ruleType' }),
      accessor: 'type',
      Cell: ({ row }: { row: Row<Zone> }) => (
        <Typography variant="bodySmall">
          {ZoneTypeHelper.allZoneTypes().includes(row.original.type)
            ? formatMessage({ id: `zoneType.${row.original.type}` })
            : formatMessage({ id: 'common.dash' })}
        </Typography>
      ),
    },
    {
      id: 'startDate',
      Header: formatMessage({ id: 'planningPreview.table.startDate' }),
      accessor: 'startsAtEpochMillis',
      Cell: ({ row }: { row: Row<Zone> }) => (
        <Typography variant="bodySmall">{dayjs(row.original.activeOn.dateRange.startInclusive).formatAsDateString()}</Typography>
      ),
      filterFn: 'myCustomFilter',
    },
    {
      id: 'endDate',
      Header: formatMessage({ id: 'planningPreview.table.endDate' }),
      accessor: 'endsAtEpochMillis',
      Cell: ({ row }: { row: Row<Zone> }) => (
        <Typography variant="bodySmall" px={1}>
          {row.original.activeOn.dateRange.endInclusive
            ? dayjs(row.original.activeOn.dateRange.endInclusive).formatAsDateString()
            : formatMessage({ id: 'common.na' })}
        </Typography>
      ),
    },

    {
      id: 'startTime',
      Header: formatMessage({ id: 'planningPreview.table.startTime' }),
      accessor: 'startsAtEpochMillis',
      Cell: ({ row }: { row: Row<Zone> }) => (
        <Typography variant="bodySmall" px={1}>
          {row.original.activeOn.timeWindow?.startInclusive || START_OF_DAY_TIME}
        </Typography>
      ),
    },

    {
      id: 'endTime',
      Header: formatMessage({ id: 'planningPreview.table.endTime' }),
      accessor: 'endsAtEpochMillis',
      Cell: ({ row }: { row: Row<Zone> }) => (
        <Typography variant="bodySmall" px={1}>
          {((row.original.activeOn.timeWindow?.endExclusive || END_OF_DAY_TIME) as string)
            .asDayjsTime()
            .toInclusive()
            .formatAsTimeWithoutSeconds()}
        </Typography>
      ),
    },
    {
      id: 'publishedDate',
      Header: formatMessage({ id: 'planningPreview.table.publishedDate' }),
      accessor: 'publishMetadata',
      Cell: ({ row }: { row: Row<Zone> }) => (
        <Typography variant="bodySmall" px={1}>
          {row.original.createdAtEpochMillis.parseEpochMillis().formatAsDateString()}
        </Typography>
      ),
    },
  ];

  return (
    <>
      <Box sx={previewTableContainerStyles}>
        <Box sx={previewTableTitleBoxStyles}>
          <Box sx={previewTableTitleTextStyles}>
            <TranslatedText variant="subtitle1" translationKey="planningPreview.zonesDataTable.title" />
          </Box>
          <Box>
            <Typography variant="subtitle1">
              {formatMessage(
                { id: 'planningPreview.zonesDataTable.zonesCount' },
                {
                  zonesCount: zones?.length,
                },
              )}
            </Typography>
          </Box>
        </Box>

        <IconButton
          size="small"
          disableRipple
          onClick={(event) => {
            event.stopPropagation();
            onCollapseToggle(!collapsed);
          }}
        >
          {CustomIcon(collapsed ? 'DoubleChevronUp' : 'DoubleChevronDown', theme.palette.primary.main)}
        </IconButton>
      </Box>
      {!collapsed && (
        <ReactTable
          loading={false}
          data={zones}
          columns={columns}
          tableContainerStyle={{
            height: 200,
            border: '1px solid #ccc',
          }}
          pagination={false}
          onRowClickHandler={(r: Zone) => {
            console.log(r);
          }}
          renderDetailsComponent={null}
        />
      )}
    </>
  );
};

export default memo(PlanningModePreviewTable);
