import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  border: 'none',
  width: '50%',
  boxSizing: 'border-box',
  height: '48px',
  padding: 0,
  backgroundColor: !isActive ? theme.palette.white.main : theme.palette.secondary.main,
  borderRadius: '4px',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: !isActive ? theme.palette.secondary.tint : theme.palette.secondary.main,
  },
}));
