import { useState } from 'react';

import { Box, Button, Modal, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import CustomIcon from '../../../utils/CustomIcon';

import ModalChangeButton, { StatusModalChangeButtonUsage } from './ModalElements/StatusModalChangeButton';
import { statusChangeModalStyle } from './StatusChangeModal';

type ModalType = 'fail' | 'retry';

interface StatusChangeFailModalInterface {
  isOpen: boolean;
  vehicleId: number;
  onClose: () => void;
  modalType: ModalType;
  handleStatusChangeRetry?: () => void;
  handleStatusChangeCancel?: () => void;
}

function StatusChangeFailModal({
  isOpen,
  vehicleId,
  onClose,
  modalType,
  handleStatusChangeRetry,
  handleStatusChangeCancel,
}: StatusChangeFailModalInterface) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const isRetryModal = modalType === 'retry';

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const confirmStatusChangeRetry = () => {
    if (!vehicleId || !handleStatusChangeRetry) {
      setLoading(false);
      setError(true);
      return;
    }
    setLoading(true);
    try {
      handleStatusChangeRetry();
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const cancelStatusChange = async () => {
    if (!handleStatusChangeCancel) return;
    handleStatusChangeCancel();
    onClose();
  };

  return (
    <Modal open={isOpen}>
      <Box sx={statusChangeModalStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(0.5) }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: theme.spacing(0.5),
            }}
          >
            {CustomIcon('AttentionIconFilled', theme.palette.alert.main, theme.spacing(2))}
            <Typography variant="bodyMediumBold" color={theme.palette.alert.main} sx={{ textAlign: 'center' }}>
              {formatMessage({ id: 'operator.statusChange.fail.caption' })}
            </Typography>
          </Box>
          <Typography variant="bodyMediumBold" color={theme.palette.dark.main} sx={{ textAlign: 'center' }}>
            {formatMessage({ id: 'operator.statusChange.vehicle' })} {vehicleId}
          </Typography>
          <Typography variant="bodySmall" color={theme.palette.dark.shade76} sx={{ textAlign: 'center' }}>
            {formatMessage({
              id: isRetryModal ? 'operator.statusChange.retry.text' : 'operator.statusChange.fail.text',
            })}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: isRetryModal ? 'column' : 'row',
            gap: '4px',
            width: '100%',
          }}
        >
          {isRetryModal ? (
            <>
              <ModalChangeButton
                usage={StatusModalChangeButtonUsage.Retry}
                message="operator.statusChange.retry"
                onClickFunction={confirmStatusChangeRetry}
                loading={loading}
              />
              <ModalChangeButton
                usage={StatusModalChangeButtonUsage.CancelRetry}
                message="operator.statusChange.cancel"
                onClickFunction={cancelStatusChange}
                disabled={loading}
              />
              <>
                {error && (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flexStart', gap: '4px' }}>
                    {CustomIcon('AttentionIcon', theme.palette.alert.main, theme.typography.caption.lineHeight)}
                    <Typography variant="caption" color={theme.palette.alert.main}>
                      {formatMessage({ id: 'operator.statusChange.changeModal.failed' })}
                    </Typography>
                  </Box>
                )}
              </>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: theme.spacing(23),
                height: theme.spacing(4),
              }}
              onClick={onClose}
            >
              <Typography variant="bodySmallBold" color={theme.palette.white.main}>
                {formatMessage({ id: 'common.dialog.confirm' })}
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default StatusChangeFailModal;
