import type { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import type { ButtonOwnProps } from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

import { ErrorMessage } from '../../components/FormFields/ErrorMessage';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';

import { containerStyles, contentStyles, actionButtonStyles } from './ConfirmationModal.styles';

export type ConfirmationModalCloseReason = 'backdropClick' | 'escapeKeyDown' | 'cancelClick';
interface ConfirmationModalProps {
  open: boolean;
  title: string;
  handleClose: (reason: ConfirmationModalCloseReason) => void;
  handleConfirm: () => void;
  loading?: boolean;
  confirmBtnColor?: ButtonOwnProps['color'];
  cancelBtnColor?: ButtonOwnProps['color'];
  confirmBtnText?: string;
  cancelBtnText?: string;
  children?: ReactNode;
  error?: string | null | undefined;
}

export const ConfirmationModal = ({
  open,
  title,
  handleClose,
  handleConfirm,
  loading = false,
  confirmBtnText,
  cancelBtnText,
  confirmBtnColor = 'primary',
  cancelBtnColor = 'primary',
  children,
  error,
}: ConfirmationModalProps) => {
  const { formatMessage } = useIntl();
  if (!confirmBtnText) {
    confirmBtnText = formatMessage({ id: 'common.confirm' });
  }
  if (!cancelBtnText) {
    cancelBtnText = formatMessage({ id: 'actions.cancel' });
  }

  return (
    <Modal open={open} onClose={(e, reason) => handleClose(reason)}>
      <Box sx={containerStyles}>
        <Typography variant="bodyMediumBold" component="h2">
          {title}
        </Typography>

        <Box sx={contentStyles}>{children}</Box>

        <Stack spacing={1} mt={2}>
          <Button onClick={handleConfirm} variant="contained" sx={actionButtonStyles} color={confirmBtnColor}>
            {loading ? <LoadingAnimation sizeMultiplier={0.25} /> : confirmBtnText}
          </Button>
          <Button variant="outlined" color={cancelBtnColor} sx={actionButtonStyles} onClick={() => handleClose('cancelClick')}>
            {cancelBtnText}
          </Button>
        </Stack>

        <ErrorMessage error={error} />
      </Box>
    </Modal>
  );
};
