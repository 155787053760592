import { useCallback, useState } from 'react';

import { Typography, Button, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import camelCase from 'lodash/camelCase';
import { useIntl } from 'react-intl';

import { ReactComponent as CargoBikeIcon } from '../../assets/illustrations/cargoBike.svg';
import { ReactComponent as DonkeyProviderIcon } from '../../assets/illustrations/donkeyProvider.svg';
import { ReactComponent as BikeIcon } from '../../assets/illustrations/eBike.svg';
import { ReactComponent as MopedIcon } from '../../assets/illustrations/eMoped.svg';
import { ReactComponent as ScooterIcon } from '../../assets/illustrations/eScooter.svg';
import { ReactComponent as MeiaudoProviderIcon } from '../../assets/illustrations/maiaudoProvider.svg';
import { ReactComponent as CarIcon } from '../../assets/illustrations/meiaudoCar.svg';
import { ReactComponent as STWBProviderIcon } from '../../assets/illustrations/stwbProvider.svg';
import { ReactComponent as ZeusProviderIcon } from '../../assets/illustrations/zeusProvider.svg';
import { GraphqlVehicleUsageStatus, GraphqlVehicleUsageStatusHelper } from '../../models';
import { LiveVehicle } from '../../state/common/vehicle.model';
import CustomIcon from '../../utils/CustomIcon';

interface StatusHeadingProps {
  status: GraphqlVehicleUsageStatus;
  vehicle: LiveVehicle;
}

const StatusHeading: React.FC<StatusHeadingProps> = ({ vehicle, status }) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      sx={{
        padding: '8px 18px',
        gap: theme.spacing(1),
        width: '100%',
        backgroundColor: GraphqlVehicleUsageStatusHelper.metadata(status).color,
      }}
    >
      <Typography color={theme.palette.white.main} variant="bodyMediumBold">
        {intl.formatMessage({ id: `vehicleType.${camelCase(vehicle.formFactor)}` })}
      </Typography>
    </Grid>
  );
};

// remove Battery Level, Range, Vehicle status for provider ‘meiAudo’

export interface VehicleInfoModalProps {
  vehicle: LiveVehicle;
  status: GraphqlVehicleUsageStatus;
  handleOpenModal: (vehicle: LiveVehicle) => void;
}

const VehicleInfoModal = (props: VehicleInfoModalProps) => {
  const { vehicle, handleOpenModal, status } = props;
  const theme = useTheme();
  const { formatMessage, formatNumber } = useIntl();

  const openModal = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      handleOpenModal(vehicle);
    },
    [handleOpenModal, vehicle],
  );

  const [content, setContent] = useState(false);
  const handleContent = () => {
    setContent((content) => !content);
  };

  const VehicleIcon = ({ vehicleType }: { vehicleType: string }) => {
    const iconStyles = {
      cargo_bicycle: { left: '45px', top: '30px', width: '155px', height: '150px', Icon: CargoBikeIcon },
      scooter: { left: '50px', top: '20px', width: '145px', height: '140px', Icon: ScooterIcon },
      ebike: { left: '45px', top: '30px', width: '155px', height: '150px', Icon: BikeIcon },
      moped: { left: '45px', top: '25px', width: '155px', height: '150px', Icon: MopedIcon },
      car: { left: '45px', top: '30px', width: '165px', height: '160px', Icon: CarIcon },
    };

    const iconConfig = iconStyles[vehicleType as keyof typeof iconStyles];
    if (!iconConfig) return null;
    const { Icon, left, top, width, height } = iconConfig;

    return (
      <Grid sx={{ position: 'absolute', left, top }}>
        <Icon width={width} height={height} />
      </Grid>
    );
  };

  const ProviderIcon = ({ providerName }: { providerName: string }) => {
    const icons = {
      'zeus scooters': <ZeusProviderIcon width="28px" height="28px" />,
      'stwb bamberg': <STWBProviderIcon />,
      'donkey republic': <DonkeyProviderIcon />,
      meiaudo: <MeiaudoProviderIcon />,
    };

    return icons[providerName as keyof typeof icons] || null;
  };

  return (
    <>
      <Grid
        container
        direction={'column'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        sx={{ padding: '0', gap: theme.spacing(3), marginBottom: theme.spacing(3), marginTop: '148px' }}
      >
        <Grid container direction={'column'} alignItems={'flex-start'} sx={{ padding: '0', gap: theme.spacing(3), width: '100%' }}>
          <StatusHeading vehicle={vehicle} status={status} />
          <Grid
            container
            direction={'column'}
            alignItems={'flex-start'}
            sx={{ padding: '0 8px', gap: theme.spacing(1), width: '100%', maxWidth: '243px' }}
          >
            {content === false ? (
              <>
                <Grid
                  container
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ paddingRight: theme.spacing(1), gap: '30px', width: '100%' }}
                >
                  <Typography variant="bodySmall" color={theme.palette.dark.shade60}>
                    {formatMessage({ id: 'map.vehicleInfo.provider' })}
                  </Typography>
                  <Typography variant="bodySmall">{vehicle.mspName ?? `${formatMessage({ id: 'common.undefined' })}`}</Typography>
                </Grid>
                <Grid
                  container
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ paddingRight: theme.spacing(1), gap: '30px', width: '100%' }}
                >
                  <Typography variant="bodySmall" color={theme.palette.dark.shade60}>
                    {formatMessage({ id: 'map.vehicleInfo.status' })}
                  </Typography>

                  <Typography variant="bodySmallBold" color={GraphqlVehicleUsageStatusHelper.metadata(status).color}>
                    {GraphqlVehicleUsageStatusHelper.metadata(status).displayText}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ paddingRight: theme.spacing(1), gap: '30px', width: '100%' }}
                >
                  <Typography variant="bodySmall" color={theme.palette.dark.shade60}>
                    {formatMessage({ id: 'map.vehicleInfo.lastStatus' })}
                  </Typography>
                  <Typography variant="bodySmall">
                    {status ? GraphqlVehicleUsageStatusHelper.metadata(status).displayText : `${formatMessage({ id: 'common.dash' })}`}
                  </Typography>
                </Grid>
              </>
            ) : (
              <></>
            )}
            {content === true ? (
              <>
                <Grid
                  container
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ paddingRight: theme.spacing(1), gap: '30px', width: '100%' }}
                >
                  <Typography variant="bodySmall" color={theme.palette.dark.shade60}>
                    {formatMessage({ id: 'map.vehicleInfo.qr' })}
                  </Typography>
                  <Typography variant="bodySmall">{vehicle.qrCodeId ?? formatMessage({ id: 'common.dash' })}</Typography>
                </Grid>
                <Grid
                  container
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ paddingRight: theme.spacing(1), gap: '30px', width: '100%' }}
                >
                  <Typography variant="bodySmall" color={theme.palette.dark.shade60}>
                    {formatMessage({ id: 'map.vehicleInfo.license' })}
                  </Typography>
                  <Typography variant="bodySmall">
                    {vehicle.vehicleId && vehicle.formFactor.toLowerCase() !== 'scooter'
                      ? vehicle.vehicleId
                      : formatMessage({ id: 'common.dash' })}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ paddingRight: theme.spacing(1), gap: '30px', width: '100%' }}
                >
                  <Typography variant="bodySmall" color={theme.palette.dark.shade60}>
                    {formatMessage({ id: 'map.vehicleInfo.range' })}
                  </Typography>
                  {vehicle.currentRangeMeters && vehicle.maxRangeMeters ? (
                    <Typography variant="bodySmall">
                      {vehicle.currentRangeMeters
                        ? formatNumber(vehicle.maxRangeMeters / 1000, { style: 'unit', unit: 'kilometer' })
                        : formatNumber(0, { style: 'unit', unit: 'meter' })}
                    </Typography>
                  ) : (
                    <Typography variant="bodySmall">{formatMessage({ id: 'common.dash' })}</Typography>
                  )}
                </Grid>
                <Grid
                  container
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ paddingRight: theme.spacing(1), gap: '30px', width: '100%' }}
                >
                  <Typography variant="bodySmall" color={theme.palette.dark.shade60}>
                    {formatMessage({ id: 'map.vehicleInfo.battery' })}
                  </Typography>
                  {((vehicle.formFactor.toLowerCase() === 'moped' || vehicle.formFactor.toLowerCase() === 'ebike') &&
                    vehicle.currentFuelPercent) ||
                  (vehicle.maxRangeMeters && vehicle.currentRangeMeters) ? (
                    <Typography variant="bodySmall">
                      {vehicle.formFactor.toLowerCase() === 'moped' || vehicle.formFactor.toLowerCase() === 'ebike'
                        ? formatNumber((vehicle.currentFuelPercent ?? 0) / 100, { style: 'percent' })
                        : vehicle.currentRangeMeters && vehicle.maxRangeMeters
                        ? formatNumber(vehicle.currentRangeMeters / vehicle.maxRangeMeters, { style: 'percent' })
                        : formatNumber(0)}
                    </Typography>
                  ) : (
                    <Typography variant="bodySmall">{formatMessage({ id: 'common.dash' })}</Typography>
                  )}
                </Grid>
                <Grid
                  container
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ paddingRight: theme.spacing(1), gap: '30px', width: '100%' }}
                >
                  <Typography variant="bodySmall" color={theme.palette.dark.shade60}>
                    {formatMessage({ id: 'map.vehicleInfo.furtherInfo' })}
                  </Typography>
                  <Button onClick={openModal} sx={{ minWidth: '0px', padding: '0px' }}>
                    {CustomIcon('LinkOut', theme.palette.secondary.dark)}
                  </Button>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <>
          <Grid
            container
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              padding: '6px 12px',
              width: '200px',
              height: theme.spacing(4),
              backgroundColor: theme.palette.primary.main,
              borderRadius: '6px',
            }}
          >
            <Button onClick={handleContent} fullWidth>
              {content === false ? (
                <Typography variant="bodySmallBold" color={theme.palette.white.main}>
                  {formatMessage({ id: 'map.vehicleInfo.button' })}
                </Typography>
              ) : (
                <Typography sx={{ display: 'flex', alignItems: 'center' }} variant="bodySmallBold" color={theme.palette.white.main}>
                  {CustomIcon('ChevronLeft', theme.palette.white.main)} {formatMessage({ id: 'map.vehicleInfo.backButton' })}
                </Typography>
              )}
            </Button>
          </Grid>
        </>
      </Grid>
      <Grid sx={{ position: 'absolute', left: '192px', top: '168px', zIndex: '5' }}>
        <Grid
          sx={{
            backgroundColor: theme.palette.white.main,
            width: theme.spacing(5),
            height: theme.spacing(5),
            boxShadow: theme.shadowOptions.belowSmall,
            borderRadius: theme.spacing(5),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px solid #E7E7E7',
          }}
        >
          <Button style={{ width: theme.spacing(4), height: theme.spacing(4), minWidth: '0px', padding: '0px' }} disabled>
            <ProviderIcon providerName={vehicle.mspName.toLowerCase()} />
          </Button>
        </Grid>
      </Grid>
      <VehicleIcon vehicleType={vehicle.formFactor.toLowerCase()} />
    </>
  );
};

export default VehicleInfoModal;
