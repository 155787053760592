import { BudgetPlanMembershipStatus } from '../../models/budget-plan';
import { BudgetPlanMembershipActiveDto } from '../../models/budget-plan';

export class BudgetPlanMembershipActiveStateDto {
  status: BudgetPlanMembershipStatus;
  subscribedAtEpochMillis?: number;
  isSubscribed: boolean;

  constructor(activeState: BudgetPlanMembershipActiveDto) {
    this.status = activeState.status;
    this.isSubscribed = this.status === BudgetPlanMembershipStatus.Subscribed;
    if (activeState.subscribedAtEpochMillis) {
      this.subscribedAtEpochMillis = activeState.subscribedAtEpochMillis;
    }
  }
}
