import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../../components/LoadingAndErrorWithRetryAndNoResults';
import { BudgetPlanMembershipDto } from '../../../../services/budget-plan-memberships';
import { budgetPlansByIdSelector } from '../../../../state';
import { fetchBudgetPlanMembershipDetails, fetchBudgetPlanMembershipSummaries } from '../../../../utils/queries';

import { BudgetPlanMembershipTable } from './BudgetPlanMembershipTable';
import { enqueueSnackbars } from './helpers';
import { pollActionCreator } from './usePolling';

import './budget-plan-memberships-snackbar.css';

let polledActions: { start: any; stop: any; intervalId: ReturnType<typeof setTimeout> }[] = [];

export const BudgetPlanMembershipsView = ({ selectedBudgetPlanId }: BudgetPlanMembershipsViewProps) => {
  const [budgetPlanMemberships, setBudgetPlanMemberships] = useState<{
    loading: boolean;
    error: boolean;
    data: BudgetPlanMembershipDto[];
  }>({
    loading: false,
    error: false,
    data: [],
  });
  const selectedBudgetPlan = useSelector(budgetPlansByIdSelector)[selectedBudgetPlanId] || {};

  const getBudgetPlanMemberships = useCallback(async () => {
    setBudgetPlanMemberships((prevBudgetPlanMemberships) => ({ ...prevBudgetPlanMemberships, loading: true, error: false }));
    try {
      const membershipSummaryResponses = (await fetchBudgetPlanMembershipSummaries(selectedBudgetPlanId)) || [];
      const membershipDtos = membershipSummaryResponses.map((member) => new BudgetPlanMembershipDto(member));
      setBudgetPlanMemberships((prevBudgetPlanMemberships) => ({ ...prevBudgetPlanMemberships, loading: false, data: membershipDtos }));
    } catch (e) {
      setBudgetPlanMemberships({ data: [], error: true, loading: false });
      console.log('Error fetching budget plan members', e);
    }
  }, [selectedBudgetPlanId]);

  useEffect(() => {
    (async () => {
      await getBudgetPlanMemberships();
    })();
  }, [getBudgetPlanMemberships]);

  // TODO: Simplify the enqueuing of snackbars while doing
  const updateInProgressTransitionOfBudgetPlanMembership = useCallback(
    (membershipId: number) => async () => {
      try {
        const updatedMembership = await fetchBudgetPlanMembershipDetails(selectedBudgetPlanId, membershipId);
        const updatedMembershipDto = new BudgetPlanMembershipDto(updatedMembership);
        enqueueSnackbars(
          updatedMembershipDto.lastAttemptedStateTransition,
          updatedMembershipDto.name(),
          selectedBudgetPlan.activeState.name,
        );
        const newMemberships: BudgetPlanMembershipDto[] = budgetPlanMemberships.data.map((membershipDto) => {
          if (membershipDto.id === updatedMembership.id) return updatedMembershipDto;
          return membershipDto;
        });
        setBudgetPlanMemberships((prevMemberships) => ({ ...prevMemberships, data: newMemberships }));
      } catch {
        console.log('Failed to fetch Budget plan membership with id', membershipId);
      }
    },
    [budgetPlanMemberships.data, selectedBudgetPlanId, selectedBudgetPlan.activeState.name],
  );

  useEffect(() => {
    if (budgetPlanMemberships.data.length) {
      for (let i = 0; i < budgetPlanMemberships.data.length; i++) {
        if (budgetPlanMemberships.data[i].lastAttemptedStateTransition.isLastAttemptedStateTransitionInProgress()) {
          const pollingAction = pollActionCreator(5000);
          const polledActionId = pollingAction.start(updateInProgressTransitionOfBudgetPlanMembership(budgetPlanMemberships.data[i].id));
          polledActions.push({ ...pollingAction, intervalId: polledActionId });
        }
      }
    }
    return () => {
      polledActions.forEach((polledAction) => polledAction.stop(polledAction.intervalId));
      polledActions = [];
    };
  }, [budgetPlanMemberships.data, updateInProgressTransitionOfBudgetPlanMembership]);

  if (budgetPlanMemberships.loading || budgetPlanMemberships.error || !budgetPlanMemberships.data.length) {
    return (
      <LoadingAndErrorWithRetryAndNoResults
        style={{ height: '100%', backgroundColor: 'transparent' }}
        error={budgetPlanMemberships.error}
        loading={budgetPlanMemberships.loading}
        onRetry={async () => {
          await getBudgetPlanMemberships();
        }}
        baseTranslationKey={'budgetPlanMemberships'}
        noResults={budgetPlanMemberships.data.length === 0 && !budgetPlanMemberships.error}
      />
    );
  }

  const activeOrInTransitionMembers = budgetPlanMemberships.data.filter((member) => !member.inActive());
  return <BudgetPlanMembershipTable budgetPlanMemberships={activeOrInTransitionMembers} />;
};

interface BudgetPlanMembershipsViewProps {
  selectedBudgetPlanId: number;
}
