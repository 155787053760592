import { useState } from 'react';

import { Box, Modal, IconButton, Grid, Typography, MenuList, MenuItem, Divider } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import camelCase from 'lodash/camelCase';
import { useIntl } from 'react-intl';

import { LiveVehicle } from '../../state/common/vehicle.model';
import CustomIcon from '../../utils/CustomIcon';
import { ProviderLogo } from '../../wmv-components';

import GeneralInformation from './VehicleInfoModalPanels/GeneralInformation/index';
import VehicleTripHistory from './VehicleInfoModalPanels/VehicleTripHistory/index';

// remove Battery Level, Range, Vehicle status for provider ‘meiAudo’

const returnDetailsPanel = (option: string, vehicle: LiveVehicle) => {
  switch (option) {
    case options.generalInformation:
      return <GeneralInformation vehicle={vehicle} />;
    case options.tripHistory:
      return <VehicleTripHistory vehicleId={vehicle.stableVehicleId} />;
    default:
      return <GeneralInformation vehicle={vehicle} />;
  }
};

const options = {
  generalInformation: 'GeneralInformation',
  tripHistory: 'TripHistory',
};

const VehicleModal = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  width: '80%',
  height: '90%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '16px 8px 8px 8px',
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.white.main,
}));

const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: '-40px',
  right: '-40px',
}));

const VehicleMenuItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  paddingTop: '0',
  paddingBottom: '0',
  color: theme.palette.dark.shade60,
  '&:hover': {
    backgroundColor: theme.palette.dark.shade4,
    color: theme.palette.secondary.dark,
  },
  '&.Mui-selected': {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.dark.shade4,
  },
  ...theme.typography.bodyLargeBold,
}));

interface DetailedVehicleInfoProps {
  vehicle: LiveVehicle;
  isOpen: boolean;
  onClose: (() => void) | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
}

const DetailedVehicleInfo = ({ vehicle, isOpen, onClose }: DetailedVehicleInfoProps) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const [option, setOption] = useState(options.generalInformation);

  return (
    <Box>
      <Modal open={isOpen} aria-labelledby="modal-title">
        <VehicleModal container direction="row" wrap="nowrap" justifyContent="center" alignItems="stretch">
          <Grid
            container
            item
            direction="column"
            sx={{
              backgroundColor: theme.palette.dark.shade3,
              padding: '16px 8px',
              gap: theme.spacing(2),
              margin: '0 8px 0 8px',
              width: 'fit-content',
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                padding: '24px 16px',
                gap: theme.spacing(1),
                backgroundColor: theme.palette.white.main,
                borderRadius: theme.spacing(1),
                flexWrap: 'nowrap',
              }}
            >
              <ProviderLogo provider={vehicle.mspName.toLowerCase()} />
              <Grid container direction="column" sx={{ gap: theme.spacing(0.5) }}>
                <Typography variant="bodyLargeBold" color={theme.palette.dark.main} sx={{ whiteSpace: 'nowrap' }}>
                  {vehicle.mspName} {vehicle.formFactor}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={theme.palette.dark.shade76}
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {vehicle.stableVehicleId ? vehicle.stableVehicleId : `${formatMessage({ id: 'common.dash' })}`}
                </Typography>
              </Grid>
            </Grid>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                borderColor: theme.palette.dark.shade8,
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
              }}
            ></Divider>
            <MenuList sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
              {Object.keys(options).map((item, index) => (
                <VehicleMenuItem
                  key={index}
                  disableRipple
                  selected={option === options[item as keyof typeof options]}
                  onClick={() => setOption(options[item as keyof typeof options])}
                >
                  {formatMessage({ id: `map.detailedModal.${camelCase(options[item as keyof typeof options])}` })}
                </VehicleMenuItem>
              ))}
            </MenuList>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: theme.palette.dark.shade8,
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(1),
            }}
          ></Divider>
          <Grid
            container
            direction="column"
            sx={{
              padding: '16px 16px 24px 16px',
              gap: theme.spacing(2),
              borderRadius: theme.spacing(1),
              margin: '0 8px 0 8px',
              overflow: 'auto',
            }}
          >
            {returnDetailsPanel(option, vehicle)}
          </Grid>
          <CloseButton aria-label="close" onClick={onClose} disableRipple>
            {CustomIcon('CloseIcon', theme.palette.white.main, theme.spacing(4))}
          </CloseButton>
        </VehicleModal>
      </Modal>
    </Box>
  );
};

export default DetailedVehicleInfo;
