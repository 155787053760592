import dayjs from 'dayjs';
import { isValidPhoneNumber } from 'libphonenumber-js';
import type { IntlShape } from 'react-intl';
import * as Yup from 'yup';

import { DEFAULT_NAME_MAX_CHAR_LENGTH } from './constants';
import { regexPatterns } from './regex-patterns';

export const validateName = (intl: IntlShape, maxCharLength: number = DEFAULT_NAME_MAX_CHAR_LENGTH): Yup.StringSchema => {
  return Yup.string()
    .required(intl.formatMessage({ id: 'form.validation.errorFieldRequired' }))
    .matches(regexPatterns.name, intl.formatMessage({ id: 'form.validation.invalidCharacters' }))
    .max(
      maxCharLength,
      intl.formatMessage(
        {
          id: 'form.validation.max',
        },
        { max: maxCharLength },
      ),
    );
};

export const validateDateOfBirthDate = (intl: IntlShape): Yup.DateSchema => {
  const today = new Date();
  const eighteenYearsAgo = dayjs().minusYears(18).toDate();
  const oneHundredTwentyYearsAgo = dayjs().minusYears(120).toDate();

  return Yup.date()
    .required(intl.formatMessage({ id: 'form.validation.errorFieldRequired' }))
    .test('not-future-date', intl.formatMessage({ id: 'form.validation.futureDateNotAllowed' }), (value) => value <= today)
    .max(eighteenYearsAgo, intl.formatMessage({ id: 'form.validation.minimumAge' }))
    .min(oneHundredTwentyYearsAgo, intl.formatMessage({ id: 'form.validation.maximumAge' }))
    .typeError(intl.formatMessage({ id: 'form.validation.invalidDate' }));
};

export const validateDialCountryCode = (intl: IntlShape): Yup.StringSchema => {
  return Yup.string()
    .required(intl.formatMessage({ id: 'form.validation.errorFieldRequired' }))
    .matches(regexPatterns.dialCode, intl.formatMessage({ id: 'form.validation.invalidDialCountryCode' }))
    .min(1, intl.formatMessage({ id: 'form.validation.atLeastOneCharacter' }, { min: 1 }))
    .max(7, intl.formatMessage({ id: 'form.validation.max' }, { max: 7 }));
};

export const validateMobileNumber = (intl: IntlShape): Yup.StringSchema => {
  return Yup.string()
    .required(intl.formatMessage({ id: 'form.validation.errorFieldRequired' }))
    .test('no-alpha-characters', intl.formatMessage({ id: 'form.validation.invalidMobile' }), (value) => /^\d+$/.test(value || ''))
    .test('is-valid-phone-number', intl.formatMessage({ id: 'form.validation.invalidMobile' }), function (value) {
      const countryCode = this.parent['phoneNumberCountryCode'];
      return isValidPhoneNumber(`${countryCode}${value || ''}`);
    });
};

export const validateStreetName = (intl: IntlShape): Yup.StringSchema => {
  return Yup.string()
    .required(intl.formatMessage({ id: 'form.validation.errorFieldRequired' }))
    .matches(regexPatterns.streetName, intl.formatMessage({ id: 'form.validation.invalidStreet' }))
    .max(250, intl.formatMessage({ id: 'form.validation.max' }, { max: 250 }));
};

export const validateHouseNumber = (intl: IntlShape): Yup.StringSchema => {
  return Yup.string()
    .required(intl.formatMessage({ id: 'form.validation.errorFieldRequired' }))
    .matches(regexPatterns.houseNumber, intl.formatMessage({ id: 'form.validation.invalidHouseNumber' }))
    .max(10, intl.formatMessage({ id: 'form.validation.max' }, { max: 10 }));
};

export const validateAddressExtension = (intl: IntlShape): Yup.StringSchema => {
  return Yup.string()
    .test('is-valid-address-extension', intl.formatMessage({ id: 'form.validation.invalidAddressExtension' }), (value) => {
      // If the string is empty, consider it valid (since the field is optional)
      if (!value) return true;

      // Apply regex only for non-empty strings
      const regex = regexPatterns.address;
      console.log(regex.test(value));
      return regex.test(value);
    })
    .max(250, intl.formatMessage({ id: 'form.validation.max' }, { max: 250 }));
};

export const validatePostalCode = (intl: IntlShape): Yup.StringSchema => {
  return Yup.string()
    .required(intl.formatMessage({ id: 'form.validation.errorFieldRequired' }))
    .matches(regexPatterns.postalCode, intl.formatMessage({ id: 'form.validation.invalidPostalCode' }))
    .max(10, intl.formatMessage({ id: 'form.validation.max' }, { max: 10 }));
};

export const validateCity = (intl: IntlShape): Yup.StringSchema => {
  return Yup.string()
    .required(intl.formatMessage({ id: 'form.validation.errorFieldRequired' }))
    .matches(regexPatterns.cityName, intl.formatMessage({ id: 'form.validation.invalidCity' }))
    .max(250, intl.formatMessage({ id: 'form.validation.max' }, { max: 250 }));
};
