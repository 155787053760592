import type { JSX } from 'react';
import React, { Fragment, MouseEvent } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { VehicleTypeHelper } from '../../../../models';
import { BudgetPlanLastChangeAttemptStatus } from '../../../../models/budget-plan';
import { BudgetPlanDto } from '../../../../services/budget-plan';
import { statusContainerStyles, statusIndicatorCircleStyles } from '../styles';

export const tableHeadingTranslationKey = 'operator.budgetPlan.table.heading';

export const useMakeBudgetPlanDetailsTableColumns: StatusChangeClickToTableColumnsFunction = (onStatusChangeCellClick) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const handleFailedStatusCellClick = (e: MouseEvent<HTMLButtonElement>, budgetPlan: BudgetPlanDto) => {
    e.stopPropagation();
    onStatusChangeCellClick(budgetPlan);
  };

  return [
    {
      Header: formatMessage({ id: `common.name` }),
      Cell: ({ row: { original: budgetPlan } }: RowData) => (
        <Typography variant="bodySmall" color={theme.palette.baseLight.base76} px={0.5}>
          {budgetPlan.activeState.name}
        </Typography>
      ),
    },
    {
      Header: formatMessage({ id: `common.depositFrequency` }),
      Cell: ({ row: { original: budgetPlan } }: RowData) => (
        <Typography variant="bodySmall" title={budgetPlan.activeState.depositFrequencyShortAndLongFormDisplayText().longForm} px={0.5}>
          {budgetPlan.activeState.depositFrequencyShortAndLongFormDisplayText().shortForm}
        </Typography>
      ),
    },
    {
      Header: formatMessage({ id: `${tableHeadingTranslationKey}.spendRestriction` }),
      Cell: ({ row: { original: budgetPlan } }: RowData) => {
        const { amountPerTripConstraint } = budgetPlan.activeState;
        return (
          <Typography variant="bodySmall" color={theme.palette.baseLight.base76} px={0.5}>
            {amountPerTripConstraint || `${formatMessage({ id: 'common.na' })}`}
          </Typography>
        );
      },
    },
    {
      Header: formatMessage({ id: `common.vehicleTypes` }),
      Cell: ({ row: { original: budgetPlan } }: RowData) => {
        const { vehicleTypesConstraint } = budgetPlan.activeState;
        return (
          <Typography variant="bodySmall" color={theme.palette.baseLight.base76} px={0.5}>
            {vehicleTypesConstraint
              ? vehicleTypesConstraint.map((vt) => VehicleTypeHelper.metadata(vt).displayText).join(', ')
              : formatMessage({ id: 'common.noRuleSet' })}
          </Typography>
        );
      },
    },
    {
      Header: formatMessage({ id: `common.lastStatusUpdate` }),
      Cell: ({ row: { original: budgetPlan } }: RowData) => {
        const {
          lastChangeAttempt: { status: lastChangeAttemptStatus },
        } = budgetPlan;
        const statusDisplayTextAndColor = budgetPlan.statusChangeAttemptToDisplayTextAndColor();
        const content = (
          <Fragment>
            <Box sx={statusIndicatorCircleStyles(theme, statusDisplayTextAndColor.color)}></Box>
            <Typography component="span" variant="bodyMedium" color={statusDisplayTextAndColor.color}>
              {statusDisplayTextAndColor.displayText}
            </Typography>
          </Fragment>
        );
        return (
          <Fragment>
            <Box px={0.5}>
              {lastChangeAttemptStatus === BudgetPlanLastChangeAttemptStatus.Failed ? (
                <Button sx={{ ...statusContainerStyles(theme), p: 0 }} onClick={(e) => handleFailedStatusCellClick(e, budgetPlan)}>
                  {content}
                </Button>
              ) : (
                <Box sx={{ ...statusContainerStyles(theme) }}>{content}</Box>
              )}
            </Box>
          </Fragment>
        );
      },
    },
  ];
};

interface RowData {
  row: {
    original: BudgetPlanDto;
  };
}

interface BudgetPlansTableColumnMetadata {
  Header: string;
  Cell: (row: RowData) => JSX.Element | string;
}

type BudgetPlanStatusChangeClickHandler = (budgetPlanId: BudgetPlanDto) => void;
type StatusChangeClickToTableColumnsFunction = (
  onStatusChangeCellClick: BudgetPlanStatusChangeClickHandler,
) => BudgetPlansTableColumnMetadata[];
