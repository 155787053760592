import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';
import { ListItemConfig } from '../common';

export enum BudgetPlanTab {
  PlanDetails = 'planDetails',
  Members = 'members',
}

interface BudgetPlanTabUnderivedMetadata {
  displayText: string;
}

export namespace BudgetPlanTabHelper {
  const { formatMessage } = intl;
  const tabToUnderivedMetadata = new Map<BudgetPlanTab, BudgetPlanTabUnderivedMetadata>([
    [BudgetPlanTab.PlanDetails, { displayText: formatMessage({ id: `budgetPlanTab.planDetails` }) }],
    [BudgetPlanTab.Members, { displayText: formatMessage({ id: `common.members` }) }],
  ]);

  export function metadata(budgetPlanTab: BudgetPlanTab): BudgetPlanTabUnderivedMetadata {
    const underivedMetadata = tabToUnderivedMetadata.get(budgetPlanTab);
    if (!underivedMetadata) {
      throw Error(`
              Specified BudgetPlan Tab Type: ${toString(budgetPlanTab)} doesnt have corresponding underived/explicitly defined metadata.
              This usually (not always) means a bug or incomplete implementation.
          `);
    }

    return { ...underivedMetadata };
  }

  export function toString(budgetPlanTab: BudgetPlanTab): string {
    return EnumWithStringValue.getEnumKeyByEnumValue(BudgetPlanTab, budgetPlanTab)!;
  }

  export function allValues(): BudgetPlanTab[] {
    return EnumWithStringValue.enumToValues(BudgetPlanTab);
  }

  export function allTabLabelAndValues(): ListItemConfig<BudgetPlanTab>[] {
    return allValues().map((budgetPlanTab) => {
      return {
        value: budgetPlanTab,
        label: metadata(budgetPlanTab).displayText,
      };
    });
  }
}
