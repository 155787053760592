import { useState, useMemo } from 'react';
import type { MouseEvent } from 'react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { LoadingAndErrorWithRetryAndNoResults } from '../../components/LoadingAndErrorWithRetryAndNoResults';
import ZoneControls from '../../components/Map/ZoneControls';
import { MapTableView } from '../../components/MapTableView';
import { DayOfWeekHelper, FloatingButtonType, ModeType, VehicleType, ZoneType } from '../../models';
import { ZonePreviewPredicates, Mode } from '../../services/zone';
import { zoneTypesSelector, vehicleTypesSelector } from '../../state';
import { loadingVehiclesSelector } from '../../state';
import {
  END_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY,
  HEADER_HEIGHT,
  HEADER_TABLE_GAP_HEIGHT,
  MAX_DEFAULT_DATE,
  MIN_DEFAULT_DATE,
  START_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY,
  TABLE_HEIGHT_NO_SECONDARY,
} from '../../utils/constants';
import { intl } from '../../utils/intl';
import { FloatingBox, Switch, SwitchButonConfig, ReactTable, NonClickableBreadCrumb } from '../../wmv-components';

import OperatorColumns from './OperatorColumns';
import useFetchMspVehicles from './useFetchMspVehicles';
import useFetchPublishedZones from './useFetchPublishedZones';

const switchButtonConfig: SwitchButonConfig[] = [
  {
    text: intl.formatMessage({ id: 'map.mapMode' }),
    switchModeButtonId: FloatingButtonType.MapMode,
  },
  {
    text: intl.formatMessage({ id: 'map.listMode' }),
    switchModeButtonId: FloatingButtonType.ListMode,
  },
];

const Operator = () => {
  const location = useLocation();
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const zoneTypes: ZoneType[] = useSelector(zoneTypesSelector);
  const vehicleTypes: VehicleType[] = useSelector(vehicleTypesSelector);

  const { allMspVehicles: dataVehicles, loading: loadingMspVehicle, error: errorMspVehicle, getAggregatedVehicles } = useFetchMspVehicles();
  const { allPublishedZones: publishedZones, getPublishedZones } = useFetchPublishedZones();

  const applicablePublishedZones = useMemo(() => {
    const zonePreviewPredicates = new ZonePreviewPredicates(
      new Mode(ModeType.WideInterval),
      new Set(zoneTypes),
      MIN_DEFAULT_DATE.asDayjsDate(),
      MAX_DEFAULT_DATE.asDayjsDate(),
      START_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY,
      END_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY,
      DayOfWeekHelper.allDaysOfWeek(),
      new Set(vehicleTypes),
    );
    return zonePreviewPredicates.provideApplicableZones(dayjs.now().toEpochMillis(), publishedZones);
  }, [vehicleTypes, zoneTypes, publishedZones]);

  const [processingStatuses, setProcessingStatuses] = useState<{ [key: string]: boolean }>({});
  //Assumption: There will be periodic checks for aggregated Vehicles in the future - This is a mock simulating that some status changes will not be successful
  const [failedStatuses, setFailedStatuses] = useState<{ [key: string]: boolean }>({});

  const loadingVehiclesData = useSelector(loadingVehiclesSelector);

  const columns = OperatorColumns({ processingStatuses, setProcessingStatuses, failedStatuses, setFailedStatuses });

  const queryParams = new URLSearchParams(location.search);
  const listViewQueryParam = queryParams.get('openListView');
  const initialMapViewType = listViewQueryParam === 'true' ? FloatingButtonType.ListMode : FloatingButtonType.MapMode;
  const [activeView, setActiveView] = useState(initialMapViewType);
  const breadCrumbTexts = [formatMessage({ id: 'menu.support' }), formatMessage({ id: 'support.fleetManagement' })];
  const isMapMode = activeView === FloatingButtonType.MapMode;
  const isSupporterMode = listViewQueryParam === 'true';

  const handleSwitchModeButtonClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, switchModeButtonId: string) => {
    setActiveView(switchModeButtonId as FloatingButtonType);
  };

  const fetchAggregateVehiclesAndPublishedZones = async () => {
    await Promise.all([getAggregatedVehicles(), getPublishedZones()]);
  };

  return (
    <>
      {loadingMspVehicle || errorMspVehicle || !dataVehicles.length ? (
        <LoadingAndErrorWithRetryAndNoResults
          loading={loadingMspVehicle}
          error={errorMspVehicle}
          baseTranslationKey="operator"
          onRetry={fetchAggregateVehiclesAndPublishedZones}
          style={{ height: `calc(100vh - ${HEADER_HEIGHT})` }}
          noResults={!dataVehicles.length}
        />
      ) : (
        <Box sx={{ backgroundColor: theme.palette.dark.shade3 }}>
          {isSupporterMode && <NonClickableBreadCrumb values={breadCrumbTexts} />}
          <FloatingBox boxStyling={{ top: isSupporterMode ? '156px' : '104px' }}>
            <Switch config={switchButtonConfig} activeButtonId={activeView} onClick={handleSwitchModeButtonClick} />
            {isMapMode && <ZoneControls />}
          </FloatingBox>

          {!isMapMode ? (
            <Box sx={{ paddingTop: HEADER_TABLE_GAP_HEIGHT }}>
              {dataVehicles.length > 0 && (
                <ReactTable
                  loading={loadingVehiclesData}
                  columns={[...columns]}
                  tableContainerStyle={{
                    border: '1px solid #f0f0f0',
                    borderRadius: '8px',
                    backgroundColor: theme.palette.white.main,
                  }}
                  renderDetailsComponent={() => {}}
                  onRowClickHandler={() => {}}
                  customRowHoverStyle={{ backgroundColor: theme.palette.secondary.tint }}
                  displayLastDifferent={true}
                  data={dataVehicles}
                  pagination={false}
                />
              )}
            </Box>
          ) : (
            <Box sx={{ height: TABLE_HEIGHT_NO_SECONDARY }}>
              <MapTableView zones={applicablePublishedZones} />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default Operator;
