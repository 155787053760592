import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ErrorMessage as FormikError } from 'formik';

import CustomIcon from '../../utils/CustomIcon';

const FormikErrorMessage = (props: { name: string }) => {
  const theme = useTheme();
  return (
    <Box marginTop={0.5} color={theme.palette.alert.main} height={theme.typography.caption.lineHeight}>
      <FormikError {...props}>{(error) => <ErrorMessage error={error} />}</FormikError>
    </Box>
  );
};

const ErrorMessage = (props: { error: string | null | undefined }) => {
  const theme = useTheme();

  if (!props.error) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" paddingTop={0.5} paddingBottom={0.5}>
      {CustomIcon('AttentionIcon', theme.palette.alert.main, theme.typography.caption.lineHeight)}
      <Typography variant="caption" color="error" marginLeft={0.5}>
        {props.error}
      </Typography>
    </Box>
  );
};

export { FormikErrorMessage as default, ErrorMessage };
