import { createAction } from '@reduxjs/toolkit';

import { ZoneType } from '../../models';
import { GraphqlVehicleUsageStatus } from '../../models';
import { GraphqlVehicleType } from '../../models';
import { InfrastructureType, MspType } from '../../utils/constants';

import { MapFilterOption } from './mapFilters.model';

export const updateVehicleTypeFilterOptionsAction = createAction<MapFilterOption<GraphqlVehicleType>[]>(
  'mapFilters/updateVehicleTypeFilterOptions',
);
export const updateVehicleStatusFilterOptionsAction = createAction<MapFilterOption<GraphqlVehicleUsageStatus>[]>(
  'mapFilters/updateVehicleStatusFilterOptions',
);
export const updateZoneTypeFilterOptionsAction = createAction<MapFilterOption<ZoneType>[]>('mapFilters/updateZoneTypeFilterOptions');
export const updateMspTypeFilterOptionsAction = createAction<MapFilterOption<MspType>[]>('mapFilters/updateMspTypeFilterOptions');
export const updateInfrastructureTypeFilterOptionsAction = createAction<MapFilterOption<InfrastructureType>[]>(
  'mapFilters/updateInfrastructureTypeFilterOptions',
);
export const resetFilterOptionsAction = createAction<void>('mapFilters/resetFilterOptions');
