import { createReducer, current } from '@reduxjs/toolkit';

import { FilterProfileType } from '../../models';
import { MOCK_FILTERPROFILES } from '../../utils/constants';

import { applyFilterProfileAction, deleteFilterProfileAction, favoriteFilterProfileAction, updateFilterProfilesAction } from './actions';

export type AnalyticsState = {
  filterProfiles: FilterProfileType[];
  selectedFilterProfile: string;
  favoriteFilterProfile: string | undefined;
};

const initialState: AnalyticsState = {
  filterProfiles: MOCK_FILTERPROFILES,
  selectedFilterProfile: 'Filter Set 1',
  favoriteFilterProfile: undefined,
};

const commonReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(updateFilterProfilesAction, (state: AnalyticsState, action: any) => {
      const stateFP = [...current(state).filterProfiles];
      const actionFP = [...action.payload];
      const fnames = actionFP.map((ap: FilterProfileType) => ap.filterName);
      stateFP.forEach((sfp: FilterProfileType, index) => {
        if (fnames.includes(sfp.filterName)) {
          // Add the new object to the state array.
          const i = actionFP.findIndex((afp: FilterProfileType) => afp.filterName === sfp.filterName);
          stateFP.splice(index, 1, actionFP[i]);
          // remove this object from action payload array.
          actionFP.splice(i, 1);
        }
      });
      console.log({ ...state, filterProfiles: [...actionFP, ...stateFP] });
      return { ...state, filterProfiles: [...actionFP, ...stateFP] };
    })
    .addCase(applyFilterProfileAction, (state: AnalyticsState, action: any) => {
      return { ...state, selectedFilterProfile: action.payload };
    })
    .addCase(favoriteFilterProfileAction, (state: AnalyticsState, action: any) => {
      const stateFP = [...current(state).filterProfiles];
      const modifiedFPIndex = stateFP.findIndex((f) => f.filterName === action.payload);
      if (modifiedFPIndex > -1) {
        stateFP.splice(modifiedFPIndex, 1, {
          ...stateFP[modifiedFPIndex],
          favorite: !stateFP[modifiedFPIndex].favorite,
        });
        return { ...state, filterProfiles: [...stateFP] };
      }
      return state;
    })
    .addCase(deleteFilterProfileAction, (state: AnalyticsState, action: any) => {
      const stateFP = [...current(state).filterProfiles];
      const indexToDelete = state.filterProfiles.findIndex((f: FilterProfileType) => f.filterName === action.payload);
      if (indexToDelete > -1) {
        stateFP.splice(indexToDelete, 1);
        return { ...state, filterProfiles: [...stateFP] };
      }
      return state;
    }),
);

export default commonReducer;
