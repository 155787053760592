/* eslint-disable import/named */
import {
  ApolloClient,
  InMemoryCache,
  split,
  HttpLink,
  fromPromise,
  from,
  DocumentNode,
  NextLink,
  Operation,
  FetchResult,
  ServerError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { Observable, getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

import UserService from './UserService';

const params = {
  url: process.env.REACT_APP_GRAPHQL_WS_LINK || '',
  // retryAttempts: 0,
  connectionParams: () => ({
    Authorization: `Bearer ${UserService.getToken()}`,
  }),
  retryAttempts: Infinity,
  shouldRetry: () => true,
};

const isSubscription = (query: DocumentNode) => {
  const definition = getMainDefinition(query);
  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
};

const wsLink = new GraphQLWsLink(createClient(params));

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_HTTPS_LINK,
});

const authLink = setContext((_, { headers }) => {
  const token = UserService.getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const splitLink = split(
  ({ query }) => {
    return isSubscription(query);
  },
  wsLink,
  httpLink,
);

const retryOperation = (forward: NextLink, operation: Operation): Observable<FetchResult> => {
  return fromPromise(
    UserService.updateToken(5).catch((error) => {
      // TODO: show notification for issue in token updation and user being redirected to login page.
      // Handle token refresh errors e.g clear stored tokens, redirect to login
      console.log('Access token refresh error: ', error);
      client.clearStore();
      return UserService.doLogout();
    }),
  )
    .filter((value) => Boolean(value))
    .flatMap((refreshed) => {
      const newToken = UserService.getToken();
      const oldHeaders = operation.getContext().headers;
      // apply the new token in operation context.
      operation.setContext({
        headers: {
          ...oldHeaders,
          authorization: `Bearer ${newToken}`,
        },
      });
      // clear cache.
      client.clearStore();
      // check if the error was caused to a subscription call then we terminate the ws and create new one and retry.
      if (isSubscription(operation.query)) {
        console.log('Websocket is being terminated, a retry will happen.');
        wsLink.client.terminate();
      }
      // retry the request.
      return forward(operation);
    });
};

interface CustomServerError extends ServerError {
  errors: any[];
}

const errorLink = onError(({ response, graphQLErrors, networkError, forward, operation }: ErrorResponse) => {
  const responseError: CustomServerError | undefined = response as CustomServerError;
  // responseError = responseErrors["ws-no-invalid-expired-token"];
  if (responseError) {
    if (responseError.statusCode === 511) {
      console.log('Server Error');
    }
    if (responseError.statusCode === 401) {
      if (UserService.isLoggedIn()) {
        console.log('User token in still valid! Invalidating it to get a fresh one.');
        UserService.invalidateToken();
      }
      return retryOperation(forward, operation);
    }
    if (responseError.statusCode === 403) {
      console.log('Access FORBIDDEN');
    }
    if (responseError?.errors || graphQLErrors) {
      for (let err of responseError?.errors || graphQLErrors) {
        switch (err.extensions.classification) {
          case 'UNAUTHORIZED':
            console.log('Access UNAUTHORIZED');
            if (UserService.isLoggedIn()) {
              console.log('User token in still valid! Invalidating it to get a fresh one.');
              UserService.invalidateToken();
            }
            return retryOperation(forward, operation);
          case 'FORBIDDEN':
            console.log('Access FORBIDDEN');
            break;
          case 'ValidationError':
            console.log('Validation Error');
            break;
          default:
            console.log(err.extensions.classification);
        }
      }
    } else {
      console.log('Unhandled Error', responseError);
    }
  }
});

export const client = new ApolloClient({
  link: from([authLink, errorLink, splitLink]),
  cache: new InMemoryCache(),
});
