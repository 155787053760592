import {
  CustomerDetailsType,
  DashboardShortcut,
  GetTripsQueryParams,
  LawEnforcementTripSummaryResponse,
  SearchUsersQueryParams,
  UserTrip,
  UserTripDetails,
  VehicleTripType,
  ZoneResponse,
  ZoneRequest,
  ZoneStatus,
} from '../models';
import {
  CreateBudgetPlanRequest,
  BudgetPlanSummaryCumDetailsResponse,
  UpdateBudgetPlanStatusRequest,
  UpdateBudgetPlanRequest,
  BudgetPlanMembershipSummaryResponse,
  BudgetPlanMembershipDetailsResponse,
} from '../models/budget-plan';
import { AggregatedVehicle } from '../models/fleet-management';
import { ChangeStatusRequest } from '../models/fleet-management';
import { ChangeRequestResponse } from '../models/fleet-management';
import axiosInstance from '../services/AxiosClient';
import UserService from '../services/UserService';

export const searchUsers = ({ firstName, lastName, birthDate }: SearchUsersQueryParams): Promise<CustomerDetailsType[]> => {
  return axiosInstance.get(`/users/?firstName=${firstName}&lastName=${lastName}&birthDate=${birthDate}`);
};

export const getTripsByVehicleId = ({
  vehicleId,
  fromInclusiveEpochMillis,
  toInclusiveEpochMillis,
}: GetTripsQueryParams): Promise<VehicleTripType[]> => {
  return axiosInstance.get(
    `/operation/vehicles/${vehicleId}/trips?fromInclusiveEpochMillis=${fromInclusiveEpochMillis}&toInclusiveEpochMillis=${toInclusiveEpochMillis}`,
  );
};

export const putCustomerDetails = (
  payload: Partial<CustomerDetailsType>,
  subjectClaim: string,
): Promise<CustomerDetailsType | undefined | null> => {
  return axiosInstance.put(`/users/${subjectClaim}/updateBySupport`, payload);
};

export const getCustomerDetails = (subjectClaim: string): Promise<CustomerDetailsType> => {
  return axiosInstance.get(`/users/${subjectClaim}`);
};

export const getUserTrips = (subjectClaim: string): Promise<UserTrip[]> => {
  return axiosInstance.get(`operation/users/${subjectClaim}/trips`);
};

export const getUserTripDetails = (tripId: BigInt): Promise<UserTripDetails> => {
  return axiosInstance.get(`operation/trips/${tripId}`);
};

export const getDashboardWidgetsData = (): Promise<DashboardShortcut[]> => {
  return axiosInstance.get(`/users/mobility-manager/dashboard-shortcuts`);
};

export const getAllDashboardWidgetsData = (): Promise<DashboardShortcut[]> => {
  return axiosInstance.get(`/users/mobility-manager/metadata/dashboard-shortcut-categories`);
};

export const fetchZonesByStatus = (status: ZoneStatus): Promise<ZoneResponse[]> => {
  return axiosInstance.get(`/mobility-manager/zones?status=${status}`);
};

export const fetchAllZones = (): Promise<ZoneResponse[]> => {
  return axiosInstance.get(`/mobility-manager/zones`);
};

export const createZone = (payload: ZoneRequest): Promise<ZoneResponse> => {
  return axiosInstance.post(`/mobility-manager/zones`, payload);
};

export const updateZone = (zoneId: number, payload: ZoneRequest): Promise<ZoneResponse> => {
  return axiosInstance.put(`/mobility-manager/zones/${zoneId}`, payload);
};

export const deleteZone = (zoneId: number): Promise<ZoneResponse> => {
  return axiosInstance.delete(`/mobility-manager/zones/${zoneId}`);
};

export const forceStopTrip = (tripId: BigInt, payload: any): Promise<void> => {
  return axiosInstance.post(`operation/trips/${tripId}/force-stop`, payload);
};

export const fetchTripSummariesForLawEnforcement = (
  fromInclusiveEpochMillis: number,
  toInclusiveEpochMillis: number,
): Promise<LawEnforcementTripSummaryResponse[]> => {
  return axiosInstance.get(
    `/operation/lookup/trips-for-law-enforcement?fromInclusiveEpochMillis=${fromInclusiveEpochMillis}&toInclusiveEpochMillis=${toInclusiveEpochMillis}`,
  );
};

export const listBudgetPlans = (): Promise<BudgetPlanSummaryCumDetailsResponse[]> => {
  return axiosInstance.get(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans`);
};

export const addBudgetPlan = (payload: CreateBudgetPlanRequest): Promise<BudgetPlanSummaryCumDetailsResponse> => {
  return axiosInstance.post(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans`, payload);
};

export const updateBudgetPlan = (payload: UpdateBudgetPlanRequest, planId: number): Promise<BudgetPlanSummaryCumDetailsResponse> => {
  return axiosInstance.put(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${planId}`, payload);
};

export const updateBudgetPlanStatus = (
  payload: UpdateBudgetPlanStatusRequest,
  planId: number,
): Promise<BudgetPlanSummaryCumDetailsResponse> => {
  return axiosInstance.put(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${planId}`, payload);
};

export const fetchBudgetPlanByIdApi = (id: number): Promise<BudgetPlanSummaryCumDetailsResponse> => {
  return axiosInstance.get(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${id}`);
};

export const fetchBudgetPlanMembershipSummaries = (budgetPlanId: number): Promise<BudgetPlanMembershipSummaryResponse[]> => {
  return axiosInstance.get(
    `/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${budgetPlanId}/memberships`,
  );
};

export const fetchBudgetPlanMembershipDetails = (
  budgetPlanId: number,
  budgetPlanMembershipId: number,
): Promise<BudgetPlanMembershipDetailsResponse> => {
  return axiosInstance.get(
    `/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${budgetPlanId}/memberships/${budgetPlanMembershipId}`,
  );
};

export const fetchAggregatedVehicles = (): Promise<AggregatedVehicle[]> => {
  return axiosInstance.get(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/vehicles`);
};

export const changeVehicleStatus = (payload: ChangeStatusRequest, vehicleId: number): Promise<ChangeRequestResponse> => {
  return axiosInstance.patch(
    `mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/vehicles/${vehicleId}/status`,
    payload,
  );
};
