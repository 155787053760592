import { Typography } from '@mui/material';
import type { IntlShape } from 'react-intl';
import { Column } from 'react-table';

import { formatDistanceFromMeters } from '../../../utils/DateMethods';

export const makeTripTableColumns = (intl: IntlShape) => {
  const { formatMessage } = intl;

  const columns: Column[] = [
    {
      Header: formatMessage({ id: 'informationTable.header.tripId' }),
      accessor: 'id',
      Cell: ({ row }: any) => (
        <Typography variant="bodySmall" px={1}>
          {formatMessage({ id: 'customerTable.heading.id' })}
          {row.original.id.toString()}
        </Typography>
      ),
    },
    {
      Header: formatMessage({ id: 'informationTable.header.startEpochMillis' }),
      accessor: 'startEpochMillis',
      Cell: ({ row }: any) => (
        <Typography variant="bodySmall">{row.original.startEpochMillis.parseEpochMillis().formatAsDateTimeString()}</Typography>
      ),
    },
    {
      Header: formatMessage({ id: 'informationTable.header.endEpochMillis' }),
      accessor: 'endEpochMillis',
      Cell: ({ row }: any) =>
        row.original.active ? (
          <Typography sx={{ marginLeft: '2px' }} variant="bodySmall">
            {formatMessage({ id: 'common.dash' })}
          </Typography>
        ) : (
          <Typography variant="bodySmall">{row.original.endEpochMillis.parseEpochMillis().formatAsDateTimeString()}</Typography>
        ),
    },
    {
      Header: formatMessage({ id: 'informationTable.header.actualDurationSeconds' }),
      accessor: 'actualDurationSeconds',
      Cell: ({ row }: any) =>
        row.original.active ? (
          <Typography variant="bodySmall">{formatMessage({ id: 'common.dash' })}</Typography>
        ) : (
          <Typography variant="bodySmall" px={1}>
            {row.original.actualDurationSeconds.parseSecondsSinceBeginningOfDay().formatAsTimeWithoutMilliSeconds()}
          </Typography>
        ),
    },
    {
      Header: formatMessage({ id: 'informationTable.header.totalDistanceMeters' }),
      accessor: 'totalDistanceMeters',
      Cell: ({ row }: any) =>
        row.original.active ? (
          <Typography variant="bodySmall">{formatMessage({ id: 'common.dash' })}</Typography>
        ) : (
          <Typography variant="bodySmall" px={1}>
            {formatDistanceFromMeters(row.original.totalDistanceMeters)}
          </Typography>
        ),
    },
    {
      Header: formatMessage({ id: 'informationTable.header.active' }),
      accessor: 'active',
      Cell: ({ row }: any) => {
        return row.original.active ? (
          <Typography sx={{ color: 'orange', px: 1 }} variant="bodySmallBold">
            {formatMessage({ id: 'customerTable.row.active' })}
          </Typography>
        ) : (
          <Typography sx={{ color: 'green', px: 1 }} variant="bodySmallBold">
            {formatMessage({ id: 'customerTable.row.completed' })}
          </Typography>
        );
      },
    },
  ];

  return columns;
};
