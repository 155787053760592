import React, { useState } from 'react';

import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { useTable, useFilters, useSortBy } from 'react-table';

import DetailedVehicleInfo from '../components/DetailedModal/DetailedVehicleInfo';

import { DefaultColumnFilter } from './TableFilters';
import TableHeaderCell from './TableHeaderCell';
import TablePagination from './TablePagination';

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background: linear-gradient(0deg, rgba(47, 46, 65, 0.08), rgba(47, 46, 65, 0.08));
  }
  &:nth-of-type(even) {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.002), rgba(255, 255, 255, 0.002));
  }
  & > td {
    height: 40px;
    color: black;
    border: 0.2px solid rgba(47, 46, 65, 0.08);
  }
  & > th {
    color: black;
    border: 0.2px solid rgba(47, 46, 65, 0.08);
    background: rgba(47, 46, 65, 0.08);
  }
`;

const DataTable = ({
  getPreFilter,
  filterChangeDependency,
  columns,
  data,
  loading,
  rowCount = 15,
  tableContainerStyle,
  pagination = true,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [rowsPerPage, setRowsPerPage] = React.useState(rowCount);
  const [page, setPage] = React.useState(0);
  const { formatMessage } = useIntl();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
        });
      },
      select: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? filterValue.includes(String(rowValue)) : true;
        });
      },
      multiple: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? filterValue.includes(rowValue) : true;
        });
      },
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({});

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = (row) => {
    setOpen(true);
    setCurrentRow(row.original);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        sortBy: columns,
      },
      // disable filters on all columns, better use under column property.
      // disableFilters: true,
      // prevent reset of filters when data changes.
      autoResetFilters: false,
      disableMultiSort: false,
      autoResetSortBy: false,
      autoResetPage: false,
      // disableSortRemove: true,
    },
    useFilters, // useFilters!
    useSortBy,
  );

  //Column Hover
  // const [show, toggleInfoButtonShow] = useState(true);

  // Render the UI for your table

  const theme = useTheme();
  return (
    <Paper sx={{ width: '100%' }} elevation={0}>
      <TableContainer sx={{ width: '100%', overflow: 'auto', ...tableContainerStyle }}>
        {!loading && (
          <Table {...getTableProps()} sx={{ height: 'auto', minWidth: '1400px', tableLayout: 'fixed' }}>
            <TableHead>
              {headerGroups.map((headerGroup, index) => (
                <TableRowStyled {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <TableHeaderCell
                      key={index}
                      columns={columns}
                      column={column}
                      index={index}
                      getPreFilter={getPreFilter}
                      filterChangeDependency={filterChangeDependency}
                    />
                  ))}
                </TableRowStyled>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                prepareRow(row);
                return (
                  <TableRowStyled {...row.getRowProps()} hover onClick={() => handleOpenModal(row)} key={index}>
                    {row.cells.map((cell, index) => {
                      return (
                        <TableCell
                          key={index}
                          {...cell.getCellProps()}
                          sx={{
                            padding: '4px',
                            width: `calc(100vw / ${columns.length})`,
                          }}
                        >
                          <Typography variant="caption">{cell.render('Cell')}</Typography>
                        </TableCell>
                      );
                    })}
                  </TableRowStyled>
                );
              })}
              {open === true ? <DetailedVehicleInfo vehicle={currentRow} isOpen={open} onClose={handleCloseModal} /> : <></>}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {pagination === true && (
        <>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
            style={{
              width: '100%',
              paddingRight: '94px',
              boxShadow: theme.shadowOptions.aboveSmall,
            }}
          >
            {loading && <Typography variant="bodySmall">{formatMessage({ id: 'loadingSpinner.loadingText' })}</Typography>}
          </TablePagination>
        </>
      )}
    </Paper>
  );
};

export default DataTable;
