import { useCallback, useState, useLayoutEffect, useEffect } from 'react';

import type { Dayjs } from 'dayjs';
import find from 'lodash/find';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../../components/LoadingAndErrorWithRetryAndNoResults';
import { VehicleType } from '../../../../models';
import { BudgetPlanSummaryCumDetailsResponse, DepositFrequency } from '../../../../models/budget-plan';
import { BudgetPlanDto } from '../../../../services/budget-plan';
import {
  addBudgetPlanAsyncThunkAction,
  AppDispatch,
  BudgetPlanActionMode,
  budgetPlanActionModeSelector,
  budgetPlanApiStatusSelector,
  budgetPlansSelector,
  resetErrorAction,
  setBudgetPlanActionModeAction,
  updateBudgetPlanAsyncThunkAction,
} from '../../../../state';
import { fetchBudgetPlanByIdApi } from '../../../../utils/queries';
import { BudgetPlanModalCloseHandler, SelectedBudgetPlanIdChangeHandler } from '../BudgetPlanModal';
import { budgetPlanFormInitialValues, makeCreateBudgetPlanRequestPayload, makeUpdateBudgetPlanRequestPayload } from '../helpers';
import { BudgetPlanDetailsFetchState, PlanStatusTransitionFailureRetryClickHandler } from '../index';

import { BudgetPlanDetailsFormWithFormik } from './BudgetPlanDetailsForm';

export const BudgetPlanDetailsView = ({
  onBudgetPlanModalClose,
  selectedBudgetPlanId,
  onSelectedBudgetPlanIdChanged,
}: BudgetPlanDetailsViewProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const representsExistingBudgetPlan = !!selectedBudgetPlanId;

  const budgetPlans: BudgetPlanDto[] = useSelector(budgetPlansSelector);
  const budgetPlanApiStatus = useSelector(budgetPlanApiStatusSelector);
  const budgetPlanActionMode = useSelector(budgetPlanActionModeSelector);

  const [budgetPlanFormValues, setBudgetPlanFormValues] = useState<BudgetPlanDetailsFormValues>(budgetPlanFormInitialValues);
  const [budgetPlanDetailsFetchState, setBudgetPlanDetailsFetchState] = useState<BudgetPlanDetailsFetchState>({
    data: null,
    hasError: false,
    loading: false,
  });

  const setPlanDetailsFetchStateAsLoadingInProgress = () => {
    setBudgetPlanDetailsFetchState({
      loading: true,
      data: null,
      hasError: false,
    });
  };
  const setPlanDetailsFetchStateAsDataFetched = (selectedPlanDetails: BudgetPlanSummaryCumDetailsResponse) => {
    setBudgetPlanDetailsFetchState({
      loading: false,
      data: selectedPlanDetails,
      hasError: false,
    });
  };
  const setPlanDetailsFetchStateAsError = () => {
    setBudgetPlanDetailsFetchState({
      loading: false,
      data: null,
      hasError: true,
    });
  };

  const handleModalClose = () => {
    onBudgetPlanModalClose();
  };

  const fetchBudgetPlanDetails: PlanStatusTransitionFailureRetryClickHandler = useCallback(async (budgetPlanId: number) => {
    setPlanDetailsFetchStateAsLoadingInProgress();
    try {
      const selectedPlanDetails = await fetchBudgetPlanByIdApi(budgetPlanId);
      const selectedPlanDetailsDto = new BudgetPlanDto(selectedPlanDetails);
      setPlanDetailsFetchStateAsDataFetched(selectedPlanDetails);
      setBudgetPlanFormValues(selectedPlanDetailsDto.toFormInput(selectedPlanDetailsDto.activeState));
    } catch (error) {
      setPlanDetailsFetchStateAsError();
    }
  }, []);

  useLayoutEffect(() => {
    if (representsExistingBudgetPlan) {
      (async () => {
        await fetchBudgetPlanDetails(selectedBudgetPlanId);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (budgetPlanApiStatus['addPlan']) dispatch(resetErrorAction('addPlan'));
      if (budgetPlanApiStatus['updatePlan']) dispatch(resetErrorAction('updatePlan'));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBudgetPlanSubmit: BudgetPlanFormSubmitHandler = async (values: BudgetPlanDetailsFormValues) => {
    if (budgetPlanActionMode === BudgetPlanActionMode.Add) {
      const requestPayload = makeCreateBudgetPlanRequestPayload(values);
      // TODO: We are doing some side-effects inside redux reducer & also do side-effects after await.
      //  It will become really hard to know all changes that will change as we are having parallel side-effects.
      //  Use only one approach & simplify later. In all places where we are dispatching actions.
      const createdBudgetPlan = await dispatch(addBudgetPlanAsyncThunkAction(requestPayload)).unwrap();
      const createdBudgetPlanDto = new BudgetPlanDto(createdBudgetPlan);
      onSelectedBudgetPlanIdChanged(createdBudgetPlan.id);
      setBudgetPlanFormValues(createdBudgetPlanDto.toFormInput(createdBudgetPlanDto.activeState));
      dispatch(setBudgetPlanActionModeAction(BudgetPlanActionMode.View));
    }

    if (budgetPlanActionMode === BudgetPlanActionMode.Edit) {
      const selectedBudgetPlan = find(budgetPlans, ['id', selectedBudgetPlanId!])!;
      const requestPayload = makeUpdateBudgetPlanRequestPayload({ ...values, version: selectedBudgetPlan.version });
      await dispatch(updateBudgetPlanAsyncThunkAction({ id: selectedBudgetPlanId!, payload: requestPayload })).unwrap();
      handleModalClose();
    }
  };

  if (budgetPlanDetailsFetchState.loading || budgetPlanDetailsFetchState.hasError) {
    return (
      <LoadingAndErrorWithRetryAndNoResults
        error={budgetPlanDetailsFetchState.hasError}
        loading={budgetPlanDetailsFetchState.loading}
        onRetry={() => fetchBudgetPlanDetails!(selectedBudgetPlanId)}
        baseTranslationKey="budgetPlanDetails"
        style={{ height: '100%' }}
      />
    );
  }

  return (
    <BudgetPlanDetailsFormWithFormik
      initialValues={budgetPlanFormValues}
      onBudgetPlanFormSubmit={handleBudgetPlanSubmit}
      onBudgetPlanModalClose={handleModalClose}
    />
  );
};

interface BudgetPlanDetailsViewProps {
  onBudgetPlanModalClose: BudgetPlanModalCloseHandler;
  selectedBudgetPlanId: number;
  onSelectedBudgetPlanIdChanged: SelectedBudgetPlanIdChangeHandler;
}

export type BudgetPlanFormSubmitHandler = (values: BudgetPlanDetailsFormValues) => Promise<void>;

export interface BudgetPlanDetailsFormValues {
  name: string;
  depositFrequency: DepositFrequency;
  depositDayOfMonth?: number;
  depositTime: Dayjs | null;
  depositAmount: number | null;
  amountPerTripSpendConstraintExists: boolean | null;
  amountPerTripSpendConstraint?: number;
  vehicleTypesSpendConstraintExists: boolean | null;
  vehicleTypesSpendConstraint: VehicleType[];
}
