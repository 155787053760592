// eslint-disable-next-line import/named
import { FormControl, Select, MenuItem, Checkbox, InputLabel, OutlinedInput, SelectChangeEvent } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { FilterValueType, TableColumnType } from '../../models';

export interface CustomSelectFieldProps {
  field: any;
  form: any;
  name: string;
  outlineLabel: string;
  options: TableColumnType[];
  width?: string;
  value?: TableColumnType[];
  disabled?: boolean;
  defaultValue?: TableColumnType;
  multiple?: boolean;
  style: React.CSSProperties;
}

const CustomSelectField: React.FC<CustomSelectFieldProps> = ({ field, form, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const { outlineLabel, multiple, value = [], name, options, style } = props;

  const handleChange = async (event: SelectChangeEvent<FilterValueType<string>[]>) => {
    const value = event.target.value as FilterValueType<string>[];
    if (value.map((o) => o.value).indexOf('all') > -1) {
      await setFieldValue(name, value.length === options.length + 1 ? [] : options);
      return;
    }
    await setFieldValue(name, value);
  };

  const getRenderValue = (selected: FilterValueType<string>[]) => {
    if (!multiple) {
      return selected[0].value;
    } else {
      if (value) {
        if (value.length === options.length) return `${formatMessage({ id: 'common.all' })}`;
        return selected
          .filter((s) => s.value !== 'all')
          .map((op) => op.label)
          .join(', ');
      }
      return '';
    }
  };

  const { formatMessage } = useIntl();

  return (
    <>
      <FormControl sx={{ width: 275, ...style }}>
        <InputLabel size="small" id={outlineLabel}>
          {outlineLabel}
        </InputLabel>
        <Select
          multiple={multiple || false}
          fullWidth
          size="small"
          labelId={outlineLabel}
          id={outlineLabel}
          value={value}
          label={name}
          onChange={handleChange}
          onBlur={async () => {
            await setFieldTouched(name, true);
          }}
          sx={{
            // "& legend": { display: "none" },
            '& fieldset': { top: 0 },
          }}
          renderValue={getRenderValue}
          input={<OutlinedInput label={outlineLabel} />}
        >
          <MenuItem key="012" value={{ value: 'all', label: `${formatMessage({ id: 'common.all' })}` } as any}>
            <ListItemIcon>
              <Checkbox checked={value.length === options.length} indeterminate={value.length > 0 && value.length < options.length} />
            </ListItemIcon>
            <ListItemText>{formatMessage({ id: 'common.all' })}</ListItemText>
          </MenuItem>
          {options.map((op: FilterValueType<string>, index: number) => (
            <MenuItem key={index} value={op as any}>
              <ListItemIcon>
                <Checkbox checked={value.map((v: FilterValueType<string>) => v).indexOf(op) > -1} />
              </ListItemIcon>
              <ListItemText>{op.label}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default CustomSelectField;
