import dayjs, { Dayjs } from 'dayjs';

import { DayOfWeek, DayOfWeekHelper, VehicleType, ZoneType, ZoneTypeHelper } from '../../models';
import { ActiveOnPredicate, ZoneResponse } from '../../models';
import {
  END_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY,
  END_OF_DAY_TIME,
  START_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY,
} from '../../utils/constants';

import { ZoneGeoJsonFeature } from './models';

export class Zone {
  static maxSecondsFromBeginningOfDay = END_OF_DAY_TIME.asDayjsTime().toInclusive().secondsFromBeginningOfDay();
  static minSecondFromBeginningOfDay = 0;
  static maxDateForOpenEndedZones: Dayjs = '9999-12-31'.asDayjsDate().toEndOfDay();

  id: number;
  name: string;
  type: ZoneType;
  startDateInclusiveBeginningOfDay: Dayjs;
  endDateInclusiveEndOfDay: Dayjs = Zone.maxDateForOpenEndedZones;
  daysOfWeek: Set<DayOfWeek> = DayOfWeekHelper.allDaysOfWeek();
  startTimeInclusiveSecondsFromBeginningOfDay: number = Zone.minSecondFromBeginningOfDay;
  endTimeInclusiveSecondsFromBeginningOfDay: number = Zone.maxSecondsFromBeginningOfDay;
  activeOn: ActiveOnPredicate;
  applicableVehicleTypes: VehicleType[];
  openEnded: boolean;
  createdAtEpochMillis: number;
  updatedAtEpochMillis: number;
  geoJson: GeoJSON.Feature;

  constructor(model: ZoneResponse) {
    const {
      activeOn: { dateRange, timeWindow, daysOfWeek },
    } = model;

    this.type = model.type;
    this.activeOn = model.activeOn;
    this.name = model.name;
    this.id = model.id;
    this.applicableVehicleTypes = model.applicableVehicleTypes;
    this.startDateInclusiveBeginningOfDay = dayjs(dateRange.startInclusive).toBeginningOfDay();
    this.daysOfWeek = daysOfWeek ? new Set<DayOfWeek>(daysOfWeek) : DayOfWeekHelper.allDaysOfWeek();
    this.openEnded = dateRange.openEnded;
    this.createdAtEpochMillis = model.createdAtEpochMillis;
    this.updatedAtEpochMillis = model.updatedAtEpochMillis;
    this.geoJson = model.geoJson;
    this.applicableVehicleTypes = model.applicableVehicleTypes;
    if (!this.openEnded) {
      this.endDateInclusiveEndOfDay = dateRange.endInclusive!.asDayjsDate().toEndOfDay();
    }
    if (daysOfWeek && daysOfWeek.length) {
      this.daysOfWeek = new Set<DayOfWeek>(daysOfWeek);
    }
    if (timeWindow) {
      this.startTimeInclusiveSecondsFromBeginningOfDay = timeWindow.startInclusive.asDayjsTime().secondsFromBeginningOfDay();
      this.endTimeInclusiveSecondsFromBeginningOfDay = timeWindow.endExclusive.asDayjsTime().toInclusive().secondsFromBeginningOfDay();
    }
  }

  typeCompatibleWith(predicateZoneTypes: Set<ZoneType>): boolean {
    return predicateZoneTypes.has(this.type);
  }

  dayOfWeeksOverlapsWith(predicateDayOfWeeks: Set<DayOfWeek> = DayOfWeekHelper.allDaysOfWeek()) {
    return [...predicateDayOfWeeks].some((predicateDay) => this.daysOfWeek.has(predicateDay));
  }

  timeOverlapsWith(predicateStartTimeSecondsFromBeginningOfDay: number, predicateEndTimeSecondsFromBeginningOfDay: number): boolean {
    return (
      this.endTimeInclusiveSecondsFromBeginningOfDay >= predicateStartTimeSecondsFromBeginningOfDay &&
      this.startTimeInclusiveSecondsFromBeginningOfDay <= predicateEndTimeSecondsFromBeginningOfDay
    );
  }

  dateOverlapsWith(predicateStartDateInclusive: Dayjs, predicateEndDateInclusive: Dayjs): boolean {
    return (
      this.endDateInclusiveEndOfDay!.isSameOrAfter(predicateStartDateInclusive) &&
      this.startDateInclusiveBeginningOfDay.isSameOrBefore(predicateEndDateInclusive)
    );
  }

  applicableVehicleTypesOverlapsWith(applicableVehicleTypes: Set<VehicleType>) {
    return this.applicableVehicleTypes.some((applicableVehicleType) => applicableVehicleTypes.has(applicableVehicleType));
  }

  geoJsonFeature(): ZoneGeoJsonFeature {
    const { timeWindow } = this.activeOn;
    const representsFullDay =
      timeWindow.startInclusive.asDayjsTime().minutesFromBeginningOfDay() === START_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY &&
      timeWindow.endExclusive.asDayjsTime().minutesFromBeginningOfDay() === END_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY;
    return {
      type: this.geoJson.type,
      properties: {
        id: this.id,
        name: this.name,
        type: this.type,
        startTime: this.activeOn.timeWindow.startInclusive.asDayjsTime(),
        endTime: this.activeOn.timeWindow.endExclusive.asDayjsTime(),
        representsFullDay,
        startDate: this.activeOn.dateRange.startInclusive.asDayjsDate(),
        endDate: !this.openEnded ? this.activeOn.dateRange.endInclusive!.asDayjsDate() : null,
        indefiniteZone: this.openEnded,
        daysOfWeek: this.daysOfWeek,
        applicableVehicleTypes: this.applicableVehicleTypes,
        strokeColor: ZoneTypeHelper.metadata(this.type).color,
        fillColor: ZoneTypeHelper.metadata(this.type).color,
      },
      id: this.id,
      geometry: this.geoJson.geometry,
    };
  }
}
