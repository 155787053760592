import { createSelector } from '@reduxjs/toolkit';

import { VehicleTypeHelper, ZoneType, ZoneTypeHelper } from '../../models';
import { GraphqlVehicleUsageStatusHelper } from '../../models';
import { GraphqlVehicleType, graphqlVehicleTypeToVehicleType } from '../../models';
import { infrastructureTypesMenuConfig, mspTypesMenuConfig } from '../../utils/constants';
import { RootState } from '../reducers';

import { MapFilterOption } from './mapFilters.model';
import { MapFiltersState } from './reducer';

export const mapFiltersSelector = (state: RootState) => state.mapFilters;

export const vehicleTypeFilterOptionsSelector = createSelector(
  mapFiltersSelector,
  (mapFilters: MapFiltersState) => mapFilters.vehicleTypeOptions,
);

// This is because we have two kind of vehicle types from Backend (vehicles have a mspType whose values/ids are different from zone api vehicleTypes)
export const vehicleTypesSelector = createSelector(
  vehicleTypeFilterOptionsSelector,
  (graphqlVehicleTypeFilters: MapFilterOption<GraphqlVehicleType>[]) => {
    return graphqlVehicleTypeFilters.map((vehicleTypeFilter) => graphqlVehicleTypeToVehicleType.get(vehicleTypeFilter.value)!);
  },
);

export const zoneTypeMapFilterOptionsSelector = createSelector(
  mapFiltersSelector,
  (mapFilters: MapFiltersState) => mapFilters.zoneTypeOptions,
);
export const zoneTypesSelector = createSelector(zoneTypeMapFilterOptionsSelector, (zoneTypeFilters: MapFilterOption<ZoneType>[]) =>
  zoneTypeFilters.map((zoneTypeFilter) => zoneTypeFilter.value),
);
export const mspTypeMapFilterOptionsSelector = createSelector(
  mapFiltersSelector,
  (mapFilters: MapFiltersState) => mapFilters.mspTypeOptions,
);

export const vehicleUsageStatusesSelector = createSelector(mapFiltersSelector, (mapFilters: MapFiltersState) =>
  mapFilters.vehicleStatusOptions.map((vehicleStatusOption) => vehicleStatusOption.value),
);

export const isAnyFilterAppliedSelector = (state: RootState) =>
  state.mapFilters.infrastructureTypeOptions.length !== infrastructureTypesMenuConfig.length ||
  state.mapFilters.zoneTypeOptions.length !== ZoneTypeHelper.allZoneTypes().length ||
  state.mapFilters.mspTypeOptions.length !== mspTypesMenuConfig.length ||
  state.mapFilters.vehicleStatusOptions.length !== GraphqlVehicleUsageStatusHelper.allUsageStatuses().length ||
  state.mapFilters.vehicleTypeOptions.length !== VehicleTypeHelper.allVehicleTypes().length;
