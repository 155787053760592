import { useEffect, useState } from 'react';

import { AggregatedVehicle } from '../../models/fleet-management';
import { fetchAggregatedVehicles } from '../../utils/queries';

function useFetchMspVehicles() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allMspVehicles, setAllMspVehicles] = useState<AggregatedVehicle[]>([]);

  useEffect(() => {
    getAggregatedVehicles();
  }, []);

  const getAggregatedVehicles = async () => {
    setError(false);
    setLoading(true);
    try {
      const res = await fetchAggregatedVehicles();
      if (res.length === 0) {
        setAllMspVehicles([]);
      } else {
        setAllMspVehicles(res);
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  return { allMspVehicles, loading, error, getAggregatedVehicles };
}

export default useFetchMspVehicles;
