import { forwardRef } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import type { AlertProps } from '@mui/material/Alert';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export const SnackbarAlert = ({ onClose, message, open, type = 'success' }: SnackbarAlertProps) => {
  const handleSnackBarClose = () => {
    if (onClose) onClose();
  };

  let icon = null;
  if (type === 'success') icon = <CheckIcon />;
  if (type === 'error') icon = <InfoOutlinedIcon />;

  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} onClose={handleSnackBarClose} autoHideDuration={4000}>
      <Alert onClose={handleSnackBarClose} severity={type} sx={{ width: '400px' }} icon={icon}>
        {open && message}
      </Alert>
    </Snackbar>
  );
};

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface SnackbarAlertProps {
  onClose?: () => void;
  message: string;
  open: boolean;
  type: 'success' | 'error' | 'info' | 'warning';
}
