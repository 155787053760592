import { BudgetPlanTab } from '../../../models';

import { BudgetPlanDetailsView } from './BudgetPlanDetails';
import { BudgetPlanMembershipsView } from './BudgetPlanMemberships';
import { BudgetPlanModalCloseHandler, SelectedBudgetPlanIdChangeHandler } from './BudgetPlanModal';

export const BudgetPlanPopupTabContent = ({
  selectedBudgetPlanId,
  activeTab,
  onBudgetPlanModalClose,
  onSelectedBudgetPlanIdChanged,
}: TabPanelContentProps) => {
  if (activeTab === BudgetPlanTab.PlanDetails) {
    return (
      <BudgetPlanDetailsView
        selectedBudgetPlanId={selectedBudgetPlanId}
        onBudgetPlanModalClose={onBudgetPlanModalClose}
        onSelectedBudgetPlanIdChanged={onSelectedBudgetPlanIdChanged}
      />
    );
  }

  if (activeTab === BudgetPlanTab.Members) {
    return <BudgetPlanMembershipsView selectedBudgetPlanId={selectedBudgetPlanId} />;
  }
};

interface TabPanelContentProps {
  selectedBudgetPlanId: number;
  activeTab: BudgetPlanTab;
  onBudgetPlanModalClose: BudgetPlanModalCloseHandler;
  onSelectedBudgetPlanIdChanged: SelectedBudgetPlanIdChangeHandler;
}
