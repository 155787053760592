import dayjs, { Dayjs } from 'dayjs';

import { ModeType } from '../../models';

import { ApplicableZonesProvider } from './ApplicableZonesProvider';
import { SliderGranularity, SliderPointsWithGranularity } from './models';
import { SliderPointsProvider } from './SliderPointsProvider';
import { Zone } from './Zone';
import { ZonePreviewPredicates } from './ZonePreviewPredicates';

export class Mode implements ApplicableZonesProvider, SliderPointsProvider {
  readonly modeType: ModeType;

  constructor(modeType: ModeType) {
    this.modeType = modeType;
  }

  provideApplicableZones(computationTimestampEpochMillis: number, predicates: ZonePreviewPredicates, allZones: Zone[]): Zone[] {
    switch (this.modeType) {
      case ModeType.WideInterval:
        return this.provideApplicableZonesForWideInterval(predicates, allZones);
      case ModeType.DayWise:
        return this.provideApplicableZonesForDayWise(computationTimestampEpochMillis, predicates, allZones);
      case ModeType.PointInTime:
        return this.provideApplicableZonesForPointInTime(computationTimestampEpochMillis, predicates, allZones);
    }
  }

  private provideApplicableZonesForWideInterval(predicates: ZonePreviewPredicates, allZones: Zone[]): Zone[] {
    return allZones.filter((zone) => {
      const typeCompatible = zone.typeCompatibleWith(predicates.zoneTypes);
      if (typeCompatible) {
        const vehicleTypesOverlaps = zone.applicableVehicleTypesOverlapsWith(predicates.applicableVehicleTypes);
        if (vehicleTypesOverlaps) {
          const dayOfWeekOverlaps = zone.dayOfWeeksOverlapsWith(predicates.daysOfWeek);
          if (dayOfWeekOverlaps) {
            const timeOverlaps = zone.timeOverlapsWith(
              predicates.startTimeSecondsSinceBeginningOfDay,
              predicates.endTimeSecondsSinceBeginningOfDay,
            );
            if (timeOverlaps) {
              const dateOverlaps = zone.dateOverlapsWith(predicates.startDateInclusive, predicates.endDateInclusive);
              if (dateOverlaps) {
                return true;
              }
            }
          }
        }
      }
      return false;
    });
  }

  private provideApplicableZonesForDayWise(
    computationTimestampEpochMillis: number,
    predicates: ZonePreviewPredicates,
    allZones: Zone[],
  ): Zone[] {
    const computationDate = dayjs(computationTimestampEpochMillis);

    return allZones.filter((zone) => {
      const typeCompatible = zone.typeCompatibleWith(predicates.zoneTypes);
      if (typeCompatible) {
        const vehicleTypesOverlaps = zone.applicableVehicleTypesOverlapsWith(predicates.applicableVehicleTypes);
        if (vehicleTypesOverlaps) {
          const dayOfWeekOverlaps = zone.dayOfWeeksOverlapsWith(new Set([computationDate.dayOfWeek()]));
          if (dayOfWeekOverlaps) {
            const timeOverlaps = zone.timeOverlapsWith(
              predicates.startTimeSecondsSinceBeginningOfDay,
              predicates.endTimeSecondsSinceBeginningOfDay,
            );
            if (timeOverlaps) {
              const dateOverlaps = zone.dateOverlapsWith(computationDate, computationDate);
              if (dateOverlaps) {
                return true;
              }
            }
          }
        }
      }
      return false;
    });
  }

  private provideApplicableZonesForPointInTime(
    computationTimestampEpochMillis: number,
    predicates: ZonePreviewPredicates,
    allZones: Zone[],
  ): Zone[] {
    const computationTimestamp = dayjs(computationTimestampEpochMillis);

    return allZones.filter((zone) => {
      const typeCompatible = zone.typeCompatibleWith(predicates.zoneTypes);
      if (typeCompatible) {
        const vehicleTypesOverlaps = zone.applicableVehicleTypesOverlapsWith(predicates.applicableVehicleTypes);
        if (vehicleTypesOverlaps) {
          const dayOfWeekOverlaps = zone.dayOfWeeksOverlapsWith(new Set([computationTimestamp.dayOfWeek()]));
          if (dayOfWeekOverlaps) {
            const timeOverlaps = zone.timeOverlapsWith(
              computationTimestamp.secondsFromBeginningOfDay(),
              computationTimestamp.secondsFromBeginningOfDay(),
            );
            if (timeOverlaps) {
              const dateOverlaps = zone.dateOverlapsWith(computationTimestamp, computationTimestamp);
              if (dateOverlaps) {
                return true;
              }
            }
          }
        }
      }
      return false;
    });
  }

  provideSliderPoints(predicates: ZonePreviewPredicates, allZones: Zone[]): SliderPointsWithGranularity {
    switch (this.modeType) {
      case ModeType.WideInterval:
        return this.provideSliderPointsForWideInterval(allZones);
      case ModeType.DayWise:
        return this.provideSliderPointsForDayWise(predicates);
      case ModeType.PointInTime:
        return this.provideSliderPointsForPointInTime(predicates);
    }
  }

  private provideSliderPointsForWideInterval(allZones: Zone[]): SliderPointsWithGranularity {
    const allStartInclusiveDates: Dayjs[] = allZones.map((zone) => dayjs(zone.activeOn.dateRange.startInclusive));
    const allEndInclusiveDates: Dayjs[] = allZones
      .map((zone) => dayjs(!zone.activeOn.dateRange.openEnded ? zone.activeOn.dateRange.endInclusive : null))
      .filter((date) => date.isValid());

    const earliestZoneTimestamp = dayjs.min(allStartInclusiveDates) as Dayjs;
    const earliestMillisTimestampEpochMillis = earliestZoneTimestamp.toEpochMillis();

    let latestZoneTimestamp = dayjs.max(allEndInclusiveDates);
    if (!latestZoneTimestamp) {
      // if all zones are open ended => no end dates, lets find fix end to be a month after latest start incl zone
      latestZoneTimestamp = dayjs.max(allStartInclusiveDates)!.plusMonths(1);
    }
    const latestMillisTimestampEpochMillis = latestZoneTimestamp!.toEpochMillis();

    return {
      pointsAsEpochMillis: [earliestMillisTimestampEpochMillis, latestMillisTimestampEpochMillis],
      granularity: SliderGranularity.AGGREGATED,
    };
  }

  private provideSliderPointsForDayWise(predicates: ZonePreviewPredicates): SliderPointsWithGranularity {
    const sliderPointsAsEpochMillisWithDayGranularity: number[] = [];
    const sliderPointsDatesWithDayGranularity: Dayjs[] = this.getSliderPointsWithDayGranularity(
      predicates.startDateInclusive,
      predicates.endDateInclusive,
    );

    for (let i = 0; i < sliderPointsDatesWithDayGranularity.length; i++) {
      const potentialSliderPoint = sliderPointsDatesWithDayGranularity[i];
      const potentialSliderPointDayOfWeek = potentialSliderPoint.dayOfWeek();
      if (predicates.dayOfWeekOverlaps(potentialSliderPointDayOfWeek)) {
        sliderPointsAsEpochMillisWithDayGranularity.push(potentialSliderPoint.toEpochMillis());
      }
    }

    return {
      pointsAsEpochMillis: sliderPointsAsEpochMillisWithDayGranularity,
      granularity: SliderGranularity.DAY,
    };
  }

  private provideSliderPointsForPointInTime(predicates: ZonePreviewPredicates): SliderPointsWithGranularity {
    const sliderPointsDatesWithDayGranularity: Dayjs[] = this.getSliderPointsWithDayGranularity(
      predicates.startDateInclusive,
      predicates.endDateInclusive,
    );
    const sliderPointsAsEpochMillisWithHourlyGranularity: number[] = [];

    // parsing ahead for performance reasons to avoid parsing inside loop (computeHourlyGranularitySliderPointsForDate)
    // always keep a slider point at begging of hour, which is why are truncating mins & seconds with toBeginningOfHour
    const startTimeIncl = predicates.startTimeSecondsSinceBeginningOfDay.parseSecondsSinceBeginningOfDay().toBeginningOfHour();
    let endTimeIncl = predicates.endTimeSecondsSinceBeginningOfDay.parseSecondsSinceBeginningOfDay();
    // if endTime is not an exact hour, then add additional point on slider by moving forward to beginning of next hour
    if (!endTimeIncl.representsExactHourWithoutMinutesAndSeconds() && endTimeIncl.hour() !== 23 /*avoiding overflow*/) {
      endTimeIncl = endTimeIncl.plusHours(1).toBeginningOfHour();
    }

    for (let i = 0; i < sliderPointsDatesWithDayGranularity.length; i++) {
      const currentDate = sliderPointsDatesWithDayGranularity[i];
      const currentDayOfWeek = currentDate.dayOfWeek();
      if (predicates.dayOfWeekOverlaps(currentDayOfWeek)) {
        const sliderPointsAsEpochMillisWithHourlyGranularityForDay = this.computeHourlyGranularitySliderPointsForDate(
          currentDate,
          startTimeIncl,
          endTimeIncl,
        );
        sliderPointsAsEpochMillisWithHourlyGranularity.push(...sliderPointsAsEpochMillisWithHourlyGranularityForDay);
      }
    }

    return {
      pointsAsEpochMillis: sliderPointsAsEpochMillisWithHourlyGranularity,
      granularity: SliderGranularity.HOUR,
    };
  }

  private computeHourlyGranularitySliderPointsForDate(currentDate: Dayjs, startTimeIncl: Dayjs, endTimeIncl: Dayjs) {
    const sliderPointsAsEpochMillisWithHourlyGranularityForDay: number[] = [];

    let sliderPoint = currentDate.toBeginningOfDay().plusHours(startTimeIncl.hour());
    let sliderPointTimeEndIncl = currentDate.toBeginningOfDay().plusHours(endTimeIncl.hour());
    while (sliderPoint.isSameOrBefore(sliderPointTimeEndIncl)) {
      sliderPointsAsEpochMillisWithHourlyGranularityForDay.push(sliderPoint.toEpochMillis());
      sliderPoint = sliderPoint.plusHours(1);
    }
    return sliderPointsAsEpochMillisWithHourlyGranularityForDay;
  }

  private getSliderPointsWithDayGranularity(startDate: Dayjs, endDate: Dayjs) {
    let potentialSliderPointDate = startDate;
    const sliderPointsDatesWithDayGranularity: Dayjs[] = [];

    while (potentialSliderPointDate.isSameOrBefore(endDate)) {
      sliderPointsDatesWithDayGranularity.push(potentialSliderPointDate);
      potentialSliderPointDate = potentialSliderPointDate.plusDays(1);
    }

    return sliderPointsDatesWithDayGranularity;
  }
}
