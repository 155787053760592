import dayjs from 'dayjs';
import toNumber from 'lodash/toNumber';
import trim from 'lodash/trim';

import {
  CreateBudgetPlanRequest,
  BudgetSpendConstraintType,
  Currency,
  DepositFrequency,
  DepositUnit,
  ExpirationScheduleType,
  UpdateBudgetPlanRequest,
  VehicleSpendConstraintCategory,
} from '../../../models/budget-plan';
import UserService from '../../../services/UserService';

import { BudgetPlanDetailsFormValues } from './BudgetPlanDetails';

export const formTranslationKey = `operator.budgetPlan.form`;
export const budgetPlanFormInitialValues: BudgetPlanDetailsFormValues = {
  name: '',
  depositFrequency: DepositFrequency.Monthly,
  depositAmount: null,
  amountPerTripSpendConstraintExists: null,
  depositDayOfMonth: 1,
  depositTime: dayjs().toBeginningOfDay(),
  vehicleTypesSpendConstraintExists: null,
  vehicleTypesSpendConstraint: [],
};

export const budgetPlanFormInputKeys = {
  name: 'name',
  depositFrequency: 'depositFrequency',
  depositDayOfMonth: 'depositDayOfMonth',
  depositTime: 'depositTime',
  depositAmount: 'depositAmount',
  amountPerTripSpendConstraintExists: 'amountPerTripSpendConstraintExists',
  amountPerTripSpendConstraint: 'amountPerTripSpendConstraint',
  vehicleTypesSpendConstraintExists: 'vehicleTypesSpendConstraintExists',
  vehicleTypesSpendConstraint: 'vehicleTypesSpendConstraint',
} as const;

interface BudgetPlanInputsWithVersion extends BudgetPlanDetailsFormValues {
  version: number;
}

export const makeCreateBudgetPlanRequestPayload = (values: BudgetPlanDetailsFormValues): CreateBudgetPlanRequest =>
  budgetPlanDetails(values);
export const makeUpdateBudgetPlanRequestPayload = (values: BudgetPlanInputsWithVersion): UpdateBudgetPlanRequest => ({
  version: values.version,
  ...budgetPlanDetails(values),
});

export const budgetPlanDetails = (
  values: BudgetPlanInputsWithVersion | BudgetPlanDetailsFormValues,
): Omit<UpdateBudgetPlanRequest, 'version'> => {
  const {
    name,
    depositAmount,
    depositFrequency,
    vehicleTypesSpendConstraint,
    amountPerTripSpendConstraint,
    depositTime,
    depositDayOfMonth,
  } = values;

  const resp: Omit<UpdateBudgetPlanRequest, 'version'> = {
    name: trim(name),
    currency: Currency.Time,
    depositSchedule: {
      frequency: depositFrequency,
      metadata: {
        dayOfMonth: depositDayOfMonth!,
        timeOfDay: depositTime!.formatAsTimeWithoutSeconds(),
      },
      expirationSchedule: {
        type: ExpirationScheduleType.TillNextDepositCycle,
      },
    },
    fragments: [
      {
        amount: toNumber(depositAmount)!, // This is coming as string, toNumber conversion will be removed once we start using NumberInput component
        amountMultiplier: 1,
        unit: DepositUnit.Minutes,
      },
    ],
  };

  if (vehicleTypesSpendConstraint.length || amountPerTripSpendConstraint) {
    resp.fragments[0].spendConstraints = [];

    if (vehicleTypesSpendConstraint.length) {
      resp.fragments[0].spendConstraints.push({
        vehicle: [
          {
            type: VehicleSpendConstraintCategory.Msp,
            ids: [UserService.subjectClaim()!],
          },
          {
            type: VehicleSpendConstraintCategory.VehicleType,
            values: vehicleTypesSpendConstraint,
          },
        ],
      });
    }

    if (amountPerTripSpendConstraint) {
      resp.fragments[0].spendConstraints[0] = {
        ...resp.fragments[0].spendConstraints[0],
        budget: [
          {
            type: BudgetSpendConstraintType.LimitPerTrip,
            value: toNumber(amountPerTripSpendConstraint)!,
          },
        ],
      };
    }
  }

  return resp;
};
