import { useState } from 'react';

import { Star, StarBorderOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Button, IconButton, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { FilterProfileType } from '../../models';
import { FilterView } from '../../pages/Analytics/TableFilters';
import { applyFilterProfileAction, deleteFilterProfileAction, favoriteFilterProfileAction } from '../../state/analytics/actions';
import { filterProfilesSelector, selectedFilterProfileSelector } from '../../state/analytics/selectors';
import CustomPopover from '../CustomPopover';

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
}));

const FilterContainer = styled('div')(({ theme }) => ({
  display: 'block',
  margin: `${theme.spacing(3)} 0px`,
}));

const FilterProfile = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `0px 0px 0px ${theme.spacing(1)}`,
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: `${theme.spacing(3)}`,
  borderTop: '1px solid #2F2E4114',
}));

interface FilterProfilesProps {
  handleClose: () => void;
  changeFilterView: (newView: FilterView, filterSetName: string | null) => void;
}

const FilterProfiles = ({ handleClose, changeFilterView }: FilterProfilesProps) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const filters: FilterProfileType[] = useSelector(filterProfilesSelector);
  const selectedFilter: string = useSelector(selectedFilterProfileSelector);
  const dispatch = useDispatch();

  const sortedFilters = [...filters].sort((a, b) => {
    return (
      (b.filterName === 'default' ? 1 : 0) - (a.filterName === 'default' ? 1 : 0) ||
      Number(b.favorite) - Number(a.favorite) ||
      a.filterName.localeCompare(b.filterName)
    );
  });

  const isElementDisabled = (filterName: string) => {
    return filterName === 'default';
  };

  const handleToggleFavoriteFilter = (filterName: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    dispatch(favoriteFilterProfileAction(filterName));
  };

  const deleteFilter = (filterName: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setSelected('default');
    dispatch(applyFilterProfileAction('default'));
    dispatch(deleteFilterProfileAction(filterName));
  };

  const [isSelected, setSelected] = useState(selectedFilter);
  const selectFilterProfile = (filterName: string) => {
    setSelected(filterName);
  };

  const applyFilterSet = () => {
    dispatch(applyFilterProfileAction(isSelected));
  };

  const [openDialog, setOpenDialog] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenDialog = (filterName: string, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setOpenDialog(filterName);
    event.stopPropagation();
  };

  const handleCloseDialog = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
    setOpenDialog(null);
  };

  const changeFilterProfileSettings = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, filterName: string) => {
    event.stopPropagation();
    //TODO: send properties to edit Page
    changeFilterView(FilterView.CREATE_NEW_FILTER, filterName);
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Header>
        <IconButton sx={{ marginRight: '24px' }} onClick={handleClose} disableRipple>
          <CloseIcon />
        </IconButton>
        <Typography variant="subtitle1">{formatMessage({ id: 'filter.filterSets' })}</Typography>
      </Header>
      <div style={{ padding: '0 24px', overflow: 'auto', flex: 2 }}>
        <Typography variant="subtitle2" style={{ color: theme.palette.primary.light }}>
          {formatMessage({ id: 'analytics.filter.savedFilters.title' })}
        </Typography>
        <FilterContainer>
          {sortedFilters.map(({ filterName, columnFilters, favorite, visibleColumns }) => (
            <div className="backgroundHover" key={filterName}>
              <FilterProfile
                key={filterName}
                className="filter-profile"
                onClick={() => selectFilterProfile(filterName)}
                style={{
                  color: selectedFilter === filterName ? theme.palette.secondary.main : theme.palette.dark.main,
                  background: isSelected === filterName ? theme.palette.dark.shade24 : theme.palette.dark.shade4,
                }}
              >
                <Typography variant="caption">{filterName}</Typography>
                <div style={{ marginRight: 0 }}>
                  <IconButton key="favorite" onClick={(event) => handleToggleFavoriteFilter(filterName, event)} disableRipple>
                    {favorite ? <Star /> : <StarBorderOutlined />}
                  </IconButton>
                  <IconButton
                    disabled={isElementDisabled(filterName)}
                    key="settings"
                    onClick={(event) => {
                      console.log(event);
                      changeFilterProfileSettings(event, filterName);
                    }}
                    disableRipple
                  >
                    <SettingsOutlinedIcon />
                  </IconButton>
                  <IconButton
                    disabled={isElementDisabled(filterName)}
                    key="delete"
                    onClick={(event) => deleteFilter(filterName, event)}
                    disableRipple
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                  <IconButton key="info" onClick={(event) => handleOpenDialog(filterName, event)} disableRipple>
                    <InfoOutlinedIcon />
                  </IconButton>
                </div>
                <CustomPopover
                  open={openDialog === filterName}
                  anchorEl={anchorEl}
                  handleClose={handleCloseDialog}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Header style={{ padding: '0' }}>
                    <Typography variant="bodyMediumBold" sx={{ padding: '0px' }}>
                      {formatMessage({ id: 'filter.filterSets.properties' }, { filterSetName: filterName })}
                    </Typography>
                    <IconButton onClick={handleCloseDialog} disableRipple>
                      <CloseIcon />
                    </IconButton>
                  </Header>
                  <div style={{ margin: '10px 0px' }}>
                    <Typography variant="bodySmallBold" sx={{ padding: '0px' }}>
                      {formatMessage({ id: 'analytics.filter.savedFilters.columns' })}
                    </Typography>
                    <ul>
                      {visibleColumns.map((column, index) => {
                        return (
                          <li style={{ margin: '10px 0px' }} key={index}>
                            {' '}
                            <Typography variant="bodySmall" sx={{ padding: '0px' }}>
                              {column.Header}
                            </Typography>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  {columnFilters.length > 0 && columnFilters.filter((cf) => cf.value !== '').length > 0 && (
                    <div style={{ margin: '10px 0px' }}>
                      <Typography variant="bodySmallBold" sx={{ padding: '0px' }}>
                        {formatMessage({ id: 'analytics.filter.savedFilters.filters' })}
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '10px 0px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="caption" sx={{ padding: '0px' }}>
                            {formatMessage({ id: 'analytics.filter.savedFilters.columnName' })}
                          </Typography>
                          <Typography variant="caption" sx={{ padding: '0px' }}>
                            {formatMessage({ id: 'analytics.filter.savedFilters.filterValue' })}
                          </Typography>
                        </div>
                        {columnFilters
                          .filter((cf) => cf.value !== '')
                          .map((cf, index) => (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: '10px 0px',
                              }}
                            >
                              <Typography variant="bodySmall" sx={{ padding: '0px' }}>
                                {cf.column.Header}
                              </Typography>
                              <Typography variant="bodySmall" sx={{ padding: '0px' }}>
                                {cf.value}
                              </Typography>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </CustomPopover>
              </FilterProfile>
            </div>
          ))}
        </FilterContainer>
      </div>
      <ButtonContainer>
        <Button onClick={() => applyFilterSet()} sx={{ marginTop: '10px', width: '100%' }} type="submit" variant="contained">
          <Typography variant="subtitle2">{formatMessage({ id: 'analytics.filter.savedFilters.applyFilterSet' })}</Typography>
        </Button>
        <Button
          onClick={(e) => changeFilterView(FilterView.CREATE_NEW_FILTER, null)}
          sx={{ marginTop: '10px', width: '100%' }}
          variant="outlined"
        >
          <Typography variant="subtitle2">{formatMessage({ id: 'analytics.filter.savedFilters.startNewFilter' })}</Typography>
        </Button>
      </ButtonContainer>
    </div>
  );
};

export default FilterProfiles;
