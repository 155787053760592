import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import MapInfoBoxMobilityStation from '../../../../assets/illustrations/MapInfoBox/mobilityStation.svg';
import { ZoneTypeHelper } from '../../../../models/ZoneType';

import ZoneIcon from './ZoneIcon';

function InfoZoneLayer() {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const layers = ZoneTypeHelper.allZoneTypes().map((zoneType) => ({
    value: zoneType,
    image: ZoneTypeHelper.metadata(zoneType).helpArtifacts.mapInfoImageSrc,
  }));

  const allLayers = [
    { value: 'MOBILITY_STATION', label: 'MOBILITY_STATION', description: 'MOBILITY_STATION', image: MapInfoBoxMobilityStation },
    ...layers,
  ];

  return (
    <Box sx={{ display: 'flex', gap: theme.spacing(3), flexDirection: 'column' }}>
      {allLayers.map((l) => (
        <Box
          key={l.value}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2),
          }}
        >
          <ZoneIcon image={l.image} zoneType={l.value} />
          <Box
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: theme.spacing(0.5) }}
          >
            <Typography variant="bodyMediumBold">{formatMessage({ id: `zoneType.${l.value}` })}</Typography>
            <Typography variant="bodyMedium" color={theme.palette.dark.shade76}>
              {formatMessage({ id: `zoneType.${l.value}.description` })}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default InfoZoneLayer;
