import { Box, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { TableColumnType } from '../../models';
import { LiveVehicle } from '../../state/common/vehicle.model';
import CustomIcon from '../../utils/CustomIcon';
import { ReactTable } from '../../wmv-components';

interface ZonesPopupDataTableProps {
  loading: boolean;
  data: LiveVehicle[];
  columns: TableColumnType[];
  onClose: () => void;
  onRowClickHandler: React.Dispatch<React.SetStateAction<string | null | undefined>>;
}

const generateUniqueID = (vehicle: LiveVehicle) => {
  return [vehicle.vehicleTypeId, vehicle.vehicleId, vehicle.qrCodeId].map((v) => String(v)).join('#');
};

const ZonesPopupDataTable = ({ loading, data, columns, onClose, onRowClickHandler }: ZonesPopupDataTableProps) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const count = (data || []).length;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '10px',
          borderBottom: '1px solid #cecece',
        }}
      >
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ background: '#f0f0f1', borderRadius: 1, padding: '2px 10px' }}>
            <Typography variant="bodySmallBold">{formatMessage({ id: 'zoneViewMode.table.title' })}</Typography>
          </Box>
          <Box>
            <Typography variant="bodySmall">
              {formatMessage(
                { id: 'zoneViewMode.table.vehicles' },
                {
                  count,
                },
              )}
            </Typography>
          </Box>
        </Box>

        <IconButton
          size="small"
          disableRipple
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
        >
          {CustomIcon('CloseIcon', theme.palette.primary.main)}
        </IconButton>
      </Box>
      <ReactTable
        loading={loading}
        data={data}
        columns={columns}
        renderDetailsComponent={false}
        tableContainerStyle={{
          height: 200,
          border: '1px solid #ccc',
        }}
        onRowClickHandler={(row: LiveVehicle) => {
          onRowClickHandler(generateUniqueID(row));
        }}
      />
    </>
  );
};

export default ZonesPopupDataTable;
