import { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ZoneType, ZoneTypeHelper, GraphqlVehicleType } from '../../../../models';
import { GraphqlVehicleUsageStatus, GraphqlVehicleUsageStatusHelper } from '../../../../models';
import {
  MapFilterOption,
  mapFiltersSelector,
  updateInfrastructureTypeFilterOptionsAction,
  updateMspTypeFilterOptionsAction,
  updateVehicleStatusFilterOptionsAction,
  updateVehicleTypeFilterOptionsAction,
  updateZoneTypeFilterOptionsAction,
} from '../../../../state';
import {
  graphqlVehicleTypeMenuConfig,
  InfrastructureType,
  infrastructureTypesMenuConfig,
  MspType,
  mspTypesMenuConfig,
} from '../../../../utils/constants';
import { SaveButton } from '../../../../wmv-components';
import CustomSelectField from '../../../FormFields/CustomSelectField';

interface MapFilterOptions {
  vehicleTypeOptions: MapFilterOption<GraphqlVehicleType>[];
  vehicleStatusOptions: MapFilterOption<GraphqlVehicleUsageStatus>[];
  mspTypeOptions: MapFilterOption<MspType>[];
  zoneTypeOptions: MapFilterOption<ZoneType>[];
  infrastructureTypeOptions: MapFilterOption<InfrastructureType>[];
}

const getFilters = (intl: IntlShape) => [
  {
    label: intl.formatMessage({ id: 'zoneControls.filters.vehicleType' }),
    options: graphqlVehicleTypeMenuConfig,
    name: 'vehicleTypeOptions',
  },
  {
    label: intl.formatMessage({ id: 'zoneControls.filters.vehicleStatus' }),
    options: GraphqlVehicleUsageStatusHelper.menuConfig,
    name: 'vehicleStatusOptions',
  },
  {
    label: intl.formatMessage({ id: 'zoneControls.filters.provider' }),
    options: mspTypesMenuConfig,
    name: 'mspTypeOptions',
  },
  {
    label: ZoneTypeHelper.menuConfig.label,
    options: ZoneTypeHelper.menuConfig.options,
    name: 'zoneTypeOptions',
  },
  {
    label: intl.formatMessage({ id: 'zoneControls.filters.infrastructure' }),
    options: infrastructureTypesMenuConfig,
    name: 'infrastructureTypeOptions',
  },
];

const FilterZoneLayer = ({ handleClose }: { handleClose: (event?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void }) => {
  const mapFilters = useSelector(mapFiltersSelector);
  const dispatch = useDispatch();
  const intl = useIntl();
  const { formatMessage } = intl;

  const [initialValues, setInitialValues] = useState<MapFilterOptions>({
    vehicleTypeOptions: [],
    vehicleStatusOptions: [],
    mspTypeOptions: [],
    zoneTypeOptions: [],
    infrastructureTypeOptions: [],
  });

  useEffect(() => {
    setInitialValues((prevState: MapFilterOptions) => ({
      ...prevState,
      vehicleTypeOptions: [...mapFilters.vehicleTypeOptions],
      vehicleStatusOptions: [...mapFilters.vehicleStatusOptions],
      mspTypeOptions: [...mapFilters.mspTypeOptions],
      zoneTypeOptions: [...mapFilters.zoneTypeOptions],
      infrastructureTypeOptions: [...mapFilters.infrastructureTypeOptions],
    }));
    // eslint-disable-next-line
  }, []);

  const validationFn = () => {
    return {};
  };

  const applyVehicleFilter = (values: MapFilterOptions) => {
    dispatch(updateVehicleTypeFilterOptionsAction(values.vehicleTypeOptions));
    dispatch(updateVehicleStatusFilterOptionsAction(values.vehicleStatusOptions));
    dispatch(updateMspTypeFilterOptionsAction(values.mspTypeOptions));
    dispatch(updateZoneTypeFilterOptionsAction(values.zoneTypeOptions));
    dispatch(updateInfrastructureTypeFilterOptionsAction(values.infrastructureTypeOptions));
    handleClose();
  };

  return (
    <Formik initialValues={initialValues} validate={validationFn} onSubmit={applyVehicleFilter} enableReinitialize>
      {({ isValid, isValidating }) => (
        <Form>
          <Grid container style={{ width: 275 }} rowSpacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="subtitle2">{formatMessage({ id: 'zoneControls.filters.title' })}</Typography>
            </Grid>
            {getFilters(intl).map((filter) => (
              <Grid key={filter.name} item xs={12}>
                <Field name={filter.name} as={CustomSelectField} options={filter.options} outlineLabel={filter.label} multiple />
                <div
                  style={{
                    marginTop: 5,
                    color: 'red',
                    fontSize: 12,
                    height: 18,
                  }}
                >
                  <ErrorMessage name={filter.name} />
                </div>
              </Grid>
            ))}
            <Grid item xs={12} style={{ marginTop: 5 }}>
              <SaveButton disabled={isValidating || !isValid} type="submit" variant="contained" disabledElevation>
                <Typography variant="subtitle2">{formatMessage({ id: 'zoneControls.filters.apply' })}</Typography>
              </SaveButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default FilterZoneLayer;
