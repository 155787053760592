import {
  BudgetPlanMembershipStatus,
  BudgetPlanMembershipStatusHelper,
  LastChangeAttemptStatus,
  LastChangeAttemptStatusHelper,
  BudgetPlanMembershipDetailsResponse,
} from '../../models/budget-plan';

import { BudgetPlanMembershipActiveStateDto } from './BudgetPlanMembershipActiveStateDto';
import { BudgetPlanMembershipLastAttemptedStateTransitionDto } from './BudgetPlanMembershipLastAttemptedStateTransitionDto';
import { BudgetPlanMembershipStatusDisplayMetadata } from './BudgetPlanMembershipStatusDisplayMetadata';

export class BudgetPlanMembershipDto {
  id: number;
  version: number;
  firstName: string;
  lastName: string;
  email: string;
  activeState: BudgetPlanMembershipActiveStateDto;
  lastAttemptedStateTransition: BudgetPlanMembershipLastAttemptedStateTransitionDto;

  constructor(budgetPlanMembership: BudgetPlanMembershipDetailsResponse) {
    this.version = budgetPlanMembership.version;
    this.id = budgetPlanMembership.id;
    this.firstName = budgetPlanMembership.firstName;
    this.lastName = budgetPlanMembership.lastName;
    this.email = budgetPlanMembership.email;
    this.activeState = new BudgetPlanMembershipActiveStateDto(budgetPlanMembership.activeState);
    /* prettier-ignore */
    this.lastAttemptedStateTransition = new BudgetPlanMembershipLastAttemptedStateTransitionDto(budgetPlanMembership.lastAttemptedStateTransition);
  }

  statusDisplayMetaData(): BudgetPlanMembershipStatusDisplayMetadata {
    const { status: activeStateStatus } = this.activeState;
    const { status: lastAttemptedStateTransitionStatus } = this.lastAttemptedStateTransition;

    return [LastChangeAttemptStatus.InProgress, LastChangeAttemptStatus.Failed].includes(lastAttemptedStateTransitionStatus)
      ? LastChangeAttemptStatusHelper.metadata(lastAttemptedStateTransitionStatus)
      : BudgetPlanMembershipStatusHelper.metadata(activeStateStatus);
  }

  inActive(): boolean {
    return (
      this.activeState.status === BudgetPlanMembershipStatus.Unsubscribed &&
      this.lastAttemptedStateTransition.status === LastChangeAttemptStatus.Success
    );
  }

  name() {
    return `${this.firstName} ${this.lastName}`;
  }
}
