import { useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Dictionary } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../components/LoadingAndErrorWithRetryAndNoResults';
import { LoadingStatus } from '../../../models';
import { BudgetPlanSummaryCumDetailsResponse } from '../../../models/budget-plan';
import { BudgetPlanDto } from '../../../services/budget-plan';
import {
  AppDispatch,
  BudgetPlanApiErrorState,
  BudgetPlanApiLoadingStatus,
  budgetPlanListLoadingOrErrorOrNoResultsSelector,
} from '../../../state';
import {
  BudgetPlanActionMode,
  budgetPlanApiStatusSelector,
  budgetPlanApiErrorSelector,
  planIdToBudgetPlanSelector,
  budgetPlansSelector,
  getBudgetPlanAction,
  listBudgetPlansAsyncThunkAction,
  setBudgetPlanActionModeAction,
  updateBudgetPlanStatusAsyncThunkAction,
} from '../../../state';
import { ReactTable } from '../../../wmv-components';

import { RetryBudgetStatusModal } from './BudgetPlanDetails/RetryBudgetStatusModal';
import { BudgetPlanModal } from './BudgetPlanModal';
import { HeadingSection } from './HeadingSection';
import { useMakeBudgetPlanDetailsTableColumns } from './hooks/useMakeBudgetPlanDetailsTableColumns';
import { pollActionCreator } from './hooks/usePolling';

const BudgetPlan = () => {
  const { palette } = useTheme();
  const dispatch = useDispatch<AppDispatch>();

  const [budgetFormModalVisible, setBudgetFormModalVisible] = useState(false);
  const [selectedBudgetPlanId, setSelectedBudgetPlanId] = useState<number | null>(null);
  const [selectedFailedBudgetPlanId, setSelectedFailedBudgetPlanId] = useState<number | null>(null);

  const budgetPlans: BudgetPlanDto[] = useSelector(budgetPlansSelector);
  const budgetPlanApiStatus: BudgetPlanApiLoadingStatus = useSelector(budgetPlanApiStatusSelector);
  const budgetPlanApiError: BudgetPlanApiErrorState = useSelector(budgetPlanApiErrorSelector);
  const planIdToBudgetPlan: Dictionary<BudgetPlanDto> = useSelector(planIdToBudgetPlanSelector);
  const budgetPlanListLoadingOrErrorOrNoResults: boolean = useSelector(budgetPlanListLoadingOrErrorOrNoResultsSelector);

  useEffect(() => {
    // TODO: All these should not be here
    let polledActions: { start: any; stop: any }[] = [];
    if (budgetPlans.length && !budgetFormModalVisible) {
      for (let i = 0; i < budgetPlans.length; i++) {
        if (budgetPlans[i].lastChangeAttempt.isTransitionInProgress) {
          const pollingAction = pollActionCreator(getBudgetPlanAction, budgetPlans[i].id, 5000);
          pollingAction.start(dispatch);
          polledActions.push(pollingAction);
        }
      }
    }

    return () => {
      polledActions.forEach((polledAction) => polledAction.stop());
    };
  }, [budgetPlans, dispatch, budgetFormModalVisible]);

  const fetchBudgetPlans = useCallback(async () => {
    // TODO: See if we can remove empty object `{}`
    await dispatch(listBudgetPlansAsyncThunkAction({}));
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      await fetchBudgetPlans();
    })();
  }, [fetchBudgetPlans]);

  const handleAddBudgetPlanClick = () => {
    setBudgetFormModalVisible(true);
    dispatch(setBudgetPlanActionModeAction(BudgetPlanActionMode.Add));
  };

  const handleBudgetPlanRowClick = async (budgetPlan: BudgetPlanDto) => {
    setBudgetFormModalVisible(true);
    setSelectedBudgetPlanId(budgetPlan.id);
    dispatch(setBudgetPlanActionModeAction(BudgetPlanActionMode.View));
  };

  const closeModal = () => {
    setBudgetFormModalVisible(false);
    setSelectedBudgetPlanId(null);
  };

  const handleBudgetPlanModalClose = () => {
    dispatch(setBudgetPlanActionModeAction(BudgetPlanActionMode.Idle));
    // TODO: Refactor it using https://react.dev/learn/scaling-up-with-reducer-and-context
    // https://gitlab.com/wikimove/web-app/mobility-manager/-/merge_requests/300#note_1839347952
    closeModal();
  };

  const handleStatusChangeCellClick = (budgetPlan: BudgetPlanDto) => {
    setSelectedFailedBudgetPlanId(budgetPlan.id);
  };

  const handleTransitionFailedPlanStatusUpdateRetry = async () => {
    const budgetPlan = planIdToBudgetPlan[selectedFailedBudgetPlanId!];
    await dispatch(
      updateBudgetPlanStatusAsyncThunkAction({
        id: selectedFailedBudgetPlanId!,
        payload: {
          version: budgetPlan.version,
          status: budgetPlan.lastChangeAttempt.transitionTo!,
        },
      }),
    ).unwrap();
    setSelectedFailedBudgetPlanId(null);
  };

  const budgetPlanDetailsTableColumns = useMakeBudgetPlanDetailsTableColumns(handleStatusChangeCellClick);
  return (
    <Box component="main">
      <HeadingSection onAddBudgetPlanClick={handleAddBudgetPlanClick} />
      {budgetPlanListLoadingOrErrorOrNoResults ? (
        <LoadingAndErrorWithRetryAndNoResults
          error={!!budgetPlanApiError['listPlans']}
          loading={budgetPlanApiStatus['listPlans'] === LoadingStatus.Pending}
          onRetry={fetchBudgetPlans}
          baseTranslationKey="budgetPlan"
          noResults={budgetPlans.length === 0}
          style={{ height: 'calc(100vh - 172px)' }}
        />
      ) : (
        <ReactTable
          columns={budgetPlanDetailsTableColumns}
          data={budgetPlans}
          onRowClickHandler={handleBudgetPlanRowClick}
          tableContainerStyle={{ height: `100%` }}
          renderDetailsComponent={false}
          customRowHoverStyle={{ backgroundColor: palette.secondary.tint, cursor: 'pointer' }}
          loading={false}
          sorted={false}
        />
      )}

      {budgetFormModalVisible && (
        <BudgetPlanModal
          open={budgetFormModalVisible}
          onBudgetPlanModalClose={handleBudgetPlanModalClose}
          selectedBudgetPlanId={selectedBudgetPlanId!}
          onSelectedBudgetPlanIdChanged={setSelectedBudgetPlanId}
        />
      )}
      {selectedFailedBudgetPlanId && (
        <RetryBudgetStatusModal
          open={!!selectedFailedBudgetPlanId}
          onClose={() => setSelectedFailedBudgetPlanId(null)}
          planName={planIdToBudgetPlan[selectedFailedBudgetPlanId].activeState.name}
          onRetry={handleTransitionFailedPlanStatusUpdateRetry}
        />
      )}
    </Box>
  );
};

export interface BudgetPlanDetailsFetchState {
  data: BudgetPlanSummaryCumDetailsResponse | null;
  hasError: boolean;
  loading: boolean;
}

export type PlanStatusTransitionFailureRetryClickHandler = (budgetPlanId: number) => Promise<void>;

export default BudgetPlan;
