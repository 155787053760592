import { Button, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { LookupDurationWindow } from '../../../models';

interface FilterButtonProps {
  filter: LookupDurationWindow;
  activeFilter: LookupDurationWindow;
  onClick: () => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({ filter, activeFilter, onClick }) => {
  const { formatMessage } = useIntl();
  return (
    <Button
      variant="contained"
      color={activeFilter === filter ? 'secondary' : 'tertiary'}
      onClick={onClick}
      sx={{ whiteSpace: 'nowrap', textTransform: 'none' }}
    >
      <Typography variant="bodyMediumBold">{formatMessage({ id: filter })}</Typography>
    </Button>
  );
};

export default FilterButton;
