import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { ReactComponent as PlusIcon } from '../../../assets/icons/fill/plus.svg';
import { TranslatedText, InfoIconWithTooltip } from '../../../wmv-components';

import { headerSectionContainerStyles } from './styles';

export const HeadingSection = ({ onAddBudgetPlanClick }: HeadingSectionProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { palette } = theme;

  return (
    <Box component="section" sx={headerSectionContainerStyles}>
      <Box display="flex" alignItems="center">
        <TranslatedText variant="bodyMediumBold" translationKey="operator.budgetPlan.heading" sx={{ mr: 0.5 }} />
        <InfoIconWithTooltip title={formatMessage({ id: 'operator.budgetPlan.heading.tooltipDescription' })} placement="top-end" />
      </Box>
      <Button variant="contained" onClick={onAddBudgetPlanClick}>
        <PlusIcon fill={palette.white.main} />
        <TranslatedText variant="bodyMediumBold" translationKey="operator.budgetPlan.addBudgetPlan" sx={{ pl: 0.5 }} />
      </Button>
    </Box>
  );
};

interface HeadingSectionProps {
  onAddBudgetPlanClick: () => void;
}
