import { BudgetPlanStatus, LastChangeAttempt, BudgetPlanLastChangeAttemptStatus } from '../../models/budget-plan';

export class BudgetPlanLastChangeAttemptDto {
  status: BudgetPlanLastChangeAttemptStatus;
  transitionTo?: BudgetPlanStatus;
  transitionFrom?: BudgetPlanStatus;
  isTransitionInProgress: boolean;

  constructor(lastChangAttempt: LastChangeAttempt) {
    this.status = lastChangAttempt.status;
    this.transitionFrom = lastChangAttempt.transition?.from;
    this.transitionTo = lastChangAttempt.transition?.to;
    this.isTransitionInProgress = BudgetPlanLastChangeAttemptStatus.InProgress === lastChangAttempt.status;
  }
}
