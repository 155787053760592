import type { Theme } from '@mui/material/styles';

export const containerStyles = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px',
  backgroundColor: theme.palette.dark.shade3,
});

export const contentWrapperStyles = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
});

export const retryButtonStyles = (theme: Theme) => ({
  textTransform: 'unset',
  display: 'flex',
  flexDirection: 'column',
});

export const errorSubTextOnHoverStyles = (theme: Theme) => ({
  ':&hover:': { textDecoration: 'underline' },
});
