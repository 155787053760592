import { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import GeoJsonZones from '../../components/Map/GeoJsonZones';
import VehicleStateLegendAndResetFilterBottomBar from '../../components/Map/VehicleStateLegendAndResetFilterBottomBar';
import WikimoveMap from '../../components/Map/WikimoveMap';
import ZoneControls from '../../components/Map/ZoneControls';
import VehiclesPopupDataTable from '../../components/MapTableView/ZonesPopupDataTable';
import { DayOfWeekHelper, ModeType, VehicleType, ZoneType } from '../../models';
import { Mode, ZonePreviewPredicates } from '../../services/zone';
import { AppDispatch, vehicleTypesSelector, zoneTypesSelector } from '../../state';
import { loadingVehiclesSelector } from '../../state';
import { LiveVehicle } from '../../state/common/vehicle.model';
import { publishedZonesSelector, getPublishedZonesAsyncThunkAction } from '../../state/zones-management';
import {
  analyticsTableAllColumns,
  END_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY,
  MAX_DEFAULT_DATE,
  MIN_DEFAULT_DATE,
  START_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY,
  TABLE_FOOTER_HEIGHT,
  VIEW_PANNING_MODE_VIEW_HEIGHT,
  VIEW_PLANNING_MODE_TABLE_HEIGHT,
} from '../../utils/constants';
import { FloatingBox } from '../../wmv-components';

const ViewModeLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();

  const [selectedVehicleId, setSelectedVehicleId] = useState<string | null | undefined>();
  const [showVehicleTable, setShowVehicleTable] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState<LiveVehicle[]>([]);

  const loadingVehicles: boolean = useSelector(loadingVehiclesSelector);
  const publishedZones = useSelector(publishedZonesSelector);
  const vehicleTypes: VehicleType[] = useSelector(vehicleTypesSelector);
  const zoneTypes: ZoneType[] = useSelector(zoneTypesSelector);

  useEffect(() => {
    dispatch(getPublishedZonesAsyncThunkAction());
  }, [dispatch]);

  const applicablePublishedZones = useMemo(() => {
    const zonePreviewPredicates = new ZonePreviewPredicates(
      new Mode(ModeType.WideInterval),
      new Set(zoneTypes),
      MIN_DEFAULT_DATE.asDayjsDate(),
      MAX_DEFAULT_DATE.asDayjsDate(),
      START_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY,
      END_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY,
      DayOfWeekHelper.allDaysOfWeek(),
      new Set(vehicleTypes),
    );
    const publishedZonesFromNowOnwards = zonePreviewPredicates.provideApplicableZones(dayjs.now().toEpochMillis(), publishedZones);
    return publishedZonesFromNowOnwards;
  }, [vehicleTypes, zoneTypes, publishedZones]);

  const vehicleTableHeight = useMemo(() => {
    let availableHeight = showVehicleTable
      ? VIEW_PANNING_MODE_VIEW_HEIGHT
      : `calc(${VIEW_PLANNING_MODE_TABLE_HEIGHT} - ${TABLE_FOOTER_HEIGHT})`;

    return `calc(100vh - ${availableHeight})`;
  }, [showVehicleTable]);

  useEffect(() => {
    if (!showVehicleTable && selectedVehicles.length > 0) {
      setShowVehicleTable(true);
    }
    if (showVehicleTable && selectedVehicles.length < 1) {
      setShowVehicleTable(false);
    }
  }, [selectedVehicles, showVehicleTable]);

  return (
    <Box height={vehicleTableHeight}>
      <WikimoveMap openMarkerId={selectedVehicleId} setPopupTableData={setSelectedVehicles}>
        <FloatingBox boxStyling={{ top: theme.spacing(9) }}>
          <ZoneControls />
        </FloatingBox>
        <GeoJsonZones zones={applicablePublishedZones} />
      </WikimoveMap>
      <Box>
        {showVehicleTable && (
          <VehiclesPopupDataTable
            loading={loadingVehicles}
            data={selectedVehicles}
            columns={analyticsTableAllColumns}
            onClose={() => {
              setShowVehicleTable(false);
              setSelectedVehicleId(null);
              setSelectedVehicles([]);
            }}
            onRowClickHandler={setSelectedVehicleId}
          />
        )}
        <VehicleStateLegendAndResetFilterBottomBar />
      </Box>
    </Box>
  );
};

export default ViewModeLayout;
