import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { camelCase } from 'lodash';
import { useIntl } from 'react-intl';

import { AggregatedVehicleUsageStatus } from '../../../../models/fleet-management';

const ModalChangeStatement = ({ usageStatement, status }: { usageStatement: string; status: AggregatedVehicleUsageStatus }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const statusColorMap = {
    [AggregatedVehicleUsageStatus.Available]: theme.palette.success.main,
    [AggregatedVehicleUsageStatus.NonOperational]: theme.palette.warning.main,
    [AggregatedVehicleUsageStatus.OnTrip]: theme.palette.blue.tint,
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(1),
      }}
    >
      <Typography variant="bodySmall" color={theme.palette.dark.shade60} sx={{ textAlign: 'end', width: '33%' }}>
        {formatMessage({ id: usageStatement })}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: theme.spacing(0.5),
          width: '60%',
        }}
      >
        <Box
          sx={{
            width: theme.spacing(1.5),
            height: theme.spacing(1.5),
            borderRadius: theme.spacing(1.5),
            backgroundColor: status ? statusColorMap[status] : 'unset',
          }}
        />
        <Typography color={theme.palette.dark.main} variant="bodySmall">
          {formatMessage({ id: `map.vehicleInfo.status.${camelCase(status)}` })}
        </Typography>
      </Box>
    </Box>
  );
};

export default ModalChangeStatement;
