import {
  BudgetPlanMembershipLastAttemptedStateTransition,
  BudgetPlanMembershipStatus,
  LastChangeAttemptStatus,
} from '../../models/budget-plan';

export class BudgetPlanMembershipLastAttemptedStateTransitionDto {
  status: LastChangeAttemptStatus;
  transitionFrom: BudgetPlanMembershipStatus;
  transitionTo: BudgetPlanMembershipStatus;

  constructor(lastAttemptedStateTransition: BudgetPlanMembershipLastAttemptedStateTransition) {
    this.status = lastAttemptedStateTransition.status;
    this.transitionFrom = lastAttemptedStateTransition.transition.from;
    this.transitionTo = lastAttemptedStateTransition.transition.to;
  }

  isLastAttemptedStateTransitionInProgress(): boolean {
    return this.status === LastChangeAttemptStatus.InProgress;
  }

  isLastAttemptedStateTransitionFailed(): boolean {
    return this.status === LastChangeAttemptStatus.Failed;
  }

  isLastAttemptedStateTransitionSuccessful(): boolean {
    return this.status === LastChangeAttemptStatus.Success;
  }
}
