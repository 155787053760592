import { forwardRef } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import type { AlertProps } from '@mui/material/Alert';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../state';
import { accessForbiddenAlertVisibleSelector, toggleAccessForbiddenAlertAction } from '../../state/common';
import { ALERT_HIDE_DURATION } from '../../utils/constants';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AccessForbiddenAlert = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  const accessForbiddenAlertVisible = useSelector(accessForbiddenAlertVisibleSelector);

  const handleSnackBarClose = () => {
    dispatch(toggleAccessForbiddenAlertAction(false));
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={accessForbiddenAlertVisible}
      onClose={handleSnackBarClose}
      autoHideDuration={ALERT_HIDE_DURATION}
    >
      <Alert onClose={handleSnackBarClose} severity="error" sx={{ width: '400px' }} icon={<InfoOutlinedIcon />}>
        {formatMessage({ id: 'common.accessForbiddenMessage' })}
      </Alert>
    </Snackbar>
  );
};
