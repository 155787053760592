import { ReactNode } from 'react';
import type { FC } from 'react';

import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';

import ConnectedIntlProvider from '../configs/ConnectedIntlProvider';
import { client } from '../services/ApolloClient';
import { store } from '../state';
import { MAX_ALLOWED_ALERTS } from '../utils/constants';

import DataSubscription from './DataSubscription';
import { themeOptions } from './ThemeOptions';

const InitialSetup: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        {/*<IntlProvider locale={language} messages={messages[language]}>*/}
        <ConnectedIntlProvider>
          <ThemeProvider theme={createTheme(themeOptions)}>
            <SnackbarProvider maxSnack={MAX_ALLOWED_ALERTS}>
              <DataSubscription>
                <CssBaseline />
                {children}
              </DataSubscription>
            </SnackbarProvider>
          </ThemeProvider>
        </ConnectedIntlProvider>
        {/*</IntlProvider>*/}
      </ApolloProvider>
    </Provider>
  );
};

// TODO: Rename this appropriately after seeing how others named this
export default InitialSetup;
