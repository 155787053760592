import { useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

import { LookupDurationWindow, VehicleTripType } from '../../../../models';
import { DEFAULT_DATETIME_FORMAT_DAYJS } from '../../../../utils/constants';
import { getTripsByVehicleId } from '../../../../utils/queries';
import { StartAndEndDateTimeRangeValues } from '../../../../wmv-components';
import CustomTripDateFilter from '../../../CustomerInformationModal/UserTrips/CustomTripDateFilter';
import FilterButton from '../../../CustomerInformationModal/UserTrips/FilterButton';
import { LoadingAndErrorWithRetryAndNoResults } from '../../../LoadingAndErrorWithRetryAndNoResults';

import VehicleInfoReactTable from './VehicleInfoReactTable';

export type LastSearchInfo = {
  startEpochMillis: number;
  endEpochMillis: number;
  lookupDuration?: LookupDurationWindow;
};

const TIME_THRESHOLD = {
  'account.tripHistory.6h': 6 * 60 * 60 * 1000,
  'account.tripHistory.12h': 12 * 60 * 60 * 1000,
  'account.tripHistory.24h': 24 * 60 * 60 * 1000,
  'account.tripHistory.custom': null,
};

const VehicleTripHistory = ({ vehicleId }: { vehicleId: string }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [filteredTrips, setFilteredTrips] = useState<VehicleTripType[]>([]);
  const [activeLookupDuration, setActiveLookupDuration] = useState(LookupDurationWindow.SixHours);
  const [showCustomFilters, setShowCustomFilters] = useState(false);
  const [noTripHistoryResults, setNoTripHistoryResults] = useState(false);
  const [lastSearchInfo, setLastSearchInfo] = useState<LastSearchInfo>({
    startEpochMillis: 0,
    endEpochMillis: 0,
  });
  const [formInitialValues, setFormInitialValues] = useState({
    startDate: '',
    endDate: '',
  });

  const theme = useTheme();
  const { formatMessage } = useIntl();

  const fetchTripsStandard = useCallback(
    async (lookupDuration: LookupDurationWindow) => {
      if (lookupDuration === LookupDurationWindow.Custom) {
        setLoading(false);
        return;
      }

      let fromInclusiveEpochMillis = 0,
        toInclusiveEpochMillis = new Date().getTime();

      const timeThreshold = TIME_THRESHOLD[lookupDuration];
      if (timeThreshold) {
        fromInclusiveEpochMillis = toInclusiveEpochMillis - timeThreshold;
      }

      setLastSearchInfo({
        startEpochMillis: fromInclusiveEpochMillis,
        endEpochMillis: toInclusiveEpochMillis,
        lookupDuration: lookupDuration,
      });

      await fetchTripsForVehicleApiCall(vehicleId, fromInclusiveEpochMillis, toInclusiveEpochMillis, lookupDuration);
    },
    [vehicleId],
  );

  const fetchTripsForVehicleApiCall = async (
    vehicleId: string,
    fromInclusiveEpochMillis: number,
    toInclusiveEpochMillis: number,
    lookupDuration: LookupDurationWindow,
  ) => {
    try {
      setLoading(true);
      setError(false);
      setNoTripHistoryResults(false);

      setLastSearchInfo({
        startEpochMillis: fromInclusiveEpochMillis,
        endEpochMillis: toInclusiveEpochMillis,
        lookupDuration: lookupDuration,
      });

      const trips = await getTripsByVehicleId({
        vehicleId,
        fromInclusiveEpochMillis,
        toInclusiveEpochMillis,
      });

      setFilteredTrips(trips);
      setLoading(false);

      if (trips.length === 0) {
        setNoTripHistoryResults(true);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
      setFilteredTrips([]);
    }
  };

  const handleFilterClick = (lookupDuration: LookupDurationWindow) => {
    setError(false);
    setLoading(false);
    setNoTripHistoryResults(false);
    setActiveLookupDuration(lookupDuration);

    if (lookupDuration !== LookupDurationWindow.Custom) {
      fetchTripsStandard(lookupDuration);
    } else if (lookupDuration === LookupDurationWindow.Custom) {
      const timeStampStartDate = dayjs(`${formInitialValues.startDate}`);
      const timeStampEndDate = dayjs(`${formInitialValues.endDate}`);
      setLastSearchInfo({
        startEpochMillis: timeStampStartDate.valueOf(),
        endEpochMillis: timeStampEndDate.valueOf(),
        lookupDuration: LookupDurationWindow.Custom,
      });
    }
    setShowCustomFilters(lookupDuration === LookupDurationWindow.Custom);
  };

  const searchCustomTrips = async ({ startDateTime, endDateTime }: StartAndEndDateTimeRangeValues) => {
    const startEpochMillis = startDateTime!.valueOf();
    const endEpochMillis = endDateTime!.valueOf();

    setFormInitialValues({
      startDate: startDateTime!.format(DEFAULT_DATETIME_FORMAT_DAYJS),
      endDate: endDateTime!.format(DEFAULT_DATETIME_FORMAT_DAYJS),
    });

    await fetchTripsForVehicleApiCall(vehicleId, startEpochMillis, endEpochMillis, LookupDurationWindow.Custom);
  };

  useEffect(() => {
    setFilteredTrips([]);
    if (activeLookupDuration !== LookupDurationWindow.Custom) {
      fetchTripsStandard(activeLookupDuration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retryLastSearch = () => {
    setError(false);

    if (lastSearchInfo.lookupDuration === LookupDurationWindow.Custom) {
      searchCustomTrips({
        startDateTime: dayjs(lastSearchInfo.startEpochMillis),
        endDateTime: dayjs(lastSearchInfo.endEpochMillis),
      });
    } else {
      fetchTripsStandard(lastSearchInfo.lookupDuration || activeLookupDuration);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'stretch' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Typography variant="bodySmallBold">{formatMessage({ id: 'account.tripHistory.title' })}</Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            justifyContent: 'center',
            alignItems: 'flex-start',
            borderRadius: '8px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '16px',
              borderRadius: '8px',
              height: '48px',
              padding: '8px',
            }}
          >
            <Typography variant="bodyMedium" color={theme.palette.dark.shade60}>
              {formatMessage({ id: 'account.tripHistory.recent' })}
            </Typography>
            <>
              {Object.values(LookupDurationWindow).map((filter, index) => (
                <FilterButton key={index} filter={filter} activeFilter={activeLookupDuration} onClick={() => handleFilterClick(filter)} />
              ))}
            </>
          </Box>

          {showCustomFilters && <CustomTripDateFilter initialValues={formInitialValues} onSubmit={searchCustomTrips} />}
        </Box>
      </Box>

      {filteredTrips.length === 0 ? (
        <LoadingAndErrorWithRetryAndNoResults
          style={{ height: '150px' }}
          error={error}
          loading={loading}
          onRetry={retryLastSearch}
          baseTranslationKey={
            activeLookupDuration !== LookupDurationWindow.Custom ? 'tripSearchHistoryFixedDuration' : 'tripSearchHistoryCustomDuration'
          }
          noResults={noTripHistoryResults}
        />
      ) : (
        <>
          <VehicleInfoReactTable loading={false} filteredTrips={filteredTrips} />
        </>
      )}
    </Box>
  );
};

export default VehicleTripHistory;
