import { VehicleType } from '../VehicleType';

import { DepositUnit } from './details/DepositUnit';
import { VehicleSpendConstraintCategory } from './details/VehicleSpendConstraintCategory';

export interface MspBudgetSpendConstraint {
  type: VehicleSpendConstraintCategory.Msp;
  ids: string[];
}

export interface VehicleTypeBudgetSpendConstraint {
  type: VehicleSpendConstraintCategory.VehicleType;
  values: VehicleType[];
}

export type VehicleSpendConstraint = MspBudgetSpendConstraint | VehicleTypeBudgetSpendConstraint;

export interface BudgetSpendConstraint {
  type: BudgetSpendConstraintType;
  value: number;
}

export interface SpendConstraints {
  vehicle: VehicleSpendConstraint[];
  budget?: BudgetSpendConstraint[];
}

export interface Fragments {
  amount: number;
  amountMultiplier: number;
  unit: DepositUnit;
  spendConstraints?: SpendConstraints[];
}

export enum BudgetSpendConstraintType {
  LimitPerTrip = 'LIMIT_PER_TRIP',
}
