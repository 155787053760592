import { createReducer } from '@reduxjs/toolkit';

import { ZoneType, ZoneTypeHelper } from '../../models';
import { GraphqlVehicleUsageStatus, GraphqlVehicleUsageStatusHelper } from '../../models';
import { GraphqlVehicleType } from '../../models';
import {
  InfrastructureType,
  MspType,
  graphqlVehicleTypeMenuConfig,
  infrastructureTypesMenuConfig,
  mspTypesMenuConfig,
} from '../../utils/constants';

import {
  updateVehicleTypeFilterOptionsAction,
  updateVehicleStatusFilterOptionsAction,
  updateZoneTypeFilterOptionsAction,
  updateMspTypeFilterOptionsAction,
  updateInfrastructureTypeFilterOptionsAction,
  resetFilterOptionsAction,
} from './actions';
import { MapFilterOption } from './mapFilters.model';

export interface MapFiltersState {
  vehicleTypeOptions: MapFilterOption<GraphqlVehicleType>[];
  vehicleStatusOptions: MapFilterOption<GraphqlVehicleUsageStatus>[];
  mspTypeOptions: MapFilterOption<MspType>[];
  zoneTypeOptions: MapFilterOption<ZoneType>[];
  infrastructureTypeOptions: MapFilterOption<InfrastructureType>[];
}

const mapFiltersState: MapFiltersState = {
  vehicleTypeOptions: graphqlVehicleTypeMenuConfig,
  vehicleStatusOptions: GraphqlVehicleUsageStatusHelper.menuConfig,
  mspTypeOptions: mspTypesMenuConfig,
  zoneTypeOptions: ZoneTypeHelper.menuConfig.options,
  infrastructureTypeOptions: infrastructureTypesMenuConfig,
};

// TODO: This needs to be refactored, we can only filter values (no need to store labels)
const mapFiltersReducer = createReducer(mapFiltersState, (builder) =>
  builder
    .addCase(updateVehicleTypeFilterOptionsAction, (state, action) => {
      return { ...state, vehicleTypeOptions: [...action.payload] };
    })
    .addCase(updateVehicleStatusFilterOptionsAction, (state, action) => {
      return { ...state, vehicleStatusOptions: [...action.payload] };
    })
    .addCase(updateMspTypeFilterOptionsAction, (state, action) => {
      return { ...state, mspTypeOptions: [...action.payload] };
    })
    .addCase(updateZoneTypeFilterOptionsAction, (state, action) => {
      return { ...state, zoneTypeOptions: [...action.payload] };
    })
    .addCase(updateInfrastructureTypeFilterOptionsAction, (state, action) => {
      return { ...state, infrastructureTypeOptions: [...action.payload] };
    })
    .addCase(resetFilterOptionsAction, (state) => {
      return { ...state, ...mapFiltersState };
    }),
);

export default mapFiltersReducer;
