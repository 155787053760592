import { useState } from 'react';

import { Box, Divider, Grid, IconButton, MenuItem, MenuList, Modal, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { themeOptions as theme } from '../../init-setup/ThemeOptions';
import { CustomerDetailsType } from '../../models';
import CustomIcon from '../../utils/CustomIcon';
import UserDriverInformation from '../UserDriverInformation';

import CustomerDetails from './CustomerDetails';
import UserTrips from './UserTrips';

// TODO: This should be value based not tab label based (wrong implementation)
const getMenuItems = (intl: IntlShape) => {
  const { formatMessage } = intl;
  return [
    formatMessage({ id: 'customerInformationModal.tab.customerDetails' }),
    formatMessage({ id: 'customerInformationModal.tab.tripHistory' }),
    formatMessage({ id: 'customerInformationModal.tab.promotions' }),
    formatMessage({ id: 'customerInformationModal.tab.tickets' }),
    formatMessage({ id: 'customerInformationModal.tab.driversLicence' }),
  ];
};

const returnDetailsPanel = ({
  option,
  userInfo,
  handleUpdateUserDetails,
  intl,
}: {
  option: string;
  userInfo: CustomerDetailsType;
  handleUpdateUserDetails: (updatedUserDetails: CustomerDetailsType) => void;
  intl: IntlShape;
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { formatMessage } = useIntl();

  switch (option) {
    case getMenuItems(intl)[0]:
      return <CustomerDetails subjectClaim={userInfo.subjectClaim} onUpdateUserDetails={handleUpdateUserDetails} />;
    case getMenuItems(intl)[1]:
      return <UserTrips userId={userInfo.subjectClaim} />;
    case getMenuItems(intl)[2]:
      return <div>{formatMessage({ id: 'customerInformationModal.tab.promotions' })} </div>;
    case getMenuItems(intl)[3]:
      return <div>{formatMessage({ id: 'customerInformationModal.tab.tickets' })} </div>;
    case getMenuItems(intl)[4]:
      return <UserDriverInformation subjectClaim={userInfo.subjectClaim} />;
    default:
      return <div>{formatMessage({ id: 'customerInformationModal.tab.customerDetails' })}</div>;
  }
};

function CustomerInformationModal({ isOpen, onClose, userInfo }: { isOpen: boolean; onClose: () => void; userInfo: CustomerDetailsType }) {
  const [openCustomerModal] = useState(isOpen);
  const intl = useIntl();
  const { formatMessage } = intl;
  const [child, setChildUserDetails] = useState<CustomerDetailsType | null>(null);
  const [option, setOption] = useState(getMenuItems(intl)[0]);

  const handleUpdateUserDetails = (updatedUserDetails: CustomerDetailsType) => {
    setChildUserDetails(updatedUserDetails);
  };

  const CustomerModal = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    width: '80%',
    height: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: theme.palette.white.main,
  }));

  const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: '-40px',
    right: '-40px',
  }));

  const CustomerMenuItem = styled(MenuItem)(({ theme }) => ({
    borderRadius: '4px',
    fontFamily: 'OpenSansBold, sans-serif',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '32px',
    paddingTop: '0',
    paddingBottom: '0',
    color: theme.palette.dark.shade60,
    '&:hover': {
      backgroundColor: theme.palette.dark.shade4,
    },
    '&.Mui-selected': {
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.dark.shade4,
    },
  }));

  return (
    <Box>
      <Modal open={openCustomerModal} aria-labelledby="modal-title">
        <CustomerModal container direction="row" wrap="nowrap" justifyContent="center" alignItems="stretch">
          <Grid
            container
            item
            direction="column"
            xs={3}
            sx={{
              backgroundColor: theme.palette.dark.shade3,
              padding: '32px 8px 8px 8px',
              gap: '8px',
              margin: '0 8px 0 8px',
            }}
          >
            <Grid container direction="column" sx={{ paddingLeft: '16px', gap: '5px' }}>
              <Typography variant="bodyLargeBold" color={theme.palette.dark.main}>
                {child ? child.lastName : userInfo.lastName}, {child ? child.firstName : userInfo.firstName}
              </Typography>
              <Grid container direction="row">
                <Typography variant="bodySmallBold" color={theme.palette.dark.main} sx={{ paddingRight: '5px' }}>
                  {' '}
                  {formatMessage({ id: 'customerInformationModal.customerId' })}
                </Typography>
                <Typography variant="bodySmall" color={theme.palette.dark.shade76}>
                  {userInfo.subjectClaim ? userInfo.subjectClaim : 'no id'}
                </Typography>
              </Grid>
            </Grid>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                borderColor: theme.palette.dark.shade8,
                marginLeft: '8px',
                marginRight: '8px',
              }}
            ></Divider>
            <MenuList sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
              {getMenuItems(intl).map((item, index) => (
                <CustomerMenuItem key={index} disableRipple selected={option === item} onClick={() => setOption(item)}>
                  {' '}
                  {item}
                </CustomerMenuItem>
              ))}
            </MenuList>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: theme.palette.dark.shade8,
              marginLeft: '8px',
              marginRight: '8px',
            }}
          ></Divider>
          <Grid
            container
            direction="column"
            sx={{
              backgroundColor: option !== getMenuItems(intl)[1] ? theme.palette.dark.shade3 : null,
              padding: '16px 16px 24px 16px',
              gap: '16px',
              borderRadius: '8px',
              margin: '0 8px 0 8px',
              overflow: 'auto',
            }}
          >
            {returnDetailsPanel({ option, userInfo, handleUpdateUserDetails, intl })}
          </Grid>
          <CloseButton aria-label="close" onClick={() => onClose()} disableRipple>
            {CustomIcon('CloseIcon', theme.palette.white.main, '32px')}
          </CloseButton>
        </CustomerModal>
      </Modal>
    </Box>
  );
}

export default CustomerInformationModal;
