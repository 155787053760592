import { enqueueSnackbar } from 'notistack';

import { BudgetPlanMembershipStatusHelper } from '../../../../models/budget-plan';
import { BudgetPlanMembershipLastAttemptedStateTransitionDto } from '../../../../services/budget-plan-memberships';
import { intl } from '../../../../utils/intl';

export const enqueueSnackbars = (
  lastAttemptedStateTransition: BudgetPlanMembershipLastAttemptedStateTransitionDto,
  planName: string,
  planMemberName: string,
) => {
  const snackBarAlertMessage = intl.formatMessage(
    { id: `alert.budgetPlanMembership.${lastAttemptedStateTransition.isLastAttemptedStateTransitionFailed() ? 'failed' : 'success'}` },
    {
      member: planMemberName,
      budgetPlan: planName,
      actionType: BudgetPlanMembershipStatusHelper.metadata(lastAttemptedStateTransition.transitionTo).displayText,
    },
  );
  if (lastAttemptedStateTransition.isLastAttemptedStateTransitionInProgress()) {
    if (
      lastAttemptedStateTransition.isLastAttemptedStateTransitionFailed() ||
      lastAttemptedStateTransition.isLastAttemptedStateTransitionSuccessful()
    ) {
      enqueueSnackbar(snackBarAlertMessage, {
        variant: lastAttemptedStateTransition.isLastAttemptedStateTransitionFailed() ? 'error' : 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  }
};
