import { EnumWithStringValue } from '../utils/EnumUtils';
import { intl } from '../utils/intl';

export enum DayOfWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

interface DayOfWeekToUnderivedMetadata {
  displayText: string;
}

interface DayOfWeekMetadata extends DayOfWeekToUnderivedMetadata {}

export namespace DayOfWeekHelper {
  const dayOfWeekToUnderivedMetadata = new Map<DayOfWeek, DayOfWeekToUnderivedMetadata>([
    [DayOfWeek.Monday, { displayText: intl.formatMessage({ id: `common.days.monday` }) }],
    [DayOfWeek.Tuesday, { displayText: intl.formatMessage({ id: `common.days.tuesday` }) }],
    [DayOfWeek.Wednesday, { displayText: intl.formatMessage({ id: `common.days.wednesday` }) }],
    [DayOfWeek.Thursday, { displayText: intl.formatMessage({ id: `common.days.thursday` }) }],
    [DayOfWeek.Friday, { displayText: intl.formatMessage({ id: `common.days.friday` }) }],
    [DayOfWeek.Saturday, { displayText: intl.formatMessage({ id: `common.days.saturday` }) }],
    [DayOfWeek.Sunday, { displayText: intl.formatMessage({ id: `common.days.sunday` }) }],
  ]);

  export function metadata(dayOfWeek: DayOfWeek): DayOfWeekMetadata {
    const underivedMetadata = dayOfWeekToUnderivedMetadata.get(dayOfWeek);
    if (!underivedMetadata) {
      throw Error(`
            Specified DayOfWeek type: ${toString(dayOfWeek)} doesnt have corresponding underived/explicitly defined metadata.
            This usually (not always) means a bug or incomplete implementation.
        `);
    }

    return { ...underivedMetadata };
  }

  export function allDaysOfWeek(): Set<DayOfWeek> {
    return new Set<DayOfWeek>(allDaysOfWeekArray());
  }

  function allDaysOfWeekArray(): DayOfWeek[] {
    return EnumWithStringValue.enumToValues(DayOfWeek);
  }

  export function toString(dayOfWeek: DayOfWeek): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(DayOfWeek, dayOfWeek);
  }

  export function parse(dayOfWeekAsStr: keyof typeof DayOfWeek): DayOfWeek {
    return DayOfWeek[dayOfWeekAsStr];
  }

  export function dayOfWeekToShortFormTranslationKey(): Map<DayOfWeek, string> {
    return new Map<DayOfWeek, string>(allDaysOfWeekArray().map((dayOfWeek) => [dayOfWeek, toShortFormTranslationKey(dayOfWeek)]));
  }

  function toShortFormTranslationKey(dayOfWeek: DayOfWeek): string {
    return `dayOfWeek.shortForm.${toString(dayOfWeek)}`;
  }
}
