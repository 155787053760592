import { useEffect, useState } from 'react';

import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

import { UserTrip } from '../../../models';
import { ReactTable } from '../../../wmv-components';
import { LoadingAndErrorWithRetryAndNoResults } from '../../LoadingAndErrorWithRetryAndNoResults';
import UserTripHistory, { EndDate } from '../../UserTripHistory';

import { makeTripTableColumns } from './makeTripTableCoulms';
import useFetchAllTrips from './useFetchAllTrips';

interface UserTripsProps {
  userId: string;
}

const UserTrips = ({ userId }: UserTripsProps) => {
  const [allTrips, setAllTrips] = useState<UserTrip[]>([]);
  const intl = useIntl();
  const tripTableColumns = makeTripTableColumns(intl);

  const { allTripsData, loading, error, refetch: refetchAllTripsData } = useFetchAllTrips({ userId });

  useEffect(() => {
    setAllTrips(allTripsData);
  }, [allTripsData]);

  const handleUpdateEndDate = (updatedEndDate: EndDate) => {
    const updatedTrips = allTrips.map((trip) => {
      if (trip.id === updatedEndDate.id) {
        return {
          ...trip,
          endEpochMillis: updatedEndDate.endEpochMillis,
          active: updatedEndDate.active,
        };
      }
      return trip;
    });
    setAllTrips(updatedTrips);
  };

  const EXPANDER_COLUMN = {
    id: 'expander',
    Header: () => <></>,
    Cell: ({ row }: any) => {
      return (
        <Box
          {...row.getToggleRowExpandedProps({})}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {row.isExpanded ? (
            <ExpandLessOutlinedIcon sx={{ fontSize: 16, color: '#009881' }} />
          ) : (
            <ExpandMoreOutlinedIcon sx={{ fontSize: 16, color: '#009881' }} />
          )}
        </Box>
      );
    },
    width: '25px',
  };

  const handleRetry = async () => {
    refetchAllTripsData(userId);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'stretch' }}>
      {error || loading ? (
        <LoadingAndErrorWithRetryAndNoResults
          style={{ height: '150px' }}
          error={error}
          loading={loading}
          onRetry={handleRetry}
          baseTranslationKey="tripHistory"
        />
      ) : (
        <>
          {allTrips.length === 0 ? (
            <LoadingAndErrorWithRetryAndNoResults
              style={{ height: '150px' }}
              error={false}
              loading={false}
              onRetry={handleRetry}
              baseTranslationKey="tripHistory"
              noResults
            />
          ) : (
            <>
              <ReactTable
                loading={loading}
                columns={[...tripTableColumns, EXPANDER_COLUMN]}
                tableContainerStyle={{
                  border: '1px solid #f0f0f0',
                  borderRadius: '8px',
                }}
                renderDetailsComponent={({ row }: any) => {
                  return <UserTripHistory trip={row} onUpdateEndDate={handleUpdateEndDate} enableForceStop={true} />;
                }}
                onRowClickHandler={(row: UserTrip) => {
                  console.log(row);
                }}
                data={allTrips}
                pagination={false}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default UserTrips;
