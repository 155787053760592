import { type ChangeEvent, Fragment } from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { VehicleTypeHelper } from '../../../../models';
import { BudgetPlanActionMode, budgetPlanActionModeSelector } from '../../../../state';
import { confirmationRadioButtonConfig } from '../../../../utils/constants';
import { Checkbox, RadioButton } from '../../../../wmv-components';
import { budgetPlanFormInputKeys } from '../helpers';
import { budgetPlanFormInputStyles, vehicleTypesCheckboxGroupStyles } from '../styles';

import { BudgetPlanDetailsFormValues } from './BudgetPlanDetailsView';
import { formInputTranslationKey } from './BudgetPlanFormInputs';
import { BudgetPlanInputContainer } from './BudgetPlanInputContainer';

export const BudgetSpendConstraintsInput = () => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { setFieldValue, handleBlur, handleChange, values, errors, touched, setFieldError, setFieldTouched, isSubmitting } =
    useFormikContext<BudgetPlanDetailsFormValues>();
  const planActionMode = useSelector(budgetPlanActionModeSelector);

  const handleSpendConstraintOnAmountPerTripToggle = async (event: ChangeEvent<HTMLInputElement>) => {
    await setFieldValue(budgetPlanFormInputKeys.amountPerTripSpendConstraintExists, event.target.value === 'true');
    await setFieldValue(budgetPlanFormInputKeys.amountPerTripSpendConstraint, null);
    setFieldError(budgetPlanFormInputKeys.amountPerTripSpendConstraint, undefined);
  };

  const handleVehicleTypesSpendConstraintToggle = async (event: ChangeEvent<HTMLInputElement>) => {
    await setFieldValue(budgetPlanFormInputKeys.vehicleTypesSpendConstraintExists, event.target.value === 'true');
    await setFieldValue(budgetPlanFormInputKeys.vehicleTypesSpendConstraint, []);
    setFieldError(budgetPlanFormInputKeys.vehicleTypesSpendConstraint, undefined);
  };

  const handleVehicleTypesChange = async (vehicleTypes: (string | number)[]) => {
    await setFieldTouched(budgetPlanFormInputKeys.vehicleTypesSpendConstraint, true);
    await setFieldValue(budgetPlanFormInputKeys.vehicleTypesSpendConstraint, vehicleTypes);
  };

  const viewMode = planActionMode === BudgetPlanActionMode.View;
  return (
    <Fragment>
      <BudgetPlanInputContainer translationGroupKeyPrefix="spendRestriction" required>
        <Box display="flex" flexDirection="column">
          <RadioButton.Group
            name={budgetPlanFormInputKeys.amountPerTripSpendConstraintExists}
            value={values.amountPerTripSpendConstraintExists}
            options={confirmationRadioButtonConfig}
            disabled={viewMode || isSubmitting}
            onChange={handleSpendConstraintOnAmountPerTripToggle}
            hasError={!!errors.amountPerTripSpendConstraintExists && touched.amountPerTripSpendConstraintExists}
            helperText={
              !!errors.amountPerTripSpendConstraintExists && touched.amountPerTripSpendConstraintExists
                ? errors.amountPerTripSpendConstraintExists
                : null
            }
          />
          {values.amountPerTripSpendConstraintExists && (
            <TextField
              type="string"
              name={budgetPlanFormInputKeys.amountPerTripSpendConstraint}
              value={values.amountPerTripSpendConstraintExists ? values.amountPerTripSpendConstraint : ''}
              disabled={viewMode || isSubmitting}
              error={!!errors.amountPerTripSpendConstraint && touched.amountPerTripSpendConstraint}
              helperText={
                !!errors.amountPerTripSpendConstraint && touched.amountPerTripSpendConstraint ? errors.amountPerTripSpendConstraint : null
              }
              label={formatMessage({ id: `${formInputTranslationKey}.spendRestriction.label` })}
              placeholder={formatMessage({ id: `common.numberPlaceholder` }, { value: 100 })}
              size="small"
              sx={{ ...budgetPlanFormInputStyles(theme), mt: 4 }}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          )}
        </Box>
      </BudgetPlanInputContainer>
      <BudgetPlanInputContainer translationGroupKeyPrefix="vehicleTypes" required>
        <Box>
          <RadioButton.Group
            name={budgetPlanFormInputKeys.vehicleTypesSpendConstraintExists}
            value={values.vehicleTypesSpendConstraintExists}
            options={confirmationRadioButtonConfig}
            disabled={viewMode || isSubmitting}
            onChange={handleVehicleTypesSpendConstraintToggle}
            hasError={!!errors.vehicleTypesSpendConstraintExists && touched.vehicleTypesSpendConstraintExists}
            helperText={
              !!errors.vehicleTypesSpendConstraintExists && touched.vehicleTypesSpendConstraintExists
                ? errors.vehicleTypesSpendConstraintExists
                : null
            }
          />
          {values.vehicleTypesSpendConstraintExists && (
            <Box>
              <Checkbox.Group
                options={VehicleTypeHelper.valuesWithLabel()}
                checkedValues={values.vehicleTypesSpendConstraint}
                onChange={handleVehicleTypesChange}
                size="small"
                sx={vehicleTypesCheckboxGroupStyles}
                disabled={viewMode || isSubmitting}
                hasError={!!errors.vehicleTypesSpendConstraint && touched.vehicleTypesSpendConstraint}
                helperText={
                  !!errors.vehicleTypesSpendConstraint && touched.vehicleTypesSpendConstraint ? errors.vehicleTypesSpendConstraint : null
                }
              />
            </Box>
          )}
        </Box>
      </BudgetPlanInputContainer>
    </Fragment>
  );
};
