import { useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
// eslint-disable-next-line import/named
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

import { HEADER_HEIGHT } from '../../utils/constants';

import { CustomerDetailsView } from './CustomerDetails/CustomerDetails';
import { LawEnforcementTripSearchView } from './LawEnforcementTripSearch/LawEnforcementTripSearchView';

// is also used as key in the translation files
const customerDatabaseViews = ['customerDetails', 'lawEnforcementTripSearch'];

const getSelectedViewComponent = (view: string) => {
  switch (view) {
    case 'customerDetails':
      return <CustomerDetailsView></CustomerDetailsView>;
    case 'lawEnforcementTripSearch':
      return <LawEnforcementTripSearchView></LawEnforcementTripSearchView>;
    default:
      return <></>;
  }
};

const CustomerDatabase = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const [selectedView, setSelectedView] = useState(customerDatabaseViews[0]);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedView(event.target.value as string);
  };

  const isCustomerDetailView = selectedView === 'customerDetails';

  return (
    <Box
      sx={{
        width: '100%',
        height: `calc(100vh - ${HEADER_HEIGHT})`,
        backgroundColor: theme.palette.dark.shade8,
      }}
    >
      <Box style={{ float: 'left' }} sx={{ p: 3, pt: isCustomerDetailView ? 4 : 3, bgcolor: 'white.main' }}>
        <Box sx={{ mb: theme.spacing(2) }}>
          <Typography variant="bodyLargeBold" color={theme.palette.dark.main}>
            {formatMessage({ id: 'maas.customerDatabase.customerManagement' })}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography component="label" htmlFor="inputType" variant="caption" color={theme.palette.dark.shade60} sx={{ mb: '4px' }}>
            {formatMessage({ id: 'maas.customerDatabase.inputType' })}
          </Typography>

          <FormControl size="small">
            <Select name="inputType" displayEmpty value={selectedView} onChange={handleChange} color="secondary">
              {customerDatabaseViews.map((view) => (
                <MenuItem key={view} value={view}>
                  {formatMessage({ id: `maas.customerDatabase.${view}.headline` })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      {getSelectedViewComponent(selectedView)}
    </Box>
  );
};

export default CustomerDatabase;
