import type { MouseEvent } from 'react';

import ButtonGroup from '@mui/material/ButtonGroup';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { StyledButton } from './Switch.styles';

export interface SwitchButonConfig {
  text: string;
  switchModeButtonId: string;
}

export const Switch = ({
  config,
  activeButtonId,
  onClick,
}: {
  config: SwitchButonConfig[];
  activeButtonId: string;
  onClick: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, switchModeButtonId: string) => void;
}) => {
  const theme = useTheme();

  return (
    <ButtonGroup
      variant="outlined"
      aria-label="outlined primary button group"
      sx={{
        minWidth: '320px',
        minHeight: '48px',
        marginBottom: '12px',
        boxShadow: theme.shadowOptions.belowMedium,
      }}
    >
      {config.map((config: SwitchButonConfig) => {
        const isActive = activeButtonId === config.switchModeButtonId;

        return (
          <StyledButton
            key={config.switchModeButtonId}
            onClick={(e) => onClick(e, config.switchModeButtonId)}
            isActive={isActive}
            variant="contained"
          >
            <Typography variant={isActive ? 'bodyMediumBold' : 'bodyMedium'} color={theme.palette.dark.shade76}>
              {config.text}
            </Typography>
          </StyledButton>
        );
      })}
    </ButtonGroup>
  );
};
