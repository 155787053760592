import { Fragment, ReactNode } from 'react';

import type { ButtonProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { LoadingAnimation } from './index';

export const ProgressAwareButton = ({
  loading,
  startIcon = null,
  children,
  variant = 'contained',
  type = 'submit',
  color = 'primary',
  loadingButtonProps = {},
  size = 'medium',
}: LoadingButtonProps) => {
  let sizeMultiplier = 0.5;
  if (size === 'small') {
    sizeMultiplier = 0.25;
  }
  return (
    <Button
      sx={{ textTransform: 'unset' }}
      disabled={loading}
      variant={variant}
      size={size}
      type={type}
      color={color}
      {...loadingButtonProps}
    >
      {loading ? (
        <LoadingAnimation sizeMultiplier={sizeMultiplier} />
      ) : (
        <Fragment>
          {startIcon}
          <Box ml={0.5}>{children}</Box>
        </Fragment>
      )}
    </Button>
  );
};

interface LoadingButtonProps {
  loading: boolean;
  children: ReactNode;
  type?: ButtonProps['type'];
  variant?: ButtonProps['variant'];
  startIcon?: ReactNode;
  color?: ButtonProps['color'];
  loadingButtonProps?: ButtonProps;
  size?: ButtonProps['size'];
}
