import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { themeOptions as theme } from '../../init-setup/ThemeOptions';
import { Billing, DurationBreakdown, DurationBreakdownType, UserTripDetails } from '../../models';
import { DEFAULT_CURRENCY } from '../../utils/constants';

import { Accordion, AccordionDetails, AccordionSummary } from './Accordion';

const StyledTableCell = ({ children, variant = 'bodySmallBold' }: { children: React.ReactNode; variant?: any }) => (
  <TableCell sx={{ border: 'none', padding: 1 }}>
    <Typography variant={variant}>{children}</Typography>
  </TableCell>
);

interface TripDetailsAccordionProps {
  billing: Billing;
  tripDetails: UserTripDetails;
}
interface DurationBreakdownSecondsProps {
  index: number;
  duration: DurationBreakdown;
  durationSecondsValue: number;
}

const TripDetailsAccordion = ({ billing, tripDetails }: TripDetailsAccordionProps) => {
  const intl = useIntl();
  const { amountBreakdown, durationBreakdown } = billing;
  const totalAmount = amountBreakdown.reduce((acc, curr) => acc + curr.amount, 0);
  const totalTax = amountBreakdown.filter((amount) => amount.type === 'TAX').reduce((acc, curr) => acc + curr.amount, 0);
  const totalActiveCharges = amountBreakdown
    .filter((amount) => amount.subType === 'ACTIVE_DURATION')
    .reduce((acc, curr) => acc + curr.amount, 0);
  const totalPausedCharges = amountBreakdown
    .filter((amount) => amount.subType === 'PAUSED_DURATION')
    .reduce((acc, curr) => acc + curr.amount, 0);

  const totalActiveDuration = durationBreakdown
    .filter((duration) => duration.type === DurationBreakdownType.ACTIVE)
    .reduce((acc, curr) => acc + curr.actualDurationSeconds, 0);

  const totalActiveChargedDuration = durationBreakdown
    .filter((duration) => duration.type === DurationBreakdownType.ACTIVE)
    .reduce((acc, curr) => acc + curr.billableDurationSeconds, 0);

  const totalPausedDuration = durationBreakdown
    .filter((duration) => duration.type === DurationBreakdownType.PAUSED)
    .reduce((acc, curr) => acc + curr.actualDurationSeconds, 0);

  const totalPausedChargedDuration = durationBreakdown
    .filter((duration) => duration.type === DurationBreakdownType.PAUSED)
    .reduce((acc, curr) => acc + curr.billableDurationSeconds, 0);

  const DurationBreakdownSeconds = ({ index, duration, durationSecondsValue }: DurationBreakdownSecondsProps) => {
    return (
      <StyledTableCell variant="bodySmall">
        {index === durationBreakdown.length - 1 && duration.type === DurationBreakdownType.ACTIVE && tripDetails.active === true ? (
          <Typography sx={{ color: theme.palette.alert.main }}>
            {intl.formatMessage({ id: 'userTripDetails.activeTrip.placeholder' })}
          </Typography>
        ) : (
          durationSecondsValue.parseSecondsSinceBeginningOfDay().formatAsTimeWithoutMilliSeconds()
        )}
      </StyledTableCell>
    );
  };

  return (
    <>
      <Accordion id="panel-1">
        <AccordionSummary>
          <Typography variant="bodySmallBold">{intl.formatMessage({ id: 'userTripDetails.duration' })}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableHead>
              <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.activity' })}</StyledTableCell>
              <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.time' })}</StyledTableCell>
              <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.actualDuration' })}</StyledTableCell>
              <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.chargeDuration' })}</StyledTableCell>
            </TableHead>
            <TableBody>
              {durationBreakdown.map((duration, index) => (
                <TableRow key={duration.startEpochMillis}>
                  <StyledTableCell variant="bodySmall">
                    {intl.formatMessage({ id: `userTripDetails.billing.status.${duration.type.toLowerCase()}` })}
                  </StyledTableCell>
                  <StyledTableCell variant="bodySmall">
                    {duration.startEpochMillis.parseEpochMillis().formatAsDateTimeString()}
                  </StyledTableCell>
                  <DurationBreakdownSeconds index={index} duration={duration} durationSecondsValue={duration.actualDurationSeconds} />
                  <DurationBreakdownSeconds index={index} duration={duration} durationSecondsValue={duration.billableDurationSeconds} />
                </TableRow>
              ))}
              <TableRow>
                <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.totalActiveDuration' })}</StyledTableCell>
                <StyledTableCell>{totalActiveDuration.parseSecondsSinceBeginningOfDay().formatAsTimeWithoutMilliSeconds()}</StyledTableCell>
                <StyledTableCell>
                  {totalActiveChargedDuration.parseSecondsSinceBeginningOfDay().formatAsTimeWithoutMilliSeconds()}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.totalPausedDuration' })}</StyledTableCell>
                <StyledTableCell>{totalPausedDuration.parseSecondsSinceBeginningOfDay().formatAsTimeWithoutMilliSeconds()}</StyledTableCell>
                <StyledTableCell>
                  {totalPausedChargedDuration.parseSecondsSinceBeginningOfDay().formatAsTimeWithoutMilliSeconds()}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
      <Accordion id="panel-2">
        <AccordionSummary>
          <Typography variant="bodySmallBold" flexGrow={2}>
            {intl.formatMessage({ id: 'userTripDetails.tripCosts' })}
          </Typography>
          <Typography variant="bodySmallBold" flex={1} pl="1rem">
            {intl.formatNumber(totalAmount, {
              style: 'currency',
              currency: 'EUR',
            })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.activeTripCost' })}</StyledTableCell>
                <StyledTableCell>
                  {intl.formatNumber(totalActiveCharges, {
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.pausedTripCost' })}</StyledTableCell>
                <StyledTableCell>
                  {intl.formatNumber(totalPausedCharges, {
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  {intl.formatMessage({ id: 'userTripDetails.tax' })} {DEFAULT_CURRENCY}
                </StyledTableCell>
                <StyledTableCell>
                  {intl.formatNumber(totalTax, {
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default TripDetailsAccordion;
