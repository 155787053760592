import { useEffect, useState } from 'react';

import { ZoneResponse, ZoneStatus } from '../../models';
import { Zone } from '../../services/zone';
import { fetchZonesByStatus } from '../../utils/queries';

function useFetchPublishedZones() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allPublishedZones, setAllPublishedZones] = useState<Zone[]>([]);

  useEffect(() => {
    fetchZonesByStatusReq();
  }, []);

  const fetchZonesByStatusReq = async () => {
    setLoading(true);
    await fetchZonesByStatus(ZoneStatus.Published)
      .then((data: ZoneResponse[]) => {
        if (data.length === 0) {
          setAllPublishedZones([]);
        } else {
          const allZones = data.map((zone) => new Zone(zone));
          setAllPublishedZones(allZones);
        }
      })
      .catch(() => {
        setAllPublishedZones([]);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { allPublishedZones, loading, error, getPublishedZones: fetchZonesByStatusReq };
}

export default useFetchPublishedZones;
