import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { BudgetPlanSummaryCumDetailsResponse, CreateBudgetPlanRequest, BudgetPlanLastChangeAttemptStatus } from '../../models/budget-plan';
import { intl } from '../../utils/intl';
import {
  addBudgetPlan as addBudgetPlanApi,
  fetchBudgetPlanByIdApi,
  listBudgetPlans,
  updateBudgetPlan as updateBudgetPlanApi,
  updateBudgetPlanStatus,
} from '../../utils/queries';
import { showAlertAction } from '../app';

import {
  BudgetPlanActionMode,
  BudgetPlanApiError,
  BudgetPlanReduxActionPayloadType,
  UpdateBudgetPlanActionPayload,
  UpdateBudgetPlanStatusActionPayload,
} from './models';

export const resetErrorAction = createAction<BudgetPlanReduxActionPayloadType>('budgetPlan/resetError');
export const setBudgetPlanActionModeAction = createAction<BudgetPlanActionMode>('budgetPlan/actionMode');

export const listBudgetPlansAsyncThunkAction = createAsyncThunk<
  BudgetPlanSummaryCumDetailsResponse[],
  {},
  { rejectValue: BudgetPlanApiError | null }
>('budgetPlan/listBudgetPlans', async (_, { rejectWithValue }) => {
  try {
    return await listBudgetPlans();
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const addBudgetPlanAsyncThunkAction = createAsyncThunk<
  BudgetPlanSummaryCumDetailsResponse,
  CreateBudgetPlanRequest,
  { rejectValue: BudgetPlanApiError | null }
>('budgetPlan/addBudgetPlan', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const resp = await addBudgetPlanApi(payload);
    dispatch(showAlertAction({ message: intl.formatMessage({ id: 'budgetPlan.addSuccess.alertMessage' }) }));
    return resp;
  } catch (error: any) {
    dispatch(
      showAlertAction({
        message: intl.formatMessage({ id: 'budgetPlan.addFailure.alertMessage' }),
        type: 'error',
      }),
    );
    return rejectWithValue(error.response.data);
  }
});

export const getBudgetPlanAction = createAsyncThunk<BudgetPlanSummaryCumDetailsResponse, number>(
  'budgetPlan/getBudgetPlan',
  async (budgetPlanId, { dispatch, rejectWithValue }) => {
    try {
      const resp = await fetchBudgetPlanByIdApi(budgetPlanId);
      if (resp.lastStateChangeAttempt.status !== BudgetPlanLastChangeAttemptStatus.InProgress) {
        dispatch({ type: 'budgetPlan/updateBudgetPlan/fulfilled', payload: resp });
        dispatch(
          showAlertAction({
            message: intl.formatMessage({ id: 'common.updateSuccess.alertMessage' }, { label: `${resp.activeState.details.name}` }),
          }),
        );
      }

      return resp;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateBudgetPlanAsyncThunkAction = createAsyncThunk<
  BudgetPlanSummaryCumDetailsResponse,
  UpdateBudgetPlanActionPayload,
  { rejectValue: BudgetPlanApiError | null }
>('budgetPlan/updateBudgetPlan', async ({ id, payload }, { dispatch, rejectWithValue }) => {
  try {
    const resp = await updateBudgetPlanApi(payload, id);
    dispatch(showAlertAction({ message: intl.formatMessage({ id: 'budgetPlan.updateSuccess.alertMessage' }) }));
    return resp;
  } catch (err: any) {
    console.error(err);
    let error: AxiosError<BudgetPlanApiError> = err;
    if (!error.response) {
      throw err;
    }
    dispatch(
      showAlertAction({
        message: intl.formatMessage({ id: 'budgetPlan.updateFailure.alertMessage' }),
        type: 'error',
      }),
    );
    return rejectWithValue(error.response.data);
  }
});

export const updateBudgetPlanStatusAsyncThunkAction = createAsyncThunk<
  BudgetPlanSummaryCumDetailsResponse,
  UpdateBudgetPlanStatusActionPayload,
  { rejectValue: BudgetPlanApiError | null }
>('budgetPlan/updateBudgetPlanStatus', async ({ id, payload }, { dispatch, rejectWithValue }) => {
  try {
    const resp = await updateBudgetPlanStatus(payload, id);
    dispatch(showAlertAction({ message: intl.formatMessage({ id: 'budgetPlan.updateSuccess.alertMessage' }) }));
    return resp;
  } catch (err: any) {
    console.error(err);
    let error: AxiosError<BudgetPlanApiError> = err;
    if (!error.response) {
      throw err;
    }
    dispatch(
      showAlertAction({
        message: intl.formatMessage({ id: 'budgetPlan.updateFailure.alertMessage' }),
        type: 'error',
      }),
    );
    return rejectWithValue(error.response.data);
  }
});
