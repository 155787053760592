import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Field } from 'formik';
import { useIntl } from 'react-intl';

import CustomTextField from './CustomTextField';
import FormikErrorMessage from './ErrorMessage';

export interface FormFieldWithLabelInfo {
  name: string;
  label: string;
  type: string;
  isMandatory?: boolean;
  error?: boolean;
  format?: string;
}

export const FormFieldWithLabel = ({ name, label, type, isMandatory, error, format }: FormFieldWithLabelInfo) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography component="label" htmlFor={name} variant="caption" color={theme.palette.dark.shade60} sx={{ mb: '4px' }}>
        {formatMessage({ id: label })}
        {isMandatory && (
          <Typography component="span" style={{ color: 'red' }}>
            *
          </Typography>
        )}
      </Typography>
      <Field as={CustomTextField} name={name} type={type} error={error} format={format} />
      <FormikErrorMessage name={name} />
    </Box>
  );
};
