/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { Zone } from '../../services/zone';
import { loadingVehiclesSelector } from '../../state';
import { LiveVehicle } from '../../state/common/vehicle.model';
import { analyticsTableAllColumns } from '../../utils/constants';
import GeoJsonZones from '../Map/GeoJsonZones';
import VehicleStateLegendAndResetFilterBottomBar from '../Map/VehicleStateLegendAndResetFilterBottomBar';
import WikimoveMap from '../Map/WikimoveMap';

import ZonesPopupDataTable from './ZonesPopupDataTable';

export const MapTableView = ({ zones }: { zones: Zone[] }) => {
  const [popupTableData, setPopupTableData] = useState<LiveVehicle[]>([]);

  const [open, setOpen] = useState(false);
  const [openMarkerId, setOpenMarkerId] = useState<string | null | undefined>(null);

  const height = useMemo(() => {
    return `calc(100% - ${open ? '255px' : '0px'})`;
  }, [open]);

  const loading = useSelector(loadingVehiclesSelector);

  useEffect(() => {
    if (!open && popupTableData.length > 0) {
      setOpen(true);
    }
    if (open && popupTableData.length < 1) {
      setOpen(false);
    }
  }, [popupTableData]);

  return (
    <>
      <Box sx={{ height }}>
        <WikimoveMap openMarkerId={openMarkerId} setPopupTableData={setPopupTableData}>
          <GeoJsonZones zones={zones} />
        </WikimoveMap>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          borderRadius: 2,
        }}
      >
        {open && (
          <ZonesPopupDataTable
            loading={loading}
            data={popupTableData}
            columns={analyticsTableAllColumns}
            onClose={() => {
              setOpen(false);
              setOpenMarkerId(null);
            }}
            onRowClickHandler={setOpenMarkerId}
          />
        )}
        <VehicleStateLegendAndResetFilterBottomBar />
      </Box>
    </>
  );
};
