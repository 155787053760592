import { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import MultipleSelectField from '../../components/FormFields/MultipleSelectField';
import ZoneControls from '../../components/Map/ZoneControls';
import { MapTableView } from '../../components/MapTableView';
import { DayOfWeekHelper, FloatingButtonType, ModeType, VehicleType, ZoneType } from '../../models';
import { TableColumnType } from '../../models';
import { ZonePreviewPredicates, Mode } from '../../services/zone';
import { AppDispatch, vehicleTypesSelector, zoneTypesSelector } from '../../state';
import { filterProfilesSelector, selectedFilterProfileSelector } from '../../state';
import { allVehiclesSelector, loadingVehiclesSelector } from '../../state';
import { getPublishedZonesAsyncThunkAction, publishedZonesSelector } from '../../state/zones-management';
import {
  END_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY,
  HEADER_TABLE_GAP_HEIGHT,
  MAX_DEFAULT_DATE,
  MIN_DEFAULT_DATE,
  START_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY,
  TABLE_HEIGHT,
  TABLE_HEIGHT_NO_SECONDARY,
  analyticsTableAllColumns,
} from '../../utils/constants';
import { intl } from '../../utils/intl';
import { Switch, FloatingBox, DataTable, SideDrawer } from '../../wmv-components';

import TableFilters from './TableFilters';

const switchButtonConfig = [
  {
    text: intl.formatMessage({ id: 'map.mapMode' }),
    switchModeButtonId: FloatingButtonType.ViewMode,
  },
  {
    text: intl.formatMessage({ id: 'map.listMode' }),
    switchModeButtonId: FloatingButtonType.ListMode,
  },
];

const Analytics = () => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [columns, setColumns] = useState<TableColumnType[]>([]);
  const [activeView, setActiveView] = useState(FloatingButtonType.ViewMode);

  const publishedZones = useSelector(publishedZonesSelector);
  const loadingData = useSelector(loadingVehiclesSelector);
  const allVehicles = useSelector(allVehiclesSelector);
  const filterProfiles = useSelector(filterProfilesSelector);
  const selectedFilterProfile = useSelector(selectedFilterProfileSelector);
  const zoneTypes: ZoneType[] = useSelector(zoneTypesSelector);
  const vehicleTypes: VehicleType[] = useSelector(vehicleTypesSelector);

  const isViewMode = activeView === FloatingButtonType.ViewMode;

  useEffect(() => {
    const filterProfile = filterProfiles.find((fp) => fp.filterName === selectedFilterProfile);

    if (filterProfile) {
      const newColumns = filterProfile.visibleColumns;
      sortAndUpdateColumns(newColumns);
    }
  }, [filterProfiles, selectedFilterProfile]);

  const handleOpenDrawer = (v: boolean) => {
    setOpenDrawer(v);
  };

  const getPreFilter = (columnAccessor: string) => {
    const columnFilter = filterProfiles.find((fp) => fp.filterName === selectedFilterProfile)?.columnFilters;
    if (columnFilter && columnFilter.length > 0) {
      const preFilterValue = columnFilter.find((cf) => cf.column.accessor === columnAccessor);
      if (preFilterValue) {
        // console.log(preFilterValue && preFilterValue.value);
        return preFilterValue.value;
      }
    }
    return undefined;
  };

  const sortAndUpdateColumns = (newColumns: TableColumnType[]) => {
    // sort Columns based on id.
    const newA = [...newColumns].sort((a, b) => a.id - b.id);
    // console.log(newA)
    setColumns(newA);
  };

  useEffect(() => {
    dispatch(getPublishedZonesAsyncThunkAction());
  }, [dispatch]);

  const applicablePublishedZones = useMemo(() => {
    const zonePreviewPredicates = new ZonePreviewPredicates(
      new Mode(ModeType.WideInterval),
      new Set(zoneTypes),
      MIN_DEFAULT_DATE.asDayjsDate(),
      MAX_DEFAULT_DATE.asDayjsDate(),
      START_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY,
      END_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY,
      DayOfWeekHelper.allDaysOfWeek(),
      new Set(vehicleTypes),
    );
    const publishedZonesFromNowOnwards = zonePreviewPredicates.provideApplicableZones(dayjs.now().toEpochMillis(), publishedZones);
    return publishedZonesFromNowOnwards;
  }, [vehicleTypes, zoneTypes, publishedZones]);

  const handleSwitchModeButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, switchModeButtonId: string) => {
    setActiveView(switchModeButtonId as FloatingButtonType);
  };

  return (
    <Box>
      <FloatingBox boxStyling={{ top: '104px' }}>
        <Switch config={switchButtonConfig} activeButtonId={activeView} onClick={handleSwitchModeButtonClick} />
        {isViewMode && <ZoneControls />}
      </FloatingBox>

      {!isViewMode ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              height: HEADER_TABLE_GAP_HEIGHT,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: theme.spacing(2),
            }}
          >
            <MultipleSelectField
              value={columns}
              options={analyticsTableAllColumns}
              outlineLabel={intl.formatMessage({ id: 'analytics.filter.columns.showHide' })}
              setValue={sortAndUpdateColumns}
            />
          </Box>
          <Box>
            <DataTable
              getPreFilter={getPreFilter}
              filterChangeDependency={[filterProfiles, selectedFilterProfile]}
              data={allVehicles}
              columns={columns}
              loading={loadingData}
              rowCount={20}
              tableContainerStyle={{
                height: TABLE_HEIGHT,
                width: `calc(100vw - (${openDrawer ? '480px' : '100px'}))`,
                overflow: 'auto',
              }}
            />
            <SideDrawer open={openDrawer}>
              <TableFilters open={openDrawer} handleOpenDrawer={handleOpenDrawer} style={{ height: TABLE_HEIGHT }} />
            </SideDrawer>
          </Box>
        </Box>
      ) : (
        <Box sx={{ height: TABLE_HEIGHT_NO_SECONDARY }}>
          <MapTableView zones={applicablePublishedZones} />
        </Box>
      )}
    </Box>
  );
};

export default Analytics;
