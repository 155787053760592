import { combineReducers } from '@reduxjs/toolkit';

import analytics from './analytics/reducer';
import app from './app/reducer';
import budgetPlan from './budget-plan/reducer';
import common from './common/reducer';
import mapFilters from './mapFilters/reducer';
import toUpdate from './toUpdate/reducer';
import zones from './zones-management/reducer';

const rootReducer = combineReducers({
  mapFilters,
  toUpdate,
  common,
  analytics,
  budgetPlan,
  zones,
  app,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
