import once from 'lodash/once';

import MapInfoBoxNoParking from '../assets/illustrations/MapInfoBox/noParkingZone.svg';
import MapInfoBoxNoRideZone from '../assets/illustrations/MapInfoBox/noRideZone.svg';
import MapInfoBoxRestrictedZone from '../assets/illustrations/MapInfoBox/restrictedZone.svg';
import MapInfoBoxSlowZone from '../assets/illustrations/MapInfoBox/slowZone.svg';
import MapInfoBoxStopZone from '../assets/illustrations/MapInfoBox/stopZone.svg';
import no_parking_area from '../assets/map-illustrations/no-parking-zone-area.svg';
import no_parking_icon from '../assets/map-illustrations/no-parking-zone-icon.svg';
import restricted_zone_area from '../assets/map-illustrations/restricted-zone-area.svg';
import restricted_zone_icon from '../assets/map-illustrations/restricted-zone-icon.svg';
import slow_zone_area from '../assets/map-illustrations/slow-zone-area.svg';
import slow_zone_icon from '../assets/map-illustrations/slow-zone-icon.svg';
import { EnumWithStringValue } from '../utils/EnumUtils';
import { intl } from '../utils/intl';

export enum ZoneType {
  NO_PARKING = 'NO_PARKING',
  SLOW_RIDE = 'SLOW_RIDE',
  RESTRICTED = 'RESTRICTED',
  STOP = 'STOP',
  NO_RIDE = 'NO_RIDE', // TODO: Check if this needs to be removed
}

interface ZoneTypeDerivedMetadata {
  translationKey: string;
  descriptionTranslationKey: string;
}

interface ZoneTypeUnderivedMetadata {
  color: string;
  helpArtifacts: {
    iconImageSrc: string;
    areaImageSrc: string;
    mapInfoImageSrc?: string;
  };
}

interface ZoneTypeMenuConfig {
  name: string;
  label: string;
  options: {
    label: string;
    value: ZoneType;
  }[];
}

export interface ZoneTypeMetadata extends ZoneTypeUnderivedMetadata, ZoneTypeDerivedMetadata {}

export namespace ZoneTypeHelper {
  const zoneTypeToUnderivedMetadata: Map<ZoneType, ZoneTypeUnderivedMetadata> = new Map<ZoneType, ZoneTypeUnderivedMetadata>([
    [
      ZoneType.NO_PARKING,
      {
        color: '#DD5147',
        helpArtifacts: {
          iconImageSrc: no_parking_icon,
          areaImageSrc: no_parking_area,
          mapInfoImageSrc: MapInfoBoxNoParking,
        },
      },
    ],
    [
      ZoneType.SLOW_RIDE,
      {
        color: '#D68D0C',
        helpArtifacts: {
          iconImageSrc: slow_zone_icon,
          areaImageSrc: slow_zone_area,
          mapInfoImageSrc: MapInfoBoxSlowZone,
        },
      },
    ],
    [
      ZoneType.RESTRICTED,
      {
        color: '#2F2E41',
        helpArtifacts: {
          iconImageSrc: restricted_zone_icon,
          areaImageSrc: restricted_zone_area,
          mapInfoImageSrc: MapInfoBoxRestrictedZone,
        },
      },
    ],
    [
      ZoneType.STOP,
      {
        color: '#0C8AFF',
        helpArtifacts: {
          /* TODO: These values are invalid. Get icons & info from UX team and fix this */
          iconImageSrc: restricted_zone_icon,
          areaImageSrc: restricted_zone_area,
          mapInfoImageSrc: MapInfoBoxStopZone,
        },
      },
    ],
    [
      ZoneType.NO_RIDE,
      {
        color: '#1A00AF',
        helpArtifacts: {
          /* TODO: These values are invalid. Get icons & info from UX team and fix this */
          iconImageSrc: restricted_zone_icon,
          areaImageSrc: restricted_zone_area,
          mapInfoImageSrc: MapInfoBoxNoRideZone,
        },
      },
    ],
  ]);

  export function metadata(zoneType: ZoneType): ZoneTypeMetadata {
    const underivedMetadata = zoneTypeToUnderivedMetadata.get(zoneType);
    if (!underivedMetadata) {
      throw Error(`
          Specified Zone type: ${toString(zoneType)} doesnt have corresponding underived/explicitly defined metadata.
          This usually (not always) means a bug or incomplete implementation.
      `);
    }
    const derivedMetadata: ZoneTypeDerivedMetadata = {
      translationKey: `zoneType.${toString(zoneType)}`,
      descriptionTranslationKey: `zoneType.${toString(zoneType)}.description`,
    };

    return { ...underivedMetadata, ...derivedMetadata };
  }

  export function allZoneTypes(): ZoneType[] {
    return EnumWithStringValue.enumToValues(ZoneType);
  }

  export function toString(zoneType: ZoneType): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(ZoneType, zoneType);
  }

  export function value(zoneType: ZoneType): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(ZoneType, zoneType);
  }

  // Multiselect is using the references for comparing the selected values. So we need to use the same reference for the options.
  export const menuConfig: ZoneTypeMenuConfig = once(() => ({
    name: 'zoneType',
    // This is  broken. The label is not being translated.
    label: intl.formatMessage({ id: 'zoneType' }),
    options: allZoneTypes().map((zoneType) => {
      return {
        value: zoneType,
        label: intl.formatMessage({ id: metadata(zoneType).translationKey }),
      };
    }),
  }))();
}
