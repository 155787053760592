import React from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { useIntl } from 'react-intl';
import { useExpanded, useFilters, useSortBy, useTable } from 'react-table';

import CustomIcon from '../utils/CustomIcon';

import TablePagination from './TablePagination';

const TableRowStyled = styled(TableRow)((props) => ({
  shouldForwardProp: (prop) => prop !== 'count' && prop !== 'expander',
  backgroundColor:
    props.count % 2 === 0
      ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.002), rgba(255, 255, 255, 0.002))'
      : 'linear-gradient(0deg, rgba(47, 46, 65, 0.08), rgba(47, 46, 65, 0.08))',
  '& > td': {
    color: 'black',
    border: '0.2px solid rgba(47, 46, 65, 0.08)',
  },
  '& > td:nth-last-of-type(1)': {
    borderLeft: props.expander ? 'none' : '0.2px solid rgba(47, 46, 65, 0.08)',
  },
  '& > td:nth-last-of-type(2)': {
    borderRight: props.expander ? 'none' : '0.2px solid rgba(47, 46, 65, 0.08)',
  },
  '& > th': {
    color: 'black',
    border: '0.2px solid rgba(47, 46, 65, 0.08)',
    background: 'rgba(47, 46, 65, 0.08)',
  },
  '& > th:nth-last-of-type(1)': {
    borderLeft: props.expander ? 'none' : '0.2px solid rgba(47, 46, 65, 0.08)',
  },
  '& > th:nth-last-of-type(2)': {
    borderRight: props.expander ? 'none' : '0.2px solid rgba(47, 46, 65, 0.08)',
  },
  ...(props.displayLastDifferent && {
    '&:is( .isProcessing) td:last-child': {
      color: props.theme.palette.white.main,
      backgroundColor: props.theme.palette.secondary.tint,
    },
    '&:is( .isFailed) td:last-child': {
      color: props.theme.palette.white.main,
      backgroundColor: props.theme.palette.alert.tint,
    },
    '&.isFailed:hover td:last-child': {
      backgroundColor: props.theme.palette.alert.main,
    },
    '&.isFailed.keepHoverState td:last-child': {
      backgroundColor: props.theme.palette.alert.main,
    },
    '&:is(:hover, .keepHoverState) td:last-child': {
      color: props.theme.palette.white.main,
      backgroundColor: props.theme.palette.secondary.main,
    },
    '&:is(:hover, .keepHoverState) td:last-child > div > span > div > button > span': {
      color: props.theme.palette.white.main,
    },
    '&:is(:hover, .keepHoverState) td:last-child > div > span > div > button > svg': {
      color: props.theme.palette.white.main,
    },
  }),
}));

const ReactTable = ({
  columns,
  data = [],
  loading,
  tableContainerStyle,
  renderDetailsComponent,
  onRowClickHandler,
  customRowHoverStyle = {},
  displayLastDifferent = false,
  rowCount = 15,
  pagination = false,
  sorted = true,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [rowsPerPage, setRowsPerPage] = React.useState(rowCount);
  const [page, setPage] = React.useState(0);
  const { formatMessage } = useIntl();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: columns,
      },
      autoResetFilters: false,
      disableMultiSort: false,
      autoResetSortBy: false,
      autoResetPage: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
  );

  const isExpanderColumnExist = !!columns.find((c) => c.id === 'expander');
  const theme = useTheme();

  return (
    <>
      <TableContainer sx={{ width: '100%', overflow: 'auto', maxHeight: 'calc(100vh - 120px)', ...tableContainerStyle }}>
        <Table {...getTableProps()} stickyHeader>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRowStyled
                key={`headergroup-${index}`}
                {...headerGroup.getHeaderGroupProps()}
                expander={isExpanderColumnExist ? 'true' : undefined}
              >
                {headerGroup.headers.map((column, index) => (
                  <TableCell
                    key={`header-${index}`}
                    sx={{
                      padding: '4px',
                      width: column.width || `calc(100% / ${columns.length})`,
                      background: 'white !important',
                      zIndex: 0,
                    }}
                  >
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        height: 'auto',
                        minHeight: '40px',
                        alignItems: 'center',
                        display: 'flex',
                        overflow: 'hidden',
                        userSelect: 'none',
                        whiteSpace: 'nowrap',
                        padding: '4px',
                      }}
                    >
                      <Typography variant="bodySmallBold">{column.render('Header')}</Typography>
                      {column.id !== 'expander' && sorted === true ? (
                        <span style={{ marginRight: 8, marginLeft: 8, height: 20 }}>
                          {column.isSortedDesc === undefined ? (
                            <>{CustomIcon('DeselectedIcon', theme.palette.dark.shade32)}</>
                          ) : column.isSortedDesc === true ? (
                            <>{CustomIcon('DescendingIcon', theme.palette.dark.main)}</>
                          ) : (
                            <>{CustomIcon('AscendingIcon', theme.palette.dark.main)}</>
                          )}
                        </span>
                      ) : null}
                    </div>
                  </TableCell>
                ))}
              </TableRowStyled>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <>
                  <TableRowStyled
                    {...row.getRowProps()}
                    key={`body-${index}`}
                    count={index}
                    hover={Object.keys(customRowHoverStyle).length === 0}
                    expander={isExpanderColumnExist ? 'true' : undefined}
                    onClick={() => onRowClickHandler(row.original)}
                    sx={{
                      '&:is(:hover, .keepHoverState)': customRowHoverStyle || theme.palette.dark.shade4,
                    }}
                    displayLastDifferent={displayLastDifferent}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          key={`bodycell-${index}`}
                          sx={{
                            padding: '4px',
                            width: `calc(100% / ${columns.length})`,
                          }}
                        >
                          <div
                            style={{
                              height: 'auto',
                              minHeight: '40px',
                              alignItems: 'center',
                              display: 'flex',
                              overflow: 'hidden',
                              userSelect: 'none',
                              whiteSpace: 'nowrap',
                              justifyContent: cell.column.justifyContent ? cell.column.justifyContent : 'flex-start',
                            }}
                          >
                            <Typography
                              variant="bodySmall"
                              sx={{
                                width: cell.column.fullWidth ? cell.column.fullWidth : {},
                              }}
                              color={theme.palette.baseLight.base76}
                            >
                              {cell.render('Cell')}
                            </Typography>
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRowStyled>
                  {row.isExpanded && renderDetailsComponent ? (
                    <TableRow>
                      <TableCell colSpan={columns?.length || 1} sx={{ p: 0 }}>
                        {renderDetailsComponent({ row: row.original })}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {pagination ? (
        <TablePagination
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          style={{
            width: '100%',
            paddingRight: '94px',
            boxShadow: theme.shadowOptions.aboveSmall,
          }}
        >
          {loading && <Typography variant="bodySmall">{formatMessage({ id: 'loadingSpinner.loadingText' })}</Typography>}
        </TablePagination>
      ) : null}
    </>
  );
};

export default ReactTable;
