import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { BudgetPlanMembershipDto } from '../../../../services/budget-plan-memberships';
import { intl } from '../../../../utils/intl';
import { ReactTable } from '../../../../wmv-components';

export const BudgetPlanMembershipTable = ({ budgetPlanMemberships }: BudgetPlanMembershipTableProps) => {
  return (
    <Box>
      <Typography variant="bodyMediumBold" mb={1} component="h3">
        {intl.formatMessage({ id: 'budgetPlanMemberships.table.title' })}
      </Typography>
      <ReactTable
        data={budgetPlanMemberships}
        columns={BudgetPlanMembershipTableColumnSupplier()}
        tableContainerStyle={{ height: `100%`, maxHeight: 'calc(80vh + 20px)' }}
        renderDetailsComponent={false}
        onRowClickHandler={() => {}}
        loading={false}
        sorted={false}
      />
    </Box>
  );
};

// TODO: Cells text need to be reusable, because same way we are displaying text in maximum cases
export const BudgetPlanMembershipTableColumnSupplier = () => {
  const theme = useTheme();

  return [
    {
      Header: intl.formatMessage({ id: `common.firstName` }),
      Cell: ({ row: { original: planMembership } }: RowData) => (
        <Typography variant="bodyMedium" px={1} color={theme.palette.baseLight.base76}>
          {planMembership.firstName}
        </Typography>
      ),
    },
    {
      Header: intl.formatMessage({ id: `common.lastName` }),
      Cell: ({ row: { original: planMembership } }: RowData) => (
        <Typography variant="bodyMedium" px={1} color={theme.palette.baseLight.base76}>
          {planMembership.lastName}
        </Typography>
      ),
    },
    {
      Header: intl.formatMessage({ id: `common.email` }),
      Cell: ({ row: { original: planMembership } }: RowData) => (
        <Typography variant="bodyMedium" px={1} color={theme.palette.baseLight.base76}>
          {planMembership.email}
        </Typography>
      ),
    },
    {
      Header: intl.formatMessage({ id: `common.activationDate` }),
      Cell: ({ row: { original: planMembership } }: RowData) => (
        <Typography variant="bodyMedium" px={1} color={theme.palette.baseLight.base76}>
          {planMembership.activeState.isSubscribed
            ? planMembership.activeState.subscribedAtEpochMillis!.parseEpochMillis().formatAsDateString()
            : intl.formatMessage({ id: 'common.na' })}
        </Typography>
      ),
    },
    {
      Header: intl.formatMessage({ id: `common.lastStatusUpdate` }),
      Cell: ({ row: { original: planMembership } }: RowData) => {
        return (
          <Typography variant="bodyMedium" px={1} color={planMembership.statusDisplayMetaData().color}>
            {planMembership.statusDisplayMetaData().displayText}
          </Typography>
        );
      },
    },
  ];
};

interface BudgetPlanMembershipTableProps {
  budgetPlanMemberships: BudgetPlanMembershipDto[];
}

interface RowData {
  row: {
    original: BudgetPlanMembershipDto;
  };
}
