import { VehicleType } from '../../VehicleType';
import { Fragments } from '../common';

import { BudgetPlanLastChangeAttemptStatus } from './BudgetPlanLastChangeAttemptStatus';
import { BudgetPlanStatus } from './BudgetPlanStatus';
import { Currency } from './Currency';
import { DepositFrequency } from './DepositFrequency';
import { ExpirationScheduleType } from './ExpirationScheduleType';
import { VehicleSpendConstraintCategory } from './VehicleSpendConstraintCategory';

export interface BudgetPlanSummaryCumDetailsResponse {
  id: number;
  version: number;
  activeState: BudgetPlanActiveState;
  lastStateChangeAttempt: LastChangeAttempt;
}

// TODO: Even if list & details share response strcture, we would want to split both so we are future proofed.
//  We would not want to mix summary responses (list api response) with get detail response.
// export interface BudgetPlanDetailsResponse {
//   id: number;
//   version: number;
//   activeState: BudgetPlanActiveState;
//   lastStateChangeAttempt: LastChangeAttempt;
// }

export interface BudgetPlanActiveState {
  status: BudgetPlanStatus;
  details: BudgetPlanDetails;
}

export interface LastChangeAttempt {
  type: LastChangeAttemptType;
  transition: LastChangeAttemptTransition;
  details: BudgetPlanDetails;
  status: BudgetPlanLastChangeAttemptStatus;
  triggeredViaRetry: boolean;
  retryAttemptPromptSuppressed: boolean;
  createdAtEpochMillis: number;
  updatedAtEpochMillis: number;
}

export interface BudgetPlanDetails {
  name: string;
  currency: Currency;
  depositSchedule: DepositSchedule;
  fragments: Fragments[];
}

enum LastChangeAttemptType {
  StatusTransition = 'STATUS_TRANSITION',
  DetailsUpdate = 'DETAILS_UPDATE',
}

interface LastChangeAttemptTransition {
  from: BudgetPlanStatus;
  to: BudgetPlanStatus;
}

export interface VehicleTypeSpendConstraints {
  type: VehicleSpendConstraintCategory.VehicleType;
  values: VehicleType[];
}

export interface DepositSchedule {
  frequency: DepositFrequency;
  metadata: DepositScheduleMetaData;
  expirationSchedule: ExpirationSchedule;
}

interface ExpirationSchedule {
  type: ExpirationScheduleType;
}

interface DepositScheduleMetaData {
  dayOfMonth: number;
  timeOfDay: string;
}
