import { themeOptions } from '../../../init-setup/ThemeOptions';
import { EnumWithStringValue } from '../../../utils/EnumUtils';
import { intl } from '../../../utils/intl';

import { DepositFrequency } from './DepositFrequency';

// TODO: QUES Check why Cancelled is there in AggregatedVehicle LastStatusTransitionAttemptStatus
// TODO: Check whether this name should be changed to BudgetPlanLastChangeAttemptStatus
export enum BudgetPlanLastChangeAttemptStatus {
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
  Failed = 'FAILED',
}

interface BudgetPlanLastChangeAttemptStatusUnderivedMetadata {
  displayText: string;
  color: string;
}

export namespace BudgetPlanLastChangeAttemptStatusHelper {
  const { formatMessage } = intl;
  const budgetPlanLastChangeAttemptStatusToUnderivedMetadata = new Map<
    BudgetPlanLastChangeAttemptStatus,
    BudgetPlanLastChangeAttemptStatusUnderivedMetadata
  >([
    [
      BudgetPlanLastChangeAttemptStatus.InProgress,
      { displayText: formatMessage({ id: 'common.InProgress' }), color: themeOptions.palette.warning.light },
    ],
    [
      BudgetPlanLastChangeAttemptStatus.Success,
      { displayText: formatMessage({ id: 'common.Success' }), color: themeOptions.palette.success.main },
    ],
    [
      BudgetPlanLastChangeAttemptStatus.Failed,
      { displayText: formatMessage({ id: 'common.Failed' }), color: themeOptions.palette.alert.main },
    ],
  ]);

  export function metadata(status: BudgetPlanLastChangeAttemptStatus): BudgetPlanLastChangeAttemptStatusUnderivedMetadata {
    const underivedMetadata = budgetPlanLastChangeAttemptStatusToUnderivedMetadata.get(status);
    if (!underivedMetadata) {
      throw Error(`
            Specified LastChangeAttemptStatus: ${toString(status)} doesnt have corresponding underived/explicitly defined metadata.
            This usually (not always) means a bug or incomplete implementation.
        `);
    }

    return { ...underivedMetadata };
  }

  export function toString(status: BudgetPlanLastChangeAttemptStatus): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(DepositFrequency, status);
  }
}
