import { createReducer } from '@reduxjs/toolkit';

import { hideAlertAction, showAlertAction } from './actions';
import { AppInitialState } from './models';

const initialState: AppInitialState = {
  alert: {
    visible: false,
    message: '',
    type: 'success',
  },
};

const appReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(showAlertAction, (state, action) => {
      state.alert.visible = true;
      state.alert.message = action.payload.message;
      state.alert.type = action.payload.type || 'success';
    })
    .addCase(hideAlertAction, (state, action) => {
      state.alert.visible = false;
      state.alert.message = '';
      state.alert.type = 'success';
    });
});

export default appReducer;
