import { Dispatch, SetStateAction, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { BudgetPlanTab, BudgetPlanTabHelper } from '../../../models';
import { budgetPlansByIdSelector } from '../../../state';
import CustomIcon from '../../../utils/CustomIcon';
import { Tabs } from '../../../wmv-components';

// TODO: Check whether to expose all the export for redux state from a single file is a good practice or not
import { BudgetPlanPopupTabContent } from './BudgetPlanPopupTabContent';
import { modalContentContainerStyles, contentBodyStyles, closeIconButtonStyles } from './styles';

export const BudgetPlanModal = (props: BudgetPlanModalProps) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { onBudgetPlanModalClose, open, selectedBudgetPlanId, onSelectedBudgetPlanIdChanged } = props;
  const [activeTab, setActiveTab] = useState<BudgetPlanTab>(BudgetPlanTab.PlanDetails);

  const selectedBudgetPlanDetails = useSelector(budgetPlansByIdSelector)[selectedBudgetPlanId];

  return (
    <Modal open={open} onClose={onBudgetPlanModalClose}>
      <Box sx={modalContentContainerStyles}>
        <Box width="240px">
          <Tabs
            items={BudgetPlanTabHelper.allTabLabelAndValues()}
            disabledItems={selectedBudgetPlanId ? [] : [BudgetPlanTab.Members]}
            onChange={(tab) => setActiveTab(tab)}
            active={activeTab}
            title={selectedBudgetPlanDetails?.activeState.name}
          />
        </Box>
        <Box sx={contentBodyStyles}>
          <BudgetPlanPopupTabContent
            selectedBudgetPlanId={selectedBudgetPlanId}
            activeTab={activeTab}
            onBudgetPlanModalClose={onBudgetPlanModalClose}
            onSelectedBudgetPlanIdChanged={onSelectedBudgetPlanIdChanged}
          />
          <IconButton
            onClick={onBudgetPlanModalClose}
            sx={closeIconButtonStyles}
            aria-label={formatMessage({ id: 'dialog.confirmDelete.close' })}
          >
            {CustomIcon('CloseIcon', theme.palette.white.main, '32px')}
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
};

export type BudgetPlanModalCloseHandler = () => void;
export type SelectedBudgetPlanIdChangeHandler = Dispatch<SetStateAction<number | null>>;

interface BudgetPlanModalProps {
  open: boolean;
  onBudgetPlanModalClose: BudgetPlanModalCloseHandler;
  selectedBudgetPlanId: number;
  onSelectedBudgetPlanIdChanged: SelectedBudgetPlanIdChangeHandler;
}
