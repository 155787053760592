import { useState, useEffect } from 'react';

import { UserTrip } from '../../../models';
import { getUserTrips } from '../../../utils/queries';

interface UseAllTripsProps {
  userId: string;
}

function useFetchAllTrips({ userId }: UseAllTripsProps) {
  const [allTripsData, setAllTripsData] = useState<UserTrip[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const loadUserTrips = (userId: string) => {
    setError(false);
    setLoading(true);
    setAllTripsData([]);

    getUserTrips(userId)
      .then((data) => {
        if (data && data.length > 0) {
          setAllTripsData(data);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadUserTrips(userId);
  }, [userId]);

  return { allTripsData, loading, error, refetch: loadUserTrips };
}

export default useFetchAllTrips;
