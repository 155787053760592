import Box from '@mui/material/Box';
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from 'react-router-dom';

import CustomerDatabase from './components/CustomerDatabase';
import Header from './components/Header/Header';
import Placeholder from './components/Placeholder/Placeholder';
import Analytics from './pages/Analytics';
import Communication from './pages/Communication';
import Dashboard from './pages/Dashboard';
import Incidents from './pages/Incidents';
import MaaS from './pages/MaaS';
import Notifications from './pages/Notifications';
import Operator from './pages/Operator';
// import Page404 from './pages/Page404';
import BugetPlan from './pages/Operator/BugetPlan';
import Support from './pages/Support';
import Zones from './pages/Zones';
import routes from './routes';
import { HEADER_HEIGHT } from './utils/constants';

const Root = () => {
  return (
    <Box style={{ marginTop: HEADER_HEIGHT }}>
      <Header />
      <Outlet />
    </Box>
  );
};

const EmptyComponent = () => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 90px)',
        width: '100vw)',
      }}
    >
      <Placeholder />
    </Box>
  );
};

export const browserRoutes = [
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '',
        element: <Navigate to={routes.dashboard} />,
      },
      {
        path: routes.dashboard,
        element: <Dashboard />,
      },
      {
        path: routes.communication,
        element: <Communication />,
      },
      {
        path: routes.zones,
        element: <Zones />,
      },
      {
        path: routes.incidents,
        element: <Incidents />,
      },
      {
        path: routes.maas,
        element: <MaaS />,
        children: [
          {
            path: '',
            element: <Navigate to={routes.customerDatabase} />,
          },
          {
            path: routes.customerDatabase,
            element: <CustomerDatabase />,
          },
          {
            path: routes.serviceManagement,
            element: <EmptyComponent />,
          },
          {
            path: routes.productManagement,
            element: <EmptyComponent />,
          },
          {
            path: routes.billingStatements,
            element: <EmptyComponent />,
          },
        ],
      },
      {
        path: routes.analytics,
        element: <Analytics />,
      },
      {
        path: routes.operator,
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate to={routes.fleetManagement} />,
          },
          {
            path: routes.fleetManagement,
            element: <Operator />,
          },
          {
            path: routes.budgetPlan,
            element: <BugetPlan />,
          },
        ],
      },
      {
        path: routes.support,
        element: <Support />,
      },
      {
        path: routes.notifications,
        element: <Notifications />,
      },
      {
        path: '/*',
        element: <EmptyComponent />,
      },
    ],
  },
];

const router = createBrowserRouter(browserRoutes);

const BrowserRouterProvider = () => <RouterProvider router={router} />;

export default BrowserRouterProvider;
